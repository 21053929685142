import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";

class ModalAddPlaylist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playlistName: ""
    };
  }

  handleTextChange = event => {
    this.setState({ playlistName: event.target.value });
  };

  createPlayList = () => {
    if (!this.state.playlistName || this.state.playlistName === "") {
      toast.error("Playlist name is required");
      return;
    }
    const url = this.props.apiHost + "playlists/";
    const headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };
    const bodyFormData = new FormData();
    bodyFormData.set("name", this.state.playlistName);

    axios
      .post(url, bodyFormData, {
        headers: headerData
      })
      .then(response => {
        if (response.status === 200) {
          this.props.history.push("/playlists/" + response.data.playlist_uuid);
        }
      })
      .catch(error => {
        console.log("post error", error);
        toast.error("Could not create your playst. Please try again");
      });
  };

  toggleModal = () => {
    this.props.togglePlaylistModal();
  };

  render() {
    return (
      <Modal isOpen={this.props.playlistModal} toggle={this.toggleModal}>
        <ModalHeader toggle={this.toggleModal}>Create a Playlist</ModalHeader>
        <ModalBody>
          <input
            placeholder="Enter a Playlist Name"
            required
            type="text"
            value={this.state.playlistName}
            onChange={this.handleTextChange}
            className="form-input form-control"
          />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={this.toggleModal}>
            Close
          </Button>
          <Button color="primary" onClick={this.createPlayList}>
            Create Playlist
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ModalAddPlaylist;
