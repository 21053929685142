import React from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Timer from "../../components/Timer";
import MicRecorder from "mic-recorder-to-mp3";
import { ImagePicker } from "react-file-picker";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles

import {
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  Dropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Row,
  ModalFooter
} from "reactstrap";
import profileImage from "../../static/images/profile.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

class CommentList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: 1,
      is_reply: false,
      comment: "",
      main_comment: -1,
      replying_to: "",
      comments: [],
      commentsList: [],
      dropdownOpen: false,
      type: "text",
      commentId: "",
      modal: false,
      blobURL: "",
      blob: "",
      image: "",
      selectedImage: "",
      isRecording: false,
      seconds: 0,
      disabled: false,
      isOpen: false,
      showImage: "",
      isEditing: false,
      editedCommentId: -1,
      editedCommentBody: ""
    };

    this.Mp3Recorder = new MicRecorder({ bitRate: 128 });

    this.handleSubmit = this.handleSubmit.bind(this);
    this.postComment = this.postComment.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addReply = this.addReply.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.getComments = this.getComments.bind(this);
    this.undoReply = this.undoReply.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  componentDidMount() {
    library.add(fas, far);
    this.renderComments(this.props.discussion.comments, 1);
  }
  componentDidUpdate(prevProps) {
    if (this.props.discussion !== prevProps.discussion) {
      this.renderComments(this.props.discussion.comments, 1);
    }
  }

  addReply(comment) {
    if (comment.type === "text") {
      this.setState({
        is_reply: true,
        main_comment: comment.id,
        replying_to: "@" + comment.user.first_name + ' "' + comment.body + '"'
      });
    } else {
      this.setState({
        is_reply: true,
        main_comment: comment.id,
        replying_to: "@" + comment.user.first_name
      });
    }

    document
      .getElementById("poemForm")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  }

  handleChange(event) {
    this.setState({ comment: event.target.value });
  }

  getComments() {
    const discussion_uuid = this.props.discussion.discussion_uuid;
    const url = this.props.apiHost + "discussions/" + discussion_uuid + "/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(url, { headers: headerData })
      .then(response => {
        // this.setState({
        //   commentsList: response.data[0].comments,
        // });
        if (response.status === 200) {
          this.setState({
            commentsList: []
          });
          this.renderComments(response.data[0].comments, 1);
        }
      })
      .catch(() => {
        toast.error("We could not fetch the discussion!");
      });
  }

  start = () => {
    if (this.state.isBlocked) {
      console.log("Permission Denied");
    } else {
      this.Mp3Recorder.start()
        .then(() => {
          this.setState({ isRecording: true });
        })
        .catch(e => console.error(e));
    }
  };

  stop = () => {
    this.Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        this.setState({ blob: blob });
        this.setState({ blobURL, isRecording: false, type: "audio" });
      })
      .catch(e => console.log(e));
  };

  delete = () => {
    this.setState({
      blobURL: "",
      blob: "",
      type: "text",
      seconds: 0,
      image: "",
      selectedImage: ""
    });
  };

  handleImageChange = base64 => {
    fetch(base64)
      .then(res => res.blob())
      .then(blob => {
        this.setState({ image: blob });
      });

    this.setState({ selectedImage: base64, type: "image" });
  };

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.isEditing) {
      this.sendEditComment();
    } else {
      this.postComment();
    }
  }

  sendEditComment() {
    console.log(this.state.editedCommentId);

    const url =
      this.props.apiHost +
      "discussions/" +
      this.props.discussion.discussion_uuid +
      "/edit/";

    let headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };
    var bodyFormData = new FormData();

    bodyFormData.set("id", this.state.editedCommentId);
    bodyFormData.set("body", this.state.comment);

    axios
      .post(url, bodyFormData, { headers: headerData })
      .then(response => {
        if (response.status === 200) {
          toast.success("Comment edited successfully!");
          this.setState({
            isEditing: false,
            editedCommentId: -1,
            editedCommentBody: "",
            comment: ""
          });
          this.getComments();
        }
      })
      .catch(() => {
        toast.error("We could not edit the comment!");
      });
  }

  postComment() {
    const url =
      this.props.apiHost +
      "discussions/" +
      this.props.discussion.discussion_uuid +
      "/add/";

    if (this.state.comment === "" && this.state.type === "text") {
      toast.error("Please enter a comment!");
      return;
    } else if (this.state.type === "audio" && this.state.blob === "") {
      toast.error("Please record an audio!");
      return;
    } else if (this.state.type === "image" && this.state.image === "") {
      toast.error("Please select an image!");
      return;
    }

    this.setState({ disabled: true });

    let headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };
    var bodyFormData = new FormData();

    bodyFormData.set("main_comment", this.state.main_comment);
    bodyFormData.set("is_reply", this.state.is_reply);

    bodyFormData.set("type", this.state.type);
    if (this.state.type === "text") {
      bodyFormData.set("body", this.state.comment);
    } else if (this.state.type === "image") {
      bodyFormData.set("image", this.state.image);
    } else if (this.state.type === "audio") {
      bodyFormData.set("audio", this.state.blob);
    }

    axios
      .post(url, bodyFormData, {
        headers: headerData
      })
      .then(response => {
        this.setState({ disabled: false });
        if (response.status === 201) {
          //  window.location.reload();
          toast.success("Comment posted!");
          this.setState({
            comment: "",
            is_reply: false,
            main_comment: -1,
            replying_to: "",
            type: "text",
            blobURL: "",
            blob: "",
            isRecording: false,
            seconds: 0,
            image: "",
            selectedImage: "",
            isEditing: false,
            editedCommentId: -1
          });
          this.getComments();
        }
      })
      .catch(error => {
        toast.error("Could not post your comment!");
      });
  }

  calculateDate(date) {
    const diff = Date.now() - date;
    const minute = 1000 * 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;
    const month = week * 30;
    const year = month * 12;
    let res = "";
    if (Math.round(diff / year) > 0) {
      res = Math.round(diff / year) + "y";
    } else if (Math.round(diff / month) > 0) {
      res = Math.round(diff / month) + "mo";
    } else if (Math.round(diff / week) > 0) {
      res = Math.round(diff / week) + "w";
    } else if (Math.round(diff / day) > 0) {
      res = Math.round(diff / day) + "d";
    } else if (Math.round(diff / hour) > 0) {
      res = Math.round(diff / hour) + "h";
    } else if (Math.round(diff / minute) > 0) {
      res = Math.round(diff / minute) + "m";
    } else {
      res = "1m";
    }
    return res;
  }

  deleteComment() {
    const commentId = this.state.commentId;
    const url =
      this.props.apiHost +
      "discussions/" +
      this.props.discussion.discussion_uuid +
      "/delete/";
    let headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };
    var bodyFormData = new FormData();
    bodyFormData.set("id", commentId);

    axios
      .post(url, bodyFormData, {
        headers: headerData
      })
      .then(response => {
        if (response.status === 200) {
          //window.location.reload();
          toast.info("Comment deleted!");
          this.getComments();
          this.toggleModal();
        }
      })
      .catch(error => {
        toast.error("Could not delete your comment!");
      });
  }

  likeComment(discussion_uuid, commentId) {
    const url =
      this.props.apiHost + "discussions/" + discussion_uuid + "/likes/";
    let headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };
    var bodyFormData = new FormData();
    bodyFormData.set("id", commentId);

    axios
      .post(url, bodyFormData, {
        headers: headerData
      })
      .then(response => {
        if (response.status === 201) {
          //window.location.reload();
          toast.success("Comment liked!");
          this.getComments();
        } else if (response.status === 200) {
          toast.info("Comment unliked!");
          this.getComments();
        }
      })
      .catch(error => {
        toast.error("Could not like your comment!");
      });
  }

  replaceURLsWithLinks = str => {
    const regex = /((http|https):\/\/[^\s]*)/gi;
    return str.replace(regex, '<a href="$1">$1</a>');
  };

  handleCommentBodyChange = e => {
    this.setState({ editedComment: e.target.value });
  };

  handleSaveClick = commentId => {
    return;
  };

  handleCancelClick = () => {
    this.setState({ isEditing: false });
  };

  handleStartEditing = () => {
    this.setState({ isEditing: true });
  };

  editComment(comment) {
    console.log(comment);
    this.setState({
      isEditing: true,
      editedCommentId: comment.id,
      comment: comment.body
    });
    document
      .getElementById("poemForm")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  }

  comment = (comment, commentType, liked, editable, indent, index) => {
    if (commentType === "text") {
      comment.body = this.replaceURLsWithLinks(comment.body);
    }

    const commentElement = (
      <li key={comment.id} style={{ paddingLeft: `${indent * 20}px` }}>
        <div className="commenter-image">
          {comment.user.avatar === "" ? (
            <img src={profileImage} alt="user-profile-img" className="p-0" />
          ) : (
            <img
              src={comment.user.avatar}
              alt="user-profile-img"
              className="p-0"
            />
          )}
        </div>
        <div className="comment-text pl-2">
          <Link to={`/user/${comment.user.user_uuid}`}>
            <span className="comment-text-bold">
              {comment.user.first_name} {comment.user.last_name}
            </span>
          </Link>
          {commentType === "text" ? (
            <div className="poem-script">
              <ReactQuill
                className="lyrics-text"
                value={comment.body}
                modules={{ toolbar: false }}
                readOnly={true}
              />
            </div>
          ) : commentType == "image" ? (
            <img
              onClick={() => {
                this.handleShowDialog(comment.image);
              }}
              style={{
                width: "200px",
                display: "flex",
                marginTop: "10px",
                marginBottom: "10px"
              }}
              src={comment.image}
            ></img>
          ) : (
            <audio
              className="audio-player"
              src={comment.audio}
              controls="controls"
            />
          )}
          <a
            style={{ cursor: "pointer" }}
            onClick={() =>
              this.likeComment(
                this.props.discussion.discussion_uuid,
                comment.id
              )
            }
          >
            {liked ? (
              <span className="">
                <FontAwesomeIcon icon={["fas", "heart"]} className="mr-2" />
                {comment.num_likes}
              </span>
            ) : (
              <span className="">
                <FontAwesomeIcon icon={["far", "heart"]} className="mr-2" />
                {comment.num_likes}
              </span>
            )}
          </a>
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.inputRef.focus();
              this.addReply(comment);
            }}
          >
            <span className="sub-text pl-4">Reply</span>
          </a>
        </div>
        <div className="item-cta">
          {editable && (
            <div>
              <Dropdown
                isOpen={
                  this.state.dropdownOpen && this.state.commentId === comment.id
                }
                toggle={this.toggle}
              >
                <DropdownToggle
                  onClick={() => {
                    this.setState({ commentId: comment.id });
                    this.toggleModal();
                  }}
                  caret
                  className="edit-button"
                >
                  <FontAwesomeIcon icon={fas.faMinusCircle} className="mr-2" />
                </DropdownToggle>
              </Dropdown>
              {commentType === "text" ? (
                <button
                  className="edit-button"
                  onClick={() => {
                    this.setState({ editedComment: comment });
                    this.editComment(comment);
                  }}
                >
                  <FontAwesomeIcon icon={fas.faEdit} className="mr-2" />
                </button>
              ) : null}
            </div>
          )}
        </div>
      </li>
    );

    return indent === 1 ? commentElement : commentElement;
  };

  handleShowDialog = image => {
    this.setState({ isOpen: !this.state.isOpen, showImage: image });
  };

  renderComments(discussionComments, indent = 1) {
    let editable = this.props.editable;
    if (discussionComments === null) {
      return <p>There's no comment to show.</p>;
    }

    const commentsList = this.state.commentsList;

    if (discussionComments !== undefined && discussionComments.length > 0) {
      discussionComments.map((comment, index) => {
        // check if user_uuid is in the list of users who liked the comment and display the heart icon
        let liked = false;
        if (comment.likes !== undefined && comment.likes.length > 0) {
          comment.likes.map(like => {
            if (like === this.props.activeUser.user_uuid) {
              liked = true;
            }
          });
        }

        if (comment.user.user_uuid === this.props.activeUser.user_uuid) {
          editable = true;
        }

        // push the new comment to the commentsList state
        commentsList.push(
          <ul className="comment-list" key={comment.id}>
            {this.comment(
              comment,
              comment.type,
              liked,
              editable,
              indent,
              index
            )}
          </ul>
        );
        this.renderComments(comment.replies, indent + 2);
      });
    }
    // update the state with the new commentsList
    this.setState({ commentsList });

    // return an empty div, as we don't want to render the comments list here
    return <div></div>;
  }

  renderPoemForm() {
    return (
      <div className="comment-form">
        <Form onSubmit={this.handleSubmit} id="poemForm">
          <div>{this.state.isRecording ? <Timer></Timer> : null}</div>
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            {/* Create a row */}
            <Row style={{ display: "inline-flex" }}>
              {(!this.state.isRecording && this.state.blob?.size > 0) ||
              this.state.selectedImage !== "" ? (
                <Col>
                  <Button outline color="danger" onClick={this.delete}>
                    <FontAwesomeIcon icon={["fas", "trash"]} />
                  </Button>
                </Col>
              ) : null}
              {this.state.image !== "" ||
              this.state.selectedImage !== "" ? null : (
                <Col>
                  {this.state.isRecording ? (
                    <Button outline color="danger" onClick={this.stop}>
                      Stop
                    </Button>
                  ) : this.state.blob?.size > 0 ? null : (
                    <Button outline color="primary" onClick={this.start}>
                      <FontAwesomeIcon icon={["fas", "microphone"]} />
                    </Button>
                  )}
                </Col>
              )}

              {this.state.blob?.size > 0 || this.state.isRecording ? null : (
                <Col>
                  <ImagePicker
                    extensions={["jpg", "jpeg", "png"]}
                    dims={{
                      minWidth: 100,
                      maxWidth: 5000,
                      minHeight: 100,
                      maxHeight: 5000
                    }}
                    onChange={this.handleImageChange}
                    onError={errMsg => toast.error(errMsg)}
                  >
                    <Button outline color="primary" onClick={() => {}}>
                      <FontAwesomeIcon icon={["fas", "image"]} />
                    </Button>
                  </ImagePicker>
                </Col>
              )}
            </Row>
          </div>
          <div></div>
          <FormGroup>
            {this.state.blob?.size > 0 ? (
              <audio
                className="audio-player"
                src={this.state.blobURL}
                controls="controls"
              />
            ) : this.state.selectedImage !== "" ? (
              <div>
                <img
                  src={this.state.selectedImage}
                  alt="selected"
                  style={{
                    width: "200px",
                    display: "flex",
                    marginTop: "10px",
                    marginBottom: "10px"
                  }}
                />
              </div>
            ) : this.state.blob?.size > 0 ||
              this.state.isRecording ||
              (this.state.image !== "" ||
                this.state.selectedImage !== "") ? null : (
              <Input
                ref={input => (this.inputRef = input)}
                onChange={this.handleChange}
                type="textarea"
                name="text"
                placeholder="Write something here..."
                value={this.state.comment}
              />
            )}

            <Button
              disabled={this.state.disabled}
              className="poem-comment-submit button small primary"
              type="submit"
            >
              Send
            </Button>
          </FormGroup>
        </Form>
      </div>
    );
  }

  undoReply() {
    this.setState({
      is_reply: false,
      replying_to: "",
      isEditing: false,
      editedCommentId: -1
    });
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    // create an empty array
    let list = [];
    const undoReply = () => {
      this.setState({
        is_reply: false,
        replying_to: "",
        isEditing: false
      });
    };
    const poemComments = this.state.commentsList;
    return (
      <div>
        <div className="comment-section">{poemComments}</div>
        <Modal isOpen={this.state.isOpen} toggle={this.handleShowDialog}>
          <ModalHeader toggle={this.handleShowDialog}>Preview</ModalHeader>
          <ModalBody>
            <img
              className=""
              src={this.state.showImage}
              onClick={this.handleShowDialog}
              alt="no image"
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.handleShowDialog}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
        {/* {this.state.isOpen && (
          
          <dialog
            className="dialog"
            style={{ position: 'absolute' }}
            open
            onClick={this.handleShowDialog}
          >
            
          </dialog>
        )} */}
        <br></br>
        <br></br>
        {this.state.is_reply ? (
          <Alert color="warning" toggle={undoReply}>
            Replying to {this.state.replying_to}
          </Alert>
        ) : (
          <div></div>
        )}

        <div className="comment-form">
          <Form onSubmit={this.handleSubmit} id="poemForm">
            <div>{this.state.isRecording ? <Timer></Timer> : null}</div>
            {this.state.isEditing ? (
              <Alert color="info" toggle={this.undoReply}>
                Editing comment "{this.state.comment}"
              </Alert>
            ) : (
              <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                {/* Create a row */}

                <Row style={{ display: "inline-flex" }}>
                  {(!this.state.isRecording && this.state.blob?.size > 0) ||
                  this.state.selectedImage !== "" ? (
                    <Col>
                      <Button outline color="danger" onClick={this.delete}>
                        <FontAwesomeIcon icon={["fas", "trash"]} />
                      </Button>
                    </Col>
                  ) : null}
                  {this.state.image !== "" ||
                  this.state.selectedImage !== "" ? null : (
                    <Col>
                      {this.state.isRecording ? (
                        <Button outline color="danger" onClick={this.stop}>
                          Stop
                        </Button>
                      ) : this.state.blob?.size > 0 ? null : (
                        <Button outline color="primary" onClick={this.start}>
                          <FontAwesomeIcon icon={["fas", "microphone"]} />
                        </Button>
                      )}
                    </Col>
                  )}

                  {this.state.blob?.size > 0 ||
                  this.state.isRecording ? null : (
                    <Col>
                      <ImagePicker
                        extensions={["jpg", "jpeg", "png"]}
                        dims={{
                          minWidth: 100,
                          maxWidth: 5000,
                          minHeight: 100,
                          maxHeight: 5000
                        }}
                        onChange={this.handleImageChange}
                        onError={errMsg => toast.error(errMsg)}
                      >
                        <Button outline color="primary" onClick={() => {}}>
                          <FontAwesomeIcon icon={["fas", "image"]} />
                        </Button>
                      </ImagePicker>
                    </Col>
                  )}
                </Row>
              </div>
            )}
            <FormGroup>
              {this.state.blob?.size > 0 ? (
                <audio
                  className="audio-player"
                  src={this.state.blobURL}
                  controls="controls"
                />
              ) : this.state.selectedImage !== "" ? (
                <div>
                  <img
                    src={this.state.selectedImage}
                    alt="selected"
                    style={{
                      width: "50%",
                      display: "flex",
                      marginTop: "10px",
                      marginBottom: "10px"
                    }}
                  />
                </div>
              ) : this.state.blob?.size > 0 ||
                this.state.isRecording ||
                (this.state.image !== "" ||
                  this.state.selectedImage !== "") ? null : (
                <Input
                  ref={input => (this.inputRef = input)}
                  onChange={this.handleChange}
                  type="textarea"
                  name="text"
                  placeholder="Write something here..."
                  value={this.state.comment}
                />
              )}

              <Button
                disabled={this.state.disabled}
                className="poem-comment-submit button small primary"
                type="submit"
              >
                Send
              </Button>
            </FormGroup>
          </Form>
        </div>
        {/* Create a modal that displays two buttons one for deleteing a comment and one to cancel */}
        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>Delete Comment</ModalHeader>
          <ModalBody>Are you sure you want to delete this comment?</ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.deleteComment}>
              Delete
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

CommentList.propTypes = {
  poemComments: PropTypes.array,
  activeUser: PropTypes.object,
  currentRecording: PropTypes.object,
  apiHost: PropTypes.string
};

export default CommentList;
