import React, { Component } from "react";
import Header from "../../Headers/Header.js";
import dannyImage from "../../static/images/Danny.jpg";
import omarImage from "../../static/images/Omar.jpg";
import pearlImage from "../../static/images/Pearl.jpg";
import { Row, Col } from "reactstrap";

export default class AboutUs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // const dannyImage = "../../static/images/Danny.jpg";
    // const omarImage = "../../static/images/Omar.jpg";
    // const pearlImage = "../../static/images/Pearl.jpg";
    return (
      <div className="App">
        <Header {...this.props} />
        <div className="interior-page page-wrapper">
          <div className="wrap">
            <h1 className="page-title">About Us</h1>
            <p>
              Recited Verse is founded and directed by a team of literary
              scholars from the University of San Francisco, University of
              California Los Angeles, and Notre Dame de Namur University. Omar
              F. Miranda founded the idea for Recited Verse back in 2013 when he
              was a graduate student at NYU, as he wanted to develop a way for
              poetry (old or new) to feel modern through an accessible,
              interactive, and engaging platform.
            </p>
            <p>
              Many talented student web developers from the Computer Science
              departments from New York University and University of San
              Francisco have helped bring the project to its current state. The
              project has been generously funded by the College of Arts and
              Sciences at the University of San Francisco.
            </p>
            <Row className="space">
              <Col sm="2">
                <img
                  src={omarImage}
                  style={{
                    height: "100px",
                    width: "100px",
                    objectFit: "cover"
                  }}
                ></img>
              </Col>
              <Col sm="10">
                <p>
                  Omar F. Miranda, PhD (Assistant Professor, University of San
                  Francisco) teaches and writes about the literature of the
                  eighteenth and nineteenth centuries. He is working on a book
                  that explores the global origins of celebrity culture and its
                  relation to literary writing. He also loves to walk his dog,
                  Dolores, as well as exercise, read, cook, go birding, travel,
                  and walk the streets of San Francisco.
                </p>
              </Col>
            </Row>
            <Row className="space">
              <Col sm="2">
                <img
                  src={dannyImage}
                  style={{
                    height: "100px",
                    width: "100px",
                    objectFit: "cover"
                  }}
                ></img>
              </Col>
              <Col sm="10">
                <p>
                  Danny Snelson, PhD (Assistant Professor of English, UCLA) is a
                  writer, editor, and archivist. His online editorial work can
                  be found on PennSound, Eclipse, UbuWeb, Jacket2, and the EPC.
                  He is currently developing a manuscript exploring online
                  collections of art and letters entitled The Little Database: A
                  Poetics of Media Formats. Otherwise, he spends too much time
                  playing video games, reading comics, and making mischief in
                  VR. See also: http://dss-edit.com{" "}
                </p>
              </Col>
            </Row>
            <Row className="space">
              <Col sm="2">
                <img
                  src={pearlImage}
                  style={{
                    height: "100px",
                    width: "100px",
                    objectFit: "cover"
                  }}
                ></img>
              </Col>
              <Col sm="10">
                <p>
                  Pearl Chaozon Bauer, PhD (Associate Professor of English,
                  Notre Dame de Namur University) teaches courses in literature
                  and film, writing, and community engagement. She especially
                  loves getting her students excited about poetry. For fun, she
                  teaches yoga and meditation, leads fermentation and pickling
                  workshops, hunts for mushrooms, and enjoys going on birding
                  hikes.
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
