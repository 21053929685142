import React, { useState } from "react";
import PdfModal from "./PdfModal";

const PdfButton = ({ name, url, author, project, uuid }) => {
  const [hovered, setHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  // const handleClick = () => {
  //   // Implement logic to open the PDF file using react-file-viewer
  // };

  const handleClick = () => {
    handleMouseLeave();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const toggleModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div
      className="pdf-button"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <div className="pdf-button__text">{name}</div>
      <div className="pdf-button__subtitle">{author}</div>
      <div
        className={`pdf-button__overlay${
          hovered ? " pdf-button__overlay--visible" : ""
        }`}
      >
        <div className="pdf-button__overlay-text">View PDF</div>
        <PdfModal
          fileUrl={url}
          fileName={name}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          toggle={toggleModalOpen}
        />
      </div>
    </div>
  );
};

export default PdfButton;
