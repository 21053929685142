import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import RecitationList from "../../components/RecitationList";
import RecitationListPlaylistSearch from "./RecitationListPlaylistSearch";
import RecitationItem from "./RecitationItem";
import ModalRecitationDelete from "../modals/ModalRecitationDelete";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  Row,
  Col
} from "reactstrap";
import { toast } from "react-toastify";
import RecitationListPlaylist from "./RecitationListPlaylist";

class PlaylistList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      recitations: [],
      editable: false
    };
    this.toggle = this.toggle.bind(this);
  }

  updateEditable = () => {
    const { activeUser } = this.props;
    if (activeUser) {
      this.setState({ editable: activeUser.is_staff });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.activeUser !== prevProps.activeUser) {
      this.updateEditable();
    }
  }

  componentDidMount() {
    this.updateEditable();
  }

  toggle() {
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  toggleModalDelete = recording => {
    this.setState(prevState => ({
      modalDelete: !prevState.modalDelete,
      selectedRecitation: recording
    }));
  };

  render() {
    const editable =
      (this.props.activeUser &&
        this.props.playlist.user === this.props.activeUser.id) ||
      this.state.editable;
    const playlistItems = this.props.playlistItems;
    const recitationId = this.state.selectedRecitation?.id;
    return (
      <div className="wrap">
        {/* {editable ? (
          <div className="playlist-add">
            <Button color="success" onClick={() => this.toggle()}>
              Explore
            </Button>
          </div>
        ) : (
          <span></span>
        )} */}
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Featured Recordings</ModalHeader>
          <ModalBody>
            <RecitationListPlaylistSearch
              recitations={this.state.recitations}
              {...this.props}
              {...this.state}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <ul>
          {playlistItems.map((recitation, index) => {
            return (
              <RecitationItem
                {...this.props}
                {...this.state}
                key={recitation.id}
                index={index}
                recitation={recitation}
                editable={editable}
                recitations={playlistItems}
                toggleModalDelete={this.toggleModalDelete}
              />
            );
          })}
        </ul>
        <ModalRecitationDelete
          {...this.props}
          key={`delete-${recitationId}`}
          selectedRecitation={this.state.selectedRecitation}
          modalDelete={this.state.modalDelete}
          toggleModalDelete={this.toggleModalDelete}
          removeFromList={this.props.removeFromList}
        ></ModalRecitationDelete>
        {/* <RecitationListPlaylist
          {...this.props}
          {...this.state}
          recitations={playlistItems}
          editable={this.editable}
        /> */}
      </div>
    );
  }
}

export default PlaylistList;
