import React, { Component } from "react";
import PostLoginHeader from "./components/PostLoginHeader";
import PreLoginHeader from "./components/PreLoginHeader";
import "./Header.scss";

class Header extends Component {
  render() {
    if (this.props.isAuthenticated) {
      return <PostLoginHeader {...this.props} />;
    } else {
      return <PreLoginHeader {...this.props} />;
    }
  }
}

export default Header;
