import React, { Component } from "react";
import { NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";

class DiscussionsList extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    return (
      <div style={{ marginTop: "20px" }}>
        <ul className="content-list reading-list">
          {this.props.discussions.map(discussion => (
            <div>
              <h5>
                <Link to={`/discussion/${discussion.discussion_uuid}`}>
                  {discussion.title}
                </Link>
              </h5>
              <p>{discussion.subtitle}</p>
            </div>
          ))}
        </ul>
      </div>
    );
  }
}

export default DiscussionsList;
