import {
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  Dropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  Row,
  ModalFooter
} from "reactstrap";
import React from "react";
import DraggableComment from "./DraggableComment";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import axios from "axios";
import { toast } from "react-toastify";

import { Link } from "react-router-dom";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles

import profileImage from "../../static/images/profile.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";

class OrderModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentsList: [],
      loading: true
    };
  }

  getComments() {
    const discussion_uuid = this.props.discussion.discussion_uuid;
    const url = this.props.apiHost + "discussions/" + discussion_uuid + "/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(url, { headers: headerData })
      .then(response => {
        if (response.status === 200) {
          var comments = [];
          for (
            let index = 0;
            index < response.data[0].comments.length;
            index++
          ) {
            const element = response.data[0].comments[index];
            if (element.is_reply == false) {
              comments.push(element);
            }
          }
          this.setState({
            commentsList: comments,
            loading: false
          });
          console.log(response.data[0].comments);
        }
      })
      .catch(error => {
        if (error.response !== undefined) {
          console.error("Error fetching comments", error);
          toast.error("We could not fetch the discussion!");
        }
      });
  }

  updateOrder = () => {
    const discussion_uuid = this.props.discussion.discussion_uuid;
    const url = this.props.apiHost + "discussions/" + "reorder";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    let comments = [];
    for (let index = 0; index < this.state.commentsList.length; index++) {
      const element = this.state.commentsList[index];
      comments.push(element.id);
    }

    let data = {
      comments: comments
    };

    console.log(data);
    console.log(headerData);
    console.log(url);

    axios
      .post(url, data, { headers: headerData })
      .then(response => {
        if (response.status === 200) {
          toast.success("Comments reordered successfully!");
          this.props.toggle();
        }
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(error => {
        console.error("Error updating comments", error);
        toast.error("We could not update the comments!");
      });
  };

  moveComment = (fromIndex, toIndex) => {
    const { commentsList } = this.state;
    const [removed] = commentsList.splice(fromIndex, 1);
    commentsList.splice(toIndex, 0, removed);

    this.setState({ commentsList });
  };

  componentDidMount() {
    this.getComments();
  }

  closeModal = event => {
    event.preventDefault();
    this.props.toggleModalOrder();
  };

  comment = (comment, commentType, indent) => {
    return (
      <div className="comment-text pl-2">
        <Link to={`/user/${comment.user.user_uuid}`}>
          <span className="comment-text-bold">
            {comment.user.first_name} {comment.user.last_name}
          </span>
        </Link>
        {commentType === "text" ? (
          <div className="poem-script">
            <ReactQuill
              className="lyrics-text"
              value={comment.body}
              modules={{ toolbar: false }}
              readOnly={true}
            />
          </div>
        ) : commentType == "image" ? (
          <img
            onClick={() => {
              this.handleShowDialog(comment.image);
            }}
            style={{
              width: "200px",
              display: "flex",
              marginTop: "10px",
              marginBottom: "10px"
            }}
            src={comment.image}
          ></img>
        ) : (
          <audio
            className="audio-player"
            src={comment.audio}
            controls="controls"
          />
        )}
      </div>
    );
  };

  render() {
    const comments = this.state.commentsList.map((comment, index) => (
      <DndProvider backend={HTML5Backend}>
        <DraggableComment
          style={{ padding: "50px" }}
          key={index}
          comment={this.comment(comment, comment.type, index)}
          index={index}
          moveComment={this.moveComment}
        />
      </DndProvider>
    ));

    return (
      <div>
        {this.state.loading ? (
          <div>loading</div>
        ) : (
          <Modal isOpen={this.props.modalOrder} toggle={this.props.toggle}>
            <ModalHeader toggle={this.props.toggle}>
              Reorder Comments
            </ModalHeader>
            <ModalBody>
              <div>{comments}</div>
            </ModalBody>
            <ModalFooter className="">
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.updateOrder();
                }}
              >
                Save
              </button>
              <button
                className="btn btn-danger"
                onClick={event => {
                  this.props.toggle();
                }}
              >
                Cancel
              </button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    );
  }
}

export default OrderModal;
