import React, { Component } from "react";
import { Button } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

export default class ModalDelete extends Component {
  constructor(props) {
    super(props);
    this.deleteRecording = this.deleteRecording.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  deleteRecording = () => {
    const recitation_uuid = this.props.currentRecording.recitation_uuid;
    const deleteUrl =
      this.props.apiHost + "recitations/" + recitation_uuid + "/delete/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    axios
      .get(deleteUrl, { headers: headerData })
      .then(response => {
        toast.success("Your recording has successfully been deleted");
        setTimeout(() => {
          this.props.history.push("/");
        }, 1000);
      })
      .catch(err => {
        toast.error("There was an error in deleting your recording");
      });
  };

  closeModal(event) {
    event.preventDefault();
    this.props.handler();
  }

  render() {
    return (
      <div className="App">
        <h4 className="upload-header">
          Are you sure you want to delete this recording?
        </h4>
        <Button
          style={{ margin: "10px" }}
          color="danger"
          onClick={this.deleteRecording}
        >
          Yes
        </Button>
        <Button
          style={{ margin: "10px" }}
          color="primary"
          outline
          onClick={this.closeModal}
        >
          No
        </Button>
      </div>
    );
  }
}
