import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import logo from "./../../static/images/logo-white.png";

class FeaturedItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const handleImageError = e => {
      e.target.src = logo;
    };

    const poem = this.props.poem;
    const image = poem.poem_photo ? poem.poem_photo : logo;

    return (
      <li className="content-list-item reading-list-item">
        <div className="item-image">
          <img src={image} alt={poem.name} onError={handleImageError}></img>
        </div>
        <div className="item-info">
          <h5>
            <Link to={`/recitations/${poem.poem_uuid}`}>{poem.name}</Link>
          </h5>
          {poem.poet !== null && poem.poet !== "" ? (
            <p>
              <span>By</span>
              {poem.poet}
            </p>
          ) : (
            <span></span>
          )}
        </div>
        <div className="item-cta">
          <span>{poem.duration}</span>
          {this.editable ? <button>edit</button> : <span></span>}
        </div>
      </li>
    );
  }
}

export default FeaturedItem;
