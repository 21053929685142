import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { PropTypes } from "prop-types";
import { get } from "lodash";
import Comments from "./Comments";
import PoemDetailDiscussion from "./PoemDetailDiscussion";
import PoemInfoDiscussion from "./PoemInfoDiscussion";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles

class DiscussionMainContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      discussion: {},
      editable: false,
      loading: true
    };
  }

  updateEditable = () => {
    const { activeUser } = this.props;
    if (activeUser) {
      this.setState({ editable: activeUser.is_staff });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.activeUser !== prevProps.activeUser) {
      this.updateEditable();
    }
  }

  playRecording = recording => {
    this.props.accessPlayer(recording);
    this.updateNumberOfPLays(recording);
  };

  updateNumberOfPLays = recording => {
    const headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };
    const url =
      this.props.apiHost +
      "recitations/" +
      recording.recitation_uuid +
      "/play/";

    axios
      .post(url, new FormData(), {
        headers: headerData
      })
      .then(response => {
        this.props.audioInstance.play();
      })
      .catch(err => {
        console.error(err);
        toast.error("Error incrementing the number of plays");
      });
  };

  getDiscussionDetail = discussion_uuid => {
    this.setState({ loading: true });
    const url = this.props.apiHost + "discussions/" + discussion_uuid + "/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(url, {
        headers: headerData
      })
      .then(response => {
        this.setState({
          discussion: response.data[0],
          loading: false,
          editable:
            response.data[0]["owner"]["user_uuid"] ===
            this.props.activeUser.user_uuid
        });
        //this.replaceURLsWithLinks();
      })
      .catch(error => {
        toast.error("We could not fetch the discussion!");
      });
  };

  componentDidMount() {
    this.updateEditable();
    this.setState({ loading: true });
    this.getDiscussionDetail(this.props.match.params.id);
  }

  replaceURLsWithLinks = (str, type) => {
    const regex = /((http|https):\/\/[^\s]*)/gi;
    if (type === "title") {
      str = str.replace(regex, '<h1><a href="$1">$1</a></h1>');
      str = "<h1>" + str + "</h1>";
      return str;
    }
    str = str.replace(regex, '<h3><a href="$1">$1</a></h3>');
    str = "<h3>" + str + "</h3>";
    return str;
  };

  render() {
    let title = "";
    let subtitle = "";
    if (!this.state.loading) {
      title = this.replaceURLsWithLinks(this.state.discussion.title, "title");
      subtitle = this.replaceURLsWithLinks(
        this.state.discussion.subtitle,
        "subtitle"
      );
    }

    return (
      <div className="container">
        {/* create a column of 80% and 20% */}

        {/* if this.state.discussion.recitation is undefinded return the layout else return null */}

        {this.state.discussion.recitation ? (
          <div className="">
            <div className="">
              <div className="card" style={{ marginBottom: "20px" }}>
                <div className="card-body">
                  <PoemInfoDiscussion
                    {...this.props}
                    recording={this.state.discussion.recitation}
                    playRecording={this.playRecording}
                  />
                  <PoemDetailDiscussion
                    {...this.props}
                    recording={this.state.discussion.recitation}
                  />
                </div>
              </div>
              <div className="poem-script">
                <ReactQuill
                  className="lyrics-text"
                  value={title}
                  modules={{ toolbar: false }}
                  readOnly={true}
                />
                <br></br>
                <ReactQuill
                  className="lyrics-text"
                  value={subtitle}
                  modules={{ toolbar: false }}
                  readOnly={true}
                />
              </div>
              <Comments
                {...this.props}
                discussion={this.state.discussion}
                editable={this.state.editable}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="poem-script">
              <ReactQuill
                className="lyrics-text title"
                value={title}
                modules={{ toolbar: false }}
                readOnly={true}
              />
              <br></br>
              <ReactQuill
                className="lyrics-text"
                value={subtitle}
                modules={{ toolbar: false }}
                readOnly={true}
              />
            </div>
            <Comments
              {...this.props}
              discussion={this.state.discussion}
              editable={this.state.editable}
            />
          </div>
        )}
      </div>
    );
  }
}

export default DiscussionMainContent;
