import React, { Component } from "react";
import { Link } from "react-router-dom";
import profileImage from "../static/images/profile.png";

export default class FollowingItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const user = this.props.user;
    let user_images = user.avatar;
    return (
      <Link to={`/user/${user.user_uuid}`}>
        <div className="following-user">
          <div className="user-image">
            {user_images === "" ? ( // display default
              <img src={profileImage} alt="user-profile-img" className="p-0" />
            ) : (
              // display profile img
              <img src={user_images} alt="user-profile-img" className="p-0" />
            )}
          </div>
          <div className="user-info">
            <p>
              {user.first_name} {user.last_name}
            </p>
          </div>
          <div className="user-link">
            <Link
              to={`/user/${user.user_uuid}`}
              className="button small primary"
            >
              View
            </Link>
          </div>
        </div>
      </Link>
    );
  }
}
