import React, { Component } from "react";
import { PropTypes } from "prop-types";
import RecitationItem from "./RecitationItem";

class LikesRecitationList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const recitations = this.props.likesRecitations;

    return (
      <div className="">
        {recitations !== undefined && recitations.length > 0 ? (
          /* display the recitation list if there's any */
          <div>
            <ul className="content-list reading-list">
              {recitations.map(item => {
                const recitation = item.recitation;

                // skip the deleted recitation
                if (recitation == null) {
                  return;
                }

                return (
                  <RecitationItem key={recitation.id} recitation={recitation} />
                );
              })}
            </ul>
          </div>
        ) : (
          /* display the notice else */
          <span></span>
        )}
      </div>
    );
  }
}

LikesRecitationList.propTypes = {
  recitations: PropTypes.array,
  location: PropTypes.object,
  apiHost: PropTypes.string
};

export default LikesRecitationList;
