import React, { Component } from "react";
import MemberList from "./MemberList";

class Members extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <MemberList
        groupMembers={this.props.groupMembers}
        addGroup={this.toggleModal}
        editable={this.editable}
      />
    );
  }
}

export default Members;
