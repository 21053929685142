import React, { Component } from "react";
import FeaturedList from "./FeaturedList";

class Featured extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <FeaturedList
        {...this.props}
        poems={this.props.poems}
        editable={this.editable}
      />
    );
  }
}

export default Featured;
