import React, { Component } from "react";
import Header from "../../Headers/Header.js";

export default class About extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="App">
        <Header {...this.props} />
        <div className="interior-page page-wrapper">
          <div className="wrap">
            <h1 className="page-title">About Recited Verse</h1>
            <p>
              Using contemporary digital tools to promote the oldest forms of
              poetic preservation
            </p>
            <p>
              Recited Verse (RV) is a free social media platform that supports
              an entirely user-generated collection of poetry spoken aloud. We
              welcome all poetry enthusiasts (poets, students, teachers,
              scholars, and the general public) and all kinds of poetry
              (original and published verse of all ages, cultures, and
              languages). Through our website and iOS and Android apps, users
              can record and listen to poems they know and have composed at home
              or on the road. Additional features include generating and
              following poetry playlists; creating, joining, and interacting in
              private group forums; maintaining a public profile; following
              other users; liking readings; and using our commenting and
              advanced search options.
            </p>
            <p>
              One of main guiding principles of Recited Verse is that a poem
              should be associated with multiple readings rather than any single
              authoritative track (even if that recording was produced by its
              author). By design, then, Recited Verse welcomes the widest
              possible range of voices, tones, cadences, and energies to offer a
              diversity of interpretation and the impetus for formal and
              informal conversations regarding the given work and its attendant
              readings. This means that each poem on Recited Verse displays not
              only its written text, primary reader, and correspondent social
              commentary and interactions, but also any number of recordings of
              the poem produced by any number of users. By encouraging public
              participants, poetry students, and poetics scholars alike to
              produce original recordings of poems from all genres and eras,
              Recited Verse aims to generate a collective resource of unique
              interpretations of written poetry in shareable audio formats, open
              to public discussion, in which all readings are welcome and valid.
            </p>
            <h5>Recited Verse and Digital Humanities</h5>
            <p>
              As a research project, Recited Verse is a digital humanities
              initiative investigating platform design in alternative social
              media, sound studies, and digital pedagogy. Focusing on spoken
              recordings of poems, the platform is the first of its kind to
              focus on community-based social practices of production,
              circulation, and discussion of poetry recordings online. The
              Recited Verse platform addresses a range of online communities –
              including students, scholars, instructors, poets, and poetry
              enthusiasts – bridging diverse perspectives and proficiencies
              through a shared focus on the sound of poetry, read aloud. It
              follows on new trends in digital humanities practices that
              highlight the creative production of knowledge utilizing networked
              platforms and innovative uses of technology to produce humanistic
              inquiry. This includes introducing the playlist, which, as a form,
              provides a radically democratic opportunity for “anthologizing”
              collections of poetry in new ways. By simplifying the process of
              compiling and empowering each RV user (regardless of background or
              position) to assume the role of an anthologist capable of creating
              original, distinctive groupings of poems, both the anthology as a
              product and anthologizing as a method might be reconceived.{" "}
            </p>
            <p>
              Recited Verse is also dedicated to speaking back to corporate
              social media trends, while providing alternative anthologizing
              methods, and arguing for renewed attention to poetry as sound and
              the practice of recitation. By mobilizing and activating new
              currents in social media and the circulation of poetry, the
              project aims to amplify the power of verse and the poetics of
              voice. Revitalizing the oral and aural elements of poetry in a
              social media platform, Recited Verse moves beyond the realm of
              literary scholarship into other areas of the humanities, including
              digital humanities, sound studies, theater, and performance
              studies, among others.
            </p>
            <h5>Recited Verse and the Classroom</h5>
            <p>
              Recited Verse attempts to bring the power of poetry and social
              media into the classroom. Through our “Groups” feature (created
              based on a specific class or even region, poet, or theme),
              students and instructors can share recordings and make comments in
              a private space independent of the main public RV site – all
              comments, recordings, and uploads are limited to the persons in a
              group. To read out a poem on RV is to produce new and singular
              sonic knowledge, in every instance. The platform, as a whole,
              inquires into what new understanding might be built on thousands
              or millions of such recordings. Recited Verse also includes a
              playlist-generating function, which allows any user to curate
              groupings of poetry recordings of any chosen length and number of
              poems. Much like Spotify, these easily compiled playlists enable
              sustained listening to a diversity of materials. Playlists can be
              created and also shared according to various categories such as
              personal taste, themes, moods, specific poets, periods, regions,
              poetic devices, or styles. On the other hand, a playlist may
              simply include fifty different versions of the same poem for
              comparative sonic analysis. As users discover unlikely and
              unforeseen combinations of poems as well as unexpected moods or
              unexpected interests, they learn how wildly varied a single poem
              might sound. In the classroom, an instructor might produce a
              playlist for a lesson, module, or an entire course syllabus, while
              also using the crowd-sourced compilation tool for a class
              assignment that might pair this corpus with any number of outside
              materials, texts, or websites.
            </p>
            <h5>Recited Verse and the General Public</h5>
            <p>
              The designation of this project as a free initiative “for the
              public” is a core tenet. Recited Verse seeks to be a site
              developed by any member of the public interested in reading,
              listening to, and learning more about poetry. The site provides
              access to poetry to those not geographically privileged enough to
              be close to the typical outlets for reading and hearing it. The
              lessons of 2020 have taught us that even those in metropolitan
              areas can quickly and unexpectedly become distanced from the very
              notion of live performances and cultural centrality. Recited Verse
              seeks to find new and public answers to these questions through
              the power of social media.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
