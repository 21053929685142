import React, { Component } from "react";
import Header from "./../Headers/Header.js";
import GroupMainContent from "./components/GroupMainContent.js";
import "./Group.scss";

class Group extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  render() {
    return (
      <div className="App">
        <Header {...this.props} />
        <div className="page-wrapper group-page">
          <GroupMainContent {...this.props} {...this.state} />
        </div>
      </div>
    );
  }
}

export default Group;
