import React, { Component } from "react";
import { Link } from "react-router-dom";
import profileImage from "../static/images/profile.png";
import axios from "axios";
import { toast } from "react-toastify";

export default class NotificationItem extends Component {
  constructor(props) {
    super(props);
  }

  deleteNotification = (id, uuid, type) => {
    const url = this.props.apiHost + "notifications_delete/";

    var formData = new FormData();

    formData.append("id", id);
    const header = {
      Authorization: "Token " + this.props.activeUser.token,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json"
    };

    axios
      .post(url, formData, {
        headers: header,
        credentials: "same-origin",
        mode: "no-cors"
      })
      .then(res => {
        if (res.status === 200) {
          //this.props.getApiData();
          if (type == 1) {
            window.location.replace(`/recitations/${uuid}`);
          } else if (type == 2) {
            window.location.replace(`/recitations/${uuid}`);
          } else if (type == 3) {
            window.location.replace(`/user/${uuid}`);
          } else if (type == 4) {
            window.location.replace(`/groups/${uuid}`);
          } else if (type == 5) {
            window.location.replace(`/recitations/${uuid}`);
          } else if (type == 6) {
            window.location.replace(`/discussion/${uuid}`);
          }
        }
      })
      .catch(err => {
        console.log(err);
        // if (type == 1) {
        //   window.location.replace(`/recitations/${uuid}`);
        // } else if (type == 2) {
        //   window.location.replace(`/recitations/${uuid}`);
        // } else if (type == 3) {
        //   window.location.replace(`/user/${uuid}`);
        // } else if (type == 4) {
        //   window.location.replace(`/groups/${uuid}`);
        // }
        toast.error(`Could not delete notification.`);
      });
  };

  render() {
    // comment
    const notification = this.props.notification;
    const type = notification.type;
    const user = notification.user;
    const name = `${user?.first_name} ${user?.last_name}`;
    const uuid = user?.user_uuid;
    const user_images = user?.avatar;
    const recitation = notification.recitation;
    const timestamp = notification.created_at;
    const date = new Date(timestamp);
    const group_name = notification.group_name;
    const group_uuid = notification.group_uuid;
    const discussion = notification.discussion;

    if (type === 1) {
      // like
      return (
        <div className="notification-user">
          <div className="user-image">
            {user_images === "" ? ( // display default
              <img src={profileImage} alt="user-profile-img" className="p-0" />
            ) : (
              // display profile img
              <img src={user_images} alt="user-profile-img" className="p-0" />
            )}
          </div>
          <div className="user-info">
            <p>
              <Link to={`/user/${uuid}`}>{name}</Link> liked your{" "}
              <Link to={`/poem/${notification.recitation?.recitation_uuid}`}>
                <span>recording</span>
              </Link>
              {"."}
            </p>
            <time>
              {date.getMonth() + 1}/{date.getDay()}/{date.getFullYear()}
            </time>
          </div>
          <div className="user-link">
            <button
              style={{ marginLeft: "1rem" }}
              className="button small primary"
              onClick={() =>
                this.deleteNotification(
                  notification.id,
                  recitation?.recitation_uuid,
                  type
                )
              }
            >
              View
            </button>
          </div>
        </div>
      );
    } else if (type === 2) {
      return (
        <div className="notification-user">
          <div className="user-image">
            {user_images === "" ? ( // display default
              <img src={profileImage} alt="user-profile-img" className="p-0" />
            ) : (
              // display profile img
              <img src={user_images} alt="user-profile-img" className="p-0" />
            )}
          </div>
          <div className="user-info">
            <p>
              <Link to={`/user/${notification.user?.user_uuid}`}>{name}</Link>{" "}
              left a comment on{" "}
              <Link to={`/recitations/${recitation?.recitation_uuid}`}>
                {recitation?.poem.name}
              </Link>
              {"."}
            </p>
            <time>
              {date.getMonth() + 1}/{date.getDay()}/{date.getFullYear()}
            </time>
          </div>
          <button
            style={{ marginLeft: "1rem" }}
            className="button small primary"
            onClick={() =>
              this.deleteNotification(
                notification.id,
                recitation?.recitation_uuid,
                type
              )
            }
          >
            View
          </button>
        </div>
      );
    } else if (type === 3) {
      return (
        <div className="notification-user">
          <div className="user-image">
            {user_images === "" ? ( // display default
              <img src={profileImage} alt="user-profile-img" className="p-0" />
            ) : (
              // display profile img
              <img src={user_images} alt="user-profile-img" className="p-0" />
            )}
          </div>
          <div className="user-info">
            <p>
              <Link to={`/user/${notification.user?.user_uuid}`}>{name}</Link>{" "}
              started following you.
            </p>
            <time>
              {date.getMonth() + 1}/{date.getDay()}/{date.getFullYear()}
            </time>
          </div>
          <div className="user-link">
            <button
              style={{ marginLeft: "1rem" }}
              className="button small primary"
              onClick={() =>
                this.deleteNotification(
                  notification.id,
                  notification.user?.user_uuid,
                  type
                )
              }
            >
              View
            </button>
          </div>
        </div>
      );
    } else if (type === 4) {
      return (
        <div className="notification-user">
          <div className="user-image">
            {user_images === "" ? ( // display default
              <img src={profileImage} alt="user-profile-img" className="p-0" />
            ) : (
              // display profile img
              <img src={user_images} alt="user-profile-img" className="p-0" />
            )}
          </div>
          <div className="user-info">
            <p>
              <Link to={`/user/${notification.user?.user_uuid}`}>{name}</Link>{" "}
              added you to a new group{" "}
              <Link to={`/groups/${notification.group.group_uuid}`}>
                {notification.group.name}
              </Link>
              {""}.
            </p>
            <time>
              {date.getMonth() + 1}/{date.getDay()}/{date.getFullYear()}
            </time>
          </div>
          <div className="user-link">
            <button
              style={{ marginLeft: "1rem" }}
              className="button small primary"
              onClick={() =>
                this.deleteNotification(
                  notification.id,
                  notification.group.group_uuid,
                  type
                )
              }
            >
              View
            </button>
            {/* <Link
              to={`/groups/${notification.group_uuid}`}
              className="button small primary"
            >
              View
            </Link> */}
          </div>
        </div>
      );
    } else if (type === 5) {
      return (
        <div className="notification-user">
          <div className="user-image">
            {user_images === "" ? ( // display default
              <img src={profileImage} alt="user-profile-img" className="p-0" />
            ) : (
              // display profile img
              <img src={user_images} alt="user-profile-img" className="p-0" />
            )}
          </div>
          <div className="user-info">
            <p>
              <Link to={`/user/${notification.user?.user_uuid}`}>{name}</Link>{" "}
              replied to your comment{" "}
              {
                <Link to={`/recitations/${recitation?.recitation_uuid}`}>
                  {notification.text}
                </Link>
              }
              .
            </p>
            <time>
              {date.getMonth() + 1}/{date.getDay()}/{date.getFullYear()}
            </time>
          </div>
          <div className="user-link">
            <button
              style={{ marginLeft: "1rem" }}
              className="button small primary"
              onClick={() =>
                this.deleteNotification(
                  notification.id,
                  recitation?.recitation_uuid,
                  type
                )
              }
            >
              View
            </button>
            {/* <Link
              to={`/groups/${notification.group_uuid}`}
              className="button small primary"
            >
              View
            </Link> */}
          </div>
        </div>
      );
    } else if (type === 6) {
      return (
        <div className="notification-user">
          <div className="user-image">
            {user_images === "" ? ( // display default
              <img src={profileImage} alt="user-profile-img" className="p-0" />
            ) : (
              // display profile img
              <img src={user_images} alt="user-profile-img" className="p-0" />
            )}
          </div>
          <div className="user-info">
            <p>
              <Link to={`/user/${notification.user?.user_uuid}`}>{name}</Link>{" "}
              added to the discussion{" "}
              {
                <Link to={`/discussion/${discussion?.discussion_uuid}`}>
                  {discussion?.title}
                </Link>
              }
              .
            </p>
            <time>
              {date.getMonth() + 1}/{date.getDay()}/{date.getFullYear()}
            </time>
          </div>
          <div className="user-link">
            <button
              style={{ marginLeft: "1rem" }}
              className="button small primary"
              onClick={() =>
                this.deleteNotification(
                  notification.id,
                  discussion?.discussion_uuid,
                  type
                )
              }
            >
              View
            </button>
            {/* <Link
              to={`/groups/${notification.group_uuid}`}
              className="button small primary"
            >
              View
            </Link> */}
          </div>
        </div>
      );
    }
  }
}
