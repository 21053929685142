import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "./../../static/images/logo-white.png";

class PoemInfo extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const handleImageError = e => {
      e.target.src = logo;
    };

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    const recording = this.props.recording;
    const poem = recording.poem;
    const user = recording.user;
    const createdTime = recording.created_at;
    const createdDate = new Date(createdTime);
    const publishedTime = recording.poem?.year;

    return (
      <div className="poem-banner-wrapper">
        <div className="poem-banner-background">
          <img
            src={recording.cover}
            onError={handleImageError}
            alt={poem.name}
          />
        </div>
        <div className="poem-banner-content wrap">
          <div className="poem-banner-image">
            <a
              className="play-button-poem"
              style={{ cursor: "pointer" }}
              onClick={() => this.props.playRecording(recording)}
            >
              <FontAwesomeIcon icon="caret-right" />
            </a>
          </div>
          <div className="poem-banner-title">
            <h2>{poem.name}</h2>
            {/* make a column with two rows */}
            <div className="poem-banner-cta">
              <div className="poem-banner-cta-row">
                <ul className="poem-intro poem-banner-subtitle">
                  <li>
                    <b>By: </b>
                    {recording.poem.poet}
                  </li>
                  <li>
                    <b>Created Date: </b>
                    <time>
                      {monthNames[createdDate.getMonth()]}{" "}
                      {createdDate.getUTCDate()}, {createdDate.getFullYear()}
                    </time>
                  </li>
                  <li>
                    <b>Read by: </b>
                    {/* link to recording.poem.user.user_uuid */}
                    <Link
                      style={{ color: "white" }}
                      to={`/user/${user.user_uuid}`}
                    >
                      {user.first_name} {user.last_name}
                    </Link>
                  </li>
                  {publishedTime ? (
                    <li>
                      <b>Year of Publication: </b>
                      {publishedTime}
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      // <div className="poem-banner wrap">
      //   <div className="poem-banner-image">
      //     <img
      //       src={recording.cover}
      //       alt={poem.name}
      //       onError={handleImageError}
      //     />
      //   </div>
      //   <div className="poem-banner-content">
      //     <h2>
      //       {poem.name}
      //       <a
      //         className="play-button"
      //         style={{ cursor: "pointer" }}
      //         onClick={() => this.props.playRecording(recording)}
      //       >
      //         <FontAwesomeIcon icon="caret-right" className="fa-3x" />
      //       </a>
      //     </h2>
      //     <p>
      //       <span>Read by </span>
      //       <Link to={`/user/${user.user_uuid}`}>
      //         {user.first_name} {user.last_name}{" "}
      //       </Link>
      //     </p>
      //   </div>
      // </div>
    );
  }
}

export default PoemInfo;
