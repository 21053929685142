import React, { Component } from "react";
import Header from "../Headers/Header.js";
import { ImagePicker } from "react-file-picker";
import {
  AvForm,
  AvInput,
  AvGroup,
  AvFeedback
} from "availity-reactstrap-validation";
import axios from "axios";
import { toast } from "react-toastify";

export default class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedImage: null,
      name: "",
      cover: ""
    };

    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  handleImageChange(base64) {
    fetch(base64)
      .then(res => res.blob())
      .then(blob => {
        this.setState({ cover: blob });
      });

    this.setState({ selectedImage: base64 });
  }
  handleTextChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleValidSubmit(event) {
    event.preventDefault();
    const imageUploadUrl = this.props.apiHost + "uploadimage/";

    let headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };
    let generatedBlobName =
      parseInt(Math.round(new Date().getTime() / 1000)) + "-" + this.state.name;
    var bodyFormData = new FormData();
    bodyFormData.set("name", this.state.name);
    if (this.state.cover === undefined) {
      bodyFormData.append("cover", this.state.cover);
      throw new Error(`No Image uploaded`);
    } else {
      bodyFormData.append("cover", this.state.cover, generatedBlobName);
    }

    axios
      .post(imageUploadUrl, bodyFormData, {
        headers: headerData
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({
            name: "",
            selectedImage: null
          });
          toast.success("Image uploaded successfully");
        }
      })
      .catch(function(error) {
        if (error.message === "No Image uploaded") {
          toast.error("Please upload an image");
        } else if (error.message === "does not exist") {
          toast.error("A recitation with this name does not exist");
        } else {
          toast.error("Something went wrong with the upload!");
        }
      });
  }

  render() {
    return (
      <div className="App">
        <Header {...this.props} />

        {!this.state.selectedImage ? (
          <div className="chosen-image"></div>
        ) : (
          <img
            style={{ height: "230px" }}
            src={`${this.state.selectedImage}`}
          />
        )}

        <div className="file-picker">
          <ImagePicker
            extensions={["jpg", "jpeg", "png"]}
            dims={{
              minWidth: 100,
              maxWidth: 5000,
              minHeight: 100,
              maxHeight: 5000
            }}
            onChange={this.handleImageChange}
            onError={errMsg => toast.error(errMsg)}
          >
            <button className="button primary">Upload Cover Image</button>
          </ImagePicker>
        </div>

        <AvForm
          style={{ width: "50%", margin: "auto" }}
          onValidSubmit={this.handleValidSubmit}
        >
          <AvGroup style={{ margin: "10px" }}>
            <AvInput
              onChange={this.handleTextChange}
              name="name"
              value={this.state.name}
              className="form-input"
              type="text"
              placeholder="Title"
              required
            ></AvInput>
            <AvFeedback>Please enter the title</AvFeedback>
          </AvGroup>

          <button className="button primary">Submit</button>
        </AvForm>
      </div>
    );
  }
}

// UploadForm.propTypes = {
//     history: PropTypes.object,
//     apiHost: PropTypes.string,
//     activeUser: PropTypes.object,
//     location: PropTypes.object
//   };
