import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Sidebar from "../../Sidebar/Sidebar";
import logo from "./../../static/images/logo-white.png";
import profileImage from "./../../static/images/profile.png";
import homeIcon from "./../../static/images/home-2.svg";
import notificationsIcon from "./../../static/images/notification.svg";
import notificationsActiveIcon from "./../../static/images/notification_active.svg";
import axios from "axios";
import { toast } from "react-toastify";

import {
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Form,
  InputGroup,
  InputGroupAddon,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input
} from "reactstrap";
import ModalAddGroup from "./Modals/ModalAddGroup";
import ModalAddPlaylist from "./Modals/ModalAddPlaylist";

class PostLoginHeader extends Component {
  constructor(props) {
    super(props);

    // loaded the searchTerm if exists
    let searchTerm = "";
    if (props.location.state && props.location.state.searchTerm) {
      searchTerm = props.location.state.searchTerm;
    }

    this.state = {
      isOpen: false,
      searchTerm: searchTerm,
      hasSearched: false,
      dropdownOpen: false,
      groupModal: false,
      playlistModal: false
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      isOpen: !this.state.isOpen,
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  toggleGroupModal = () => {
    this.setState(prevState => ({ groupModal: !prevState.groupModal }));
  };

  togglePlaylistModal = () => {
    this.setState(prevState => ({ playlistModal: !prevState.playlistModal }));
  };

  sideMenuButtonClick = () => {
    this.props.toggleSideMenu();
  };

  logoutHandler = event => {
    let url = this.props.apiHost + "logout/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    axios
      .post(url, new FormData(), {
        headers: headerData
      })
      .then(response => {
        event.preventDefault();
        if (localStorage.getItem("isAuthenticated") !== undefined) {
          localStorage.removeItem("isAuthenticated");
          localStorage.removeItem("activeUser");
        }
        this.props.setAuthStatus(false, {});
        this.props.history.push("/");
      })
      .catch(error => {
        toast.error("Error in logging out");
      });
  };

  // handle the search form submission
  handleSubmit = e => {
    if (this.state.searchTerm !== "") {
      e.preventDefault();
      this.setState({ hasSearched: true });

      if (window.location.pathname === "/search") {
        this.props.onSearch(this.state.searchTerm);
      }
    }
  };

  // handle the search form onChange
  handleSearchChange = e => {
    this.setState({ searchTerm: e.target.value });
  };

  render() {
    // init the status of side menu
    const sideMenuOpen = this.props.sideMenuOpen;

    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    const user = activeUser;

    // if we're on search page, don't redirect
    // else, redirect when use searches
    if (
      this.state.hasSearched === true &&
      window.location.pathname !== "/search"
    ) {
      return (
        <Redirect
          to={{
            pathname: "/search",
            // param: this.state.searchTerm,
            state: { searchTerm: this.state.searchTerm }
          }}
        />
      );
    }

    return (
      <Navbar light expand="xs" sticky="top" className="bg-blue">
        {/* <Button className="bg-none border-0" onClick={this.sideMenuButtonClick}>
          <FontAwesomeIcon className="font-white" icon="bars" />
        </Button> */}
        <Sidebar className={`sidebar ${sideMenuOpen ? "open" : "closed"}`} />
        <NavbarToggler onClick={this.toggle} />
        <Link to="/" className="logo">
          <img src={logo} alt="recitedverse-logo" />
          <span>RECITED VERSE</span>
        </Link>
        <Form className="search m-auto" onSubmit={this.handleSubmit}>
          <InputGroup className="d-none d-sm-flex">
            <Input
              value={this.state.searchTerm}
              onChange={this.handleSearchChange}
              className="header--text-input"
              placeholder="Search"
            />
            <InputGroupAddon addonType="append">
              <span className="input-group-text bg-none border-0">
                <FontAwesomeIcon
                  icon="search"
                  className="font-white"
                  cursor={"pointer"}
                  onClick={this.handleSubmit}
                />
              </span>
            </InputGroupAddon>
          </InputGroup>
        </Form>
        <Nav navbar>
          <NavItem>
            <Dropdown
              isOpen={this.state.dropdownOpen}
              toggle={this.toggle}
              className="create-btn"
            >
              <DropdownToggle caret>Create</DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <Link
                    to={{
                      pathname: "/upload"
                    }}
                  >
                    Recording
                  </Link>
                </DropdownItem>
                <DropdownItem onClick={this.toggleGroupModal}>
                  Group
                  <ModalAddGroup
                    {...this.props}
                    groupModal={this.state.groupModal}
                    toggleGroupModal={this.toggleGroupModal}
                  />
                </DropdownItem>
                <DropdownItem onClick={this.togglePlaylistModal}>
                  Playlist
                  <ModalAddPlaylist
                    {...this.props}
                    playlistModal={this.state.playlistModal}
                    togglePlaylistModal={this.togglePlaylistModal}
                  />
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} className="fw-bold" to="/">
              <img height="24" width="24" src={homeIcon} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to="/notifications">
              {this.props.activeUser.notification_count > 0 ? (
                <img
                  style={{ color: "white" }}
                  height="20"
                  width="20"
                  src={notificationsActiveIcon}
                />
              ) : (
                <img height="20" width="20" src={notificationsIcon} />
              )}
            </NavLink>
          </NavItem>
          <UncontrolledDropdown nav inNavbar className="profile">
            <DropdownToggle nav className="p-0">
              {user && user.avatar && user.avatar !== "" ? (
                // display profile img
                <img
                  src={this.props.activeUser.avatar}
                  alt="user-profile-img"
                  className="nav-profile p-0"
                />
              ) : (
                // display default
                <img
                  src={profileImage}
                  alt="user-profile-img"
                  className="nav-profile p-0"
                />
              )}
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <NavLink
                  tag={Link}
                  to={"/user/" + this.props.activeUser.user_uuid}
                  className="p-0"
                >
                  <FontAwesomeIcon icon="user" />{" "}
                  {this.props.activeUser.first_name}{" "}
                  {this.props.activeUser.last_name}
                </NavLink>
              </DropdownItem>
              {/* <DropdownItem>
                    <NavLink href="/user/<userId>/edit" className="p-0"><FontAwesomeIcon icon="cog"/> Settings</NavLink>
                  </DropdownItem> */}
              <DropdownItem divider />
              <DropdownItem>
                <NavLink onClick={this.logoutHandler} className="p-0">
                  Logout
                </NavLink>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Navbar>
    );
  }
}

PostLoginHeader.propTypes = {
  activeUser: PropTypes.object,
  setAuthStatus: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object
};
export default PostLoginHeader;
