import React, { Component } from "react";
import Header from "./../Headers/Header.js";
import StreamingMainContent from "./components/StreamingMainContent.js";
import "./Home.scss";

class Home extends Component {
  render() {
    return (
      <div className="App">
        <Header {...this.props} />
        <div className="page-wrapper homepage">
          <StreamingMainContent
            {...this.props}
            token={this.props.activeUser.token}
          />
        </div>
      </div>
    );
  }
}

export default Home;
