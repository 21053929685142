import React, { Component } from "react";
import DiscussionsList from "./DiscussionsList";
import ModalAddDiscussion from "./ModalAddDiscussion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Discussions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discussionModal: false,
      recitations: [],
      editable: false
    };
  }

  updateEditable = () => {
    const { activeUser } = this.props;
    if (activeUser) {
      this.setState({ editable: activeUser.is_staff });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.activeUser !== prevProps.activeUser) {
      this.updateEditable();
    }
  }

  componentDidMount() {
    this.updateEditable();
  }

  toggleDiscussionModal = () => {
    this.setState(prevState => ({
      discussionModal: !prevState.discussionModal
    }));
  };

  render() {
    return (
      <div>
        {this.props.groupData.group_creator.user_uuid ===
          this.props.activeUser.user_uuid || this.state.editable ? (
          // add a plus button to add a discussion
          <div
            className="add-discussion"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: "1rem"
            }}
          >
            <button
              className="button primary"
              onClick={this.toggleDiscussionModal}
            >
              <FontAwesomeIcon icon="plus" /> Add Discussion
            </button>
          </div>
        ) : null}

        <ModalAddDiscussion
          {...this.props}
          discussionModal={this.state.discussionModal}
          toggleDiscussionModal={this.toggleDiscussionModal}
        />
        <DiscussionsList
          {...this.props}
          discussions={this.props.discussions}
          editable={this.editable}
        />
      </div>
    );
  }
}

export default Discussions;
