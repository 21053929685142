import React, { Component } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import {
  AvForm,
  AvField,
  AvInput,
  AvGroup,
  AvFeedback
} from "availity-reactstrap-validation";
import axios from "axios";
import { toast } from "react-toastify";

export default class ModalEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      lyrics: "",
      user: this.props.activeUser,
      pubYear: "",
      poet: "",
      showSuccess: false,
      res: {},
      poemExists: false,
      poemId: null,
      tags: [],
      isPrivate: false,
      cover: "",
      selectedImage: ""
    };

    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.fetchRecitationData = this.fetchRecitationData.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  componentDidMount() {
    this.fetchRecitationData();
  }

  fetchRecitationData() {
    const recitation_uuid = this.props.currentRecording.recitation_uuid;
    const recitationUrl =
      this.props.apiHost + "recitations/" + recitation_uuid + "/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(recitationUrl, {
        headers: headerData
      })
      .then(response => {
        this.setState({
          cover: response.data.cover,
          name: response.data.poem.name,
          poet: response.data.poem.poet,
          pubYear: response.data.poem.year,
          lyrics: response.data.poem.lyrics,
          isPrivate: response.data.private,
          selectedImage: response.data.cover
        });
      })
      .catch(err => {
        toast.error("There was an error getting the recitation data");
      });
  }

  handleTextChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i)
    });
  }

  handleCheckbox(event) {
    this.setState({ isPrivate: event.target.checked });
  }

  handleAddition(tag) {
    this.setState(state => ({ tags: [...state.tags, tag] }));
  }

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  }

  handleImageChange(base64) {
    fetch(base64)
      .then(res => res.blob())
      .then(blob => {
        this.setState({ cover: blob });
      });

    this.setState({ selectedImage: base64 });
  }

  handleValidSubmit(event) {
    event.preventDefault();
    let data = {
      name: this.state.name,
      lyrics: this.state.lyrics,
      poet: this.state.poet,
      year: parseInt(this.state.pubYear)
    };
    //update call to poems
    const poemId = this.props.currentRecording.poem.poem_uuid;
    const poemUpdateUrl = this.props.apiHost + "poems/" + poemId + "/update/";
    let headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };
    axios
      .post(poemUpdateUrl, data, {
        headers: headerData
      })
      .then(response => {
        const recitationId = this.props.currentRecording.recitation_uuid;

        var bodyFormData = new FormData();

        bodyFormData.set("private", this.state.isPrivate);

        const recPostUrl =
          this.props.apiHost +
          "recitations/" +
          recitationId +
          "/updateprivate/";

        axios
          .post(recPostUrl, bodyFormData, {
            headers: headerData
          })
          .then(res => {
            toast.success("Poem was updated successfully");
            window.location.reload(false);
          })
          .catch(function(err) {
            toast.error("Something went wrong with the upload!");
            window.location.reload(false);
          });
      })
      .catch(err => {
        toast.error("Something went wrong with the upload!");
      });

    this.props.handler();
  }

  render() {
    return (
      <div className="App">
        <h3 className="upload-header">Poem and Title Details</h3>

        <AvForm
          style={{ width: "50%", margin: "auto" }}
          onValidSubmit={this.handleValidSubmit}
        >
          <AvGroup style={{ margin: "10px" }}>
            <AvInput
              onChange={this.handleTextChange}
              name="name"
              value={this.state.name}
              className="form-input"
              type="text"
              placeholder="Title"
              required
            ></AvInput>
            <AvFeedback>Please enter the title</AvFeedback>
          </AvGroup>

          <AvGroup style={{ margin: "10px", marginTop: "10px" }}>
            <AvField
              onChange={this.handleTextChange}
              name="poet"
              value={this.state.poet}
              type="text"
              placeholder="Poet"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Please enter the Poet's name"
                },
                pattern: {
                  value: "[A-Za-z]",
                  errorMessage: "The name can only have letters"
                }
              }}
            ></AvField>
          </AvGroup>

          <AvGroup style={{ margin: "10px", marginTop: "10px" }}>
            <AvField
              onChange={this.handleTextChange}
              name="pubYear"
              value={this.state.pubYear}
              type="text"
              placeholder="Year of Publication"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Please enter the year of publication"
                },
                pattern: {
                  value: "[0-9]",
                  errorMessage: "The year can only have number"
                },
                minLength: {
                  value: 4,
                  errorMessage: "The year must have 4 characters"
                },
                maxLength: {
                  value: 4,
                  errorMessage: "The year must have 4 characters"
                }
              }}
            ></AvField>
          </AvGroup>

          <Input
            onChange={this.handleTextChange}
            name="lyrics"
            value={this.state.lyrics}
            type="textarea"
            placeholder="Text of Poem"
          ></Input>

          <FormGroup check style={{ margin: "10px" }}>
            <Label check>
              <Input
                type="checkbox"
                style={{ height: "auto" }}
                checked={this.state.isPrivate}
                onChange={this.handleCheckbox}
              />
              This upload is private
            </Label>
          </FormGroup>

          <button className="upload-button">Submit</button>
        </AvForm>
      </div>
    );
  }
}
