import React from "react";
import RecitationList from "./RecitationList";

class ProfileRecitations extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const user = this.props.user;

    if (Object.keys(user).length > 0) {
      return (
        <div className="profile-section">
          <RecitationList {...this.props} />
        </div>
      );
    } else {
      return <p>You have no uploads yet.</p>;
    }
  }
}

export default ProfileRecitations;
