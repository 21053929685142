import React, { Component } from "react";
import UploadItem from "./UploadItem";

class UploadList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const recitations = this.props.recitations;

    return (
      <div>
        {recitations !== undefined && recitations.length > 0 ? (
          <ul className="content-list reading-list">
            {recitations.map(recitation => (
              <UploadItem key={recitation.id} recitation={recitation} />
            ))}
          </ul>
        ) : (
          <div className="wrap center">
            <p>
              No recordings exist for this group. Please upload a recording to
              see Featured Recordings.
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default UploadList;
