import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import axios from "axios";
import logo from "../../static/images/logo-white.png";
import { toast } from "react-toastify";
import "../Playlist.scss";

import { Button } from "reactstrap";

class RecitationListPlaylistSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeUser: null,
      recitationLike: null,
      dropdownOpen: false
    };

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    const userJSON = localStorage.getItem("activeUser");
    const user = JSON.parse(userJSON);

    this.setState({
      activeUser: user
    });
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  getApiData = (url, name) => {
    var self = this;
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(url, {
        headers: headerData
      })
      .then(function(response) {
        if (name === "recitationLike") {
          self.setState({
            recitationLike: response.data
          });
        } else {
          self.setState({
            recitationLike: null
          });
        }
      })
      .catch(function(error) {
        toast.error("There was an error getting recitation like");
      });
  };

  postApiData = url => {
    var self = this;
    axios
      .post(url)
      .then(function(response) {
        self.setState({
          user: response.data
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  addToPlaylist = recitation_uuid => {
    const playlist_uuid = window.location.pathname.replace("/playlists/", "");

    const playListUrl =
      this.props.apiHost + "playlists/" + playlist_uuid + "/utils/add";
    const bodyFormData = new FormData();

    bodyFormData.set("recitation_uuid", recitation_uuid);

    let headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };
    axios
      .post(playListUrl, bodyFormData, {
        headers: headerData
      })
      .then(response => {
        if (response.status === 200) {
          toast.success("This recording has been added to your library.");
          this.toggle();
        }
      })
      .catch(error => {
        toast.success("There was an error adding recording to playlist");
      });
  };

  getRecitationLike = recitationUuid => {
    if (this.state.activeUser === null) {
      return null;
    }
    const activeUserUuid = this.state.activeUser["user_uuid"];
    const url =
      this.props.apiHost +
      "users/" +
      activeUserUuid +
      "/recitations/" +
      recitationUuid +
      "/like/";
    this.getApiData(url, "recitationLike");
    return;
  };

  renderRecitations = recitations => {
    const handleImageError = e => {
      e.target.src = logo;
    };

    let content;

    if (recitations !== undefined && recitations.length > 0) {
      content = (
        <ul className="content-list reading-list">
          {recitations.map(recitation => {
            if (recitation === null) {
              return;
            }

            return (
              <li
                key={recitation.id}
                className="content-list-item reading-list-item"
              >
                <div className="item-image">
                  <img
                    src={recitation.cover}
                    alt={recitation.poem.name}
                    onError={handleImageError}
                  ></img>
                </div>
                <div className="item-info">
                  <h5>
                    <Link to={`/recitations/${recitation.recitation_uuid}`}>
                      {recitation.poem.name}
                    </Link>
                  </h5>
                  <h5></h5>
                  <p className="p-0 m-0 featured-poet"></p>
                  <p>
                    <span>By</span>
                    <Link
                      to={"/user/" + recitation.user?.user_uuid}
                      className="featured-poet"
                    >
                      {recitation.user?.first_name} {recitation.user?.last_name}
                    </Link>
                  </p>
                  <p>
                    <Link
                      to={`/recitations/${recitation?.recitation_uuid}`}
                      className="featured-poet"
                    >
                      {recitation.poem?.poet}
                    </Link>
                  </p>
                </div>
                <div className="modal-buttons">
                  <Button
                    className="buttons"
                    color="success"
                    onClick={() =>
                      this.addToPlaylist(recitation.recitation_uuid)
                    }
                  >
                    +
                  </Button>
                  <Button
                    className="buttons"
                    color="primary"
                    onClick={() =>
                      this.addToPlaylist(recitation.recitation_uuid)
                    }
                  >
                    Play
                  </Button>
                </div>
              </li>
            );
          })}
        </ul>
      );
    } else {
      content = (
        <div className="wrap center">
          <h2 className="page-title">No Recordings to show</h2>
        </div>
      );
    }
    return content;
  };

  render() {
    const recitations = this.renderRecitations(this.props.recitations);

    return <div className="tab-container">{recitations}</div>;
  }
}

RecitationListPlaylistSearch.propTypes = {
  recitations: PropTypes.array,
  location: PropTypes.object,
  apiHost: PropTypes.string
};

export default RecitationListPlaylistSearch;
