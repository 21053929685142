import React, { Component } from "react";
import Header from "./../Headers/Header.js";
import PdfButton from "./PdfButton.js";
import ProjectView from "./ProjectView.js";
import "./Project.scss";

class Project extends Component {
  render() {
    return (
      <div className="App project-page">
        <Header {...this.props} />
        <div className="page-wrapper project-page">
          <ProjectView {...this.props} />
        </div>
      </div>
    );
  }
}

export default Project;
