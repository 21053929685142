import React, { Component } from "react";
import Header from "../Headers/Header.js";
import axios from "axios";
import { toast } from "react-toastify";
import {
  AvForm,
  AvInput,
  AvField,
  AvGroup,
  AvFeedback
} from "availity-reactstrap-validation";

export default class UploadPoet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      poet: ""
    };

    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  handleTextChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleValidSubmit(event) {
    event.preventDefault();
    const uploadPoetUrl = this.props.apiHost + "uploadpoet/";
    let headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };
    var bodyFormData = new FormData();
    bodyFormData.set("name", this.state.name);
    bodyFormData.set("poet", this.state.poet);

    axios
      .post(uploadPoetUrl, bodyFormData, {
        headers: headerData
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({
            name: "",
            poet: ""
          });
          toast.success("Poet name updated successfully");
        }
      })
      .catch(function(error) {
        if (error.message === "Request failed with status code 400") {
          toast.error("A poem with this name does not exist");
        } else {
          toast.error("Something went wrong with the upload!");
        }
      });
  }

  render() {
    return (
      <div className="App">
        <Header {...this.props} />

        <AvForm
          style={{ width: "50%", margin: "auto" }}
          onValidSubmit={this.handleValidSubmit}
        >
          <AvGroup style={{ margin: "10px" }}>
            <AvInput
              onChange={this.handleTextChange}
              name="name"
              value={this.state.name}
              className="form-input"
              type="text"
              placeholder="Title"
              required
            ></AvInput>
            <AvFeedback>Please enter the title</AvFeedback>
          </AvGroup>

          <AvGroup style={{ margin: "10px", marginTop: "10px" }}>
            <AvField
              onChange={this.handleTextChange}
              name="poet"
              value={this.state.poet}
              type="text"
              placeholder="Poet"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Please enter the Poet's name"
                },
                pattern: {
                  value: "[A-Za-z]",
                  errorMessage: "The name can only have letters"
                }
              }}
            ></AvField>
          </AvGroup>

          <button className="upload-button">Submit</button>
        </AvForm>
      </div>
    );
  }
}
