import React from "react";
import CommentList from "./CommentList";

class Comment extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="comments">
        <h4>Comments</h4>
        <CommentList {...this.props} />
      </div>
    );
  }
}

export default Comment;
