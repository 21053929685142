import React, { Component } from "react";
import Header from "../Headers/Header.js";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";
import axios from "axios";
import { toast } from "react-toastify";
import FollowingItem from "./FollowingItem";
import "./Followings.css";

export default class Followings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      followingList: []
    };
    this.fetchFollowingList = this.fetchFollowingList.bind(this);
  }

  componentDidMount() {
    this.fetchFollowingList();
  }

  fetchFollowingList() {
    const userId = this.props.match.params.userId;
    const followerUrl = this.props.apiHost + "users/" + userId + "/followings/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(followerUrl, {
        headers: headerData
      })
      .then(response => {
        if (response.status === 200) {
          this.setState({
            groupData: response.data,
            loading: false
          });
          this.setState({ followingList: response.data });
        }
      })
      .catch(error => {
        toast.error("Could not get data.");
      });
  }
  render() {
    return (
      this.state.loading === false && (
        <div className="App">
          <Header {...this.props} />
          <div className="page-wrapper">
            <div className="following-list wrap">
              {this.state.followingList.map(item => (
                <li key={item.id} className="following-list-item">
                  <FollowingItem user={item} />
                </li>
              ))}
            </div>
          </div>
        </div>
      )
    );
  }
}
