import React from "react";
import { Jumbotron, Row, Col } from "reactstrap";
import FAQSearchImage from "./../../static/images/faq-search.jpg";

const Example = () => {
  var sectionStyle = {
    backgroundImage: "url(" + FAQSearchImage + ")"
  };

  return (
    <Jumbotron fluid className="p-0 m-0 bg-none">
      <Row>
        <div className="bg-scroll" style={sectionStyle}></div>
      </Row>
      <Row className="bg-front">
        <Col className="py-5 m-auto">
          <h1 className="display-5 text-center my-4">How can we help you?</h1>
          <Col xs="8" sm="8" md="4" lg="4" className="m-auto"></Col>
        </Col>
      </Row>
    </Jumbotron>
  );
};
export default Example;
