import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import { toast } from "react-toastify";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

class ModalAddDiscussion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      subtitle: "",
      recitationId: null
    };
  }

  handleSelectChange = event => {
    this.setState({ recitationId: event.value });
  };

  handleTitleChange = event => {
    this.setState({ title: event.target.value });
  };

  handleSubTitleChange = event => {
    this.setState({ subtitle: event.target.value });
  };

  createDiscussion = () => {
    if (
      !this.state.title ||
      this.state.title === "" ||
      (!this.state.subtitle || this.state.subtitle === "")
    ) {
      toast.error("Discussion title and subtitle are required");
      return;
    }

    const postUrl =
      this.props.apiHost +
      "groups/" +
      this.props.groupData.group_uuid +
      "/discussions/create/";

    let headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };

    var bodyFormData = new FormData();
    bodyFormData.set("title", this.state.title);
    bodyFormData.set("subtitle", this.state.subtitle);
    bodyFormData.set("recitation", this.state.recitationId);

    axios
      .post(postUrl, bodyFormData, {
        headers: headerData
      })
      .then(res => {
        if (res.status == 200 || res.status == 201) {
          this.props.history.push("/discussion/" + res.data.discussion_uuid);
          toast.success("Discussion created successfully!");
        }
      })
      .catch(function(err) {
        toast.error("Something went wrong with creating the discussion!");
      });
  };

  toggleModal = () => {
    this.props.toggleDiscussionModal();
  };

  getRecitations = () => {
    return this.props.featuredRecordings.map(recording => {
      return { label: recording.poem.name, value: recording.id };
    });
  };

  render() {
    return (
      <Modal isOpen={this.props.discussionModal} toggle={this.toggleModal}>
        <ModalHeader toggle={this.toggleModal}>Create a Discussion</ModalHeader>
        <ModalBody>
          <input
            placeholder="Enter a discussion title"
            type="text"
            value={this.state.groupName}
            required
            onChange={this.handleTitleChange}
            className="form-input form-control"
          />
          <br></br>
          <Input
            ref={input => (this.inputRef = input)}
            onChange={this.handleSubTitleChange}
            type="textarea"
            name="text"
            placeholder="Enter a description for your discussion"
            value={this.state.subtitle}
          />
          <br></br>
          <Select
            closeMenuOnSelect={true}
            components={animatedComponents}
            isSingle
            options={this.getRecitations()}
            onChange={this.handleSelectChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={this.toggleModal}>
            Close
          </Button>
          <Button color="primary" onClick={this.createDiscussion}>
            Create Discussion
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ModalAddDiscussion;
