import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import logo from "../../static/images/logo-white.png";

class PlaylistRecitations extends Component {
  constructor(props) {
    super(props);
  }

  toggleModalDelete = recitation => {
    this.props.toggleModalDelete(recitation);
  };

  renderRecitations = recitations => {
    const handleImageError = e => {
      e.target.src = logo;
    };

    let content;
    const editable = this.props.editable;

    if (recitations !== undefined && recitations.length > 0) {
      content = (
        <ul className="content-list reading-list">
          {recitations.map(recitation => {
            if (recitation === null) {
              return;
            }
            return (
              <li
                key={recitation.id}
                className="content-list-item reading-list-item"
              >
                <div className="item-image">
                  <img
                    src={recitation.cover}
                    alt={recitation.poem.name}
                    onError={handleImageError}
                  ></img>
                </div>
                <div className="item-info">
                  <h5>
                    <Link to={`/recitations/${recitation.recitation_uuid}`}>
                      {recitation.poem.name}
                    </Link>
                  </h5>
                  <h5></h5>
                  <p className="p-0 m-0 featured-poet"></p>
                  <p>
                    <span>By</span>
                    <Link
                      to={"/user/" + recitation.user?.user_uuid}
                      className="featured-poet"
                    >
                      {recitation.user?.first_name} {recitation.user?.last_name}
                    </Link>
                  </p>
                  <p>
                    <Link
                      to={`/recitations/${recitation?.recitation_uuid}`}
                      className="featured-poet"
                    >
                      {recitation.poem?.poet}
                    </Link>
                  </p>
                </div>
                <div className="item-cta">
                  {/* <button class="dropdown-item" type="button">
                  </button> */}
                  <span>{recitation.duration}</span>
                  {editable ? (
                    <Button onClick={() => this.toggleModalDelete(recitation)}>
                      <FontAwesomeIcon icon={faTrash} className="mr-2" />
                    </Button>
                  ) : (
                    <span></span>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      );
    } else {
      content = (
        <div className="wrap center">
          <h2 className="page-title">No Recordings to show</h2>
        </div>
      );
    }
    return content;
  };

  render() {
    const recitations = this.renderRecitations(this.props.recitations);

    return <div className="tab-container">{recitations}</div>;
  }
}

PlaylistRecitations.propTypes = {
  recitations: PropTypes.array,
  location: PropTypes.object,
  apiHost: PropTypes.string
};

export default PlaylistRecitations;
