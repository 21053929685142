import React, { Component } from "react";
import GroupList from "./GroupList";
import ModalGroupAdd from "./Modals/ModalGroupAdd";

export default class Groups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalAdd: false
    };
  }

  toggleModalAdd = () => {
    this.setState(prevState => ({ modalAdd: !prevState.modalAdd }));
  };

  render() {
    const groups = this.props.groups;

    return (
      <div className="profile-section profile-groups">
        <ModalGroupAdd
          {...this.props}
          modalAdd={this.state.modalAdd}
          toggleModalAdd={this.toggleModalAdd}
        />
        <GroupList
          {...this.props}
          items={groups}
          addGroup={this.toggleModalAdd}
        />
      </div>
    );
  }
}
