import React, { Component } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles

class PoemDetail extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const recording = this.props.recording;
    return (
      <div>
        <div className="poem-script">
          <ReactQuill
            className="lyrics-text"
            value={recording.poem.lyrics}
            modules={{ toolbar: false }}
            readOnly={true}
          />
        </div>
      </div>
    );
  }
}

export default PoemDetail;
