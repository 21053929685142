import React, { Component } from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "./App.scss";
import Login from "./Login/Login.js";
// import FAQ from "./FAQ/FAQ.js";
import Home from "./Home/Home.js";
import Profile from "./Profile/Profile.js";
import Poem from "./Poem/Poem.js";
import Upload from "./Upload/Upload.js";
import UploadForm from "./Upload/UploadForm.js";
import RecordForm from "./Upload/RecordForm.js";
import Group from "./Group/Group.js";
import ResetPassword from "./Email/ResetPassword.js";
import VerifyEmail from "./Email/VerifyEmail.js";
import About from "./Sidebar/components/About";
import AboutUs from "./Sidebar/components/AboutUs";
import ContactUs from "./Sidebar/components/ContactUs";
import FAQ from "./Sidebar/components/FAQ";
import UploadImage from "./Upload/UploadImage";
import UploadPoet from "./Upload/UploadPoet";
import Search from "./Search/Search.js";
import Followers from "./Followers/Followers";
import Followings from "./Followings/Followings";
import Playlist from "./Playlist/Playlist";
import Discussion from "./Discussion/Discussion";
import Projects from "./Projects/Projects";
import { useEffect, useState } from "react";
import axios from "axios";

import logo from "./static/images/logo-white.png";

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import Notifications from "./Notifications/Notifications";
import {
  faBell,
  faUpload,
  faSearch,
  faUser,
  faCog,
  faList,
  faCaretRight,
  faCaretDown,
  faCloudUploadAlt,
  faPlayCircle,
  faHeart,
  faFireAlt,
  faComments,
  faHome,
  faBars,
  faEnvelope,
  faLock,
  faUserGraduate,
  faCompactDisc,
  faMusic,
  faCaretSquareLeft,
  faCaretSquareRight,
  faPlus,
  faCalendarAlt,
  faPenNib,
  faScroll
} from "@fortawesome/free-solid-svg-icons";
import {
  faHeart as farHeart,
  faCalendarAlt as farCalendarAlt
} from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import ReactJkMusicPlayer from "react-jinke-music-player";
import "./reactJkMusicPlayerStyles.css";
import ProjectView from "./Projects/ProjectView";
import Project from "./Projects/Project";
//import "react-jinke-music-player/assets/index.css";
// import ContactUs from "./Headers/components/SidebarComponents/ContactUs";

library.add(
  faBell,
  faUpload,
  faSearch,
  faUser,
  faCog,
  faList,
  faCaretRight,
  faCaretDown,
  faCloudUploadAlt,
  faPlayCircle,
  faHeart,
  farHeart,
  faFireAlt,
  faComments,
  faHome,
  faBars,
  faEnvelope,
  faLock,
  faUserGraduate,
  faCompactDisc,
  faMusic,
  faCaretSquareLeft,
  faCaretSquareRight,
  faPlus,
  faCalendarAlt,
  farCalendarAlt,
  faPenNib,
  faScroll
);

let globalActiveUser = null;

export const updateActiveUser = newValue => {
  globalActiveUser = newValue;
};

export const getActiveUser = () => {
  return globalActiveUser;
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      activeUser: null,
      apiHost: "https://api.recitedverse.com/api/",
      //apiHost: "http://localhost:8000/api/",
      playlist: null,
      playlistIsPlaying: false,
      playIndex: 0,
      playingTrack: "",
      audioLists: [],
      audioInstance: null,
      sideMenuOpen: false
    };
  }

  initializeApp = async () => {
    await this.updateAuthStatus();
    this.updateActiveUser();
    this.getNotifications();
  };

  getNotifications = () => {
    if (this.state.activeUser === null) {
      return null;
    }

    this.setState({ loading: true });

    // get notifications
    const notificationEndpoint = this.state.apiHost + "notifications/";
    let activeUser = this.state.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    axios
      .get(notificationEndpoint, {
        headers: headerData
      })
      .then(response => {
        const data = response.data;
        if (data.error) {
          console.error(data.error);
        } else {
          this.state.activeUser.notification_count = data.length;
          this.setState({
            activeUser: this.state.activeUser
          });
        }
      })
      .catch(error => {
        console.log(error);
        toast.error("Error fetching notifications");
      });
  };

  updateAuthStatus = () => {
    return new Promise((resolve, reject) => {
      const isAuthenticated = localStorage.getItem("isAuthenticated");
      if (
        isAuthenticated !== undefined &&
        localStorage.getItem("activeUser") !== "undefined"
      ) {
        this.setState(
          {
            isAuthenticated: isAuthenticated,
            activeUser: JSON.parse(localStorage.getItem("activeUser"))
          },
          () => {
            updateActiveUser(JSON.parse(localStorage.getItem("activeUser")));
            resolve(); // resolve the promise when the state has been updated
          }
        );
      } else {
        resolve(); // resolve the promise if the state doesn't need to be updated
      }
    });
  };

  componentDidMount = async () => {
    await this.initializeApp();
    toast.configure({
      autoClose: 3000,
      draggable: true,
      hideProgressBar: true,
      pauseOnHover: false
    });
  };

  updateActiveUser() {
    if (this.state.activeUser === null) {
      return null;
    }

    // get notifications
    const url =
      this.state.apiHost + "users/" + this.state.activeUser.user_uuid + "/";
    let activeUser = this.state.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    axios
      .get(url, {
        headers: headerData
      })
      .then(response => {
        const data = response.data;
        if (data.error) {
          console.error(data.error);
        } else {
          const updatedActiveUser = { ...this.state.activeUser };

          // get all keys from activeUser and update them
          Object.keys(this.state.activeUser).forEach(key => {
            if (data[key] !== undefined) {
              console.log(key, data[key]);
              updatedActiveUser[key] = data[key];
            }
          });

          console.log(updatedActiveUser);

          this.setState({ activeUser: updatedActiveUser }, () => {
            localStorage.setItem(
              "activeUser",
              JSON.stringify(this.state.activeUser)
            );
            updateActiveUser(this.state.activeUser);
          });
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          this.setAuthStatus(false, null);
        }
        console.log(error);
        toast.error("Error fetching user data");
      });
  }

  setAuthStatus = (isAuthenticated, activeUser) => {
    if (isAuthenticated) {
      localStorage.setItem("isAuthenticated", isAuthenticated);
      localStorage.setItem("activeUser", JSON.stringify(activeUser));
    }

    this.setState({
      isAuthenticated: isAuthenticated,
      activeUser: activeUser
    });
    updateActiveUser(activeUser);
  };

  cleanHTML = text => {
    // replace every ending tag with a new line
    text = text.replace(/<\/p>/g, "\n");
    return text.replace(/<\/?[^>]+(>|$)/g, "");
  };

  timeStampToSeconds = timeStamp => {
    const time = timeStamp.split(":");
    return parseInt(time[0]) * 120 + parseInt(time[1]) * 60 + parseInt(time[2]);
  };

  convertLyricsToLrc(lyrics, duration) {
    lyrics = this.cleanHTML(lyrics);
    duration = this.timeStampToSeconds(duration);
    duration += 10;
    const lines = lyrics.split("\n");
    const lineDuration = duration / lines.length;
    let currentTime = 0;
    let lrcLyrics = "";

    for (const line of lines) {
      const minutes = Math.floor(currentTime / 60);
      const seconds = Math.floor(currentTime % 60);
      lrcLyrics += `[${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}.00]${line}\n`;
      currentTime += lineDuration;
    }

    return lrcLyrics;
  }

  accessPlayer = song => {
    const newList = [...this.state.audioLists];
    const songToAdd = {
      name: song.poem.name,
      singer: song.poem.poet,
      lyric: this.convertLyricsToLrc(song.poem.lyrics, song.duration),
      cover: song.cover ? song.cover : logo,
      musicSrc: song.record
    };
    newList.unshift(songToAdd);
    //newList.push(songToAdd);
    this.setState({ audioLists: newList }, () => {
      console.log(this.state.audioLists);
    });
  };

  accessPlayerList = list => {
    let newList = list.map(item => {
      let obj = {
        name: item.poem.name,
        singer: item.poem.poet,
        lyric: item.poem.lyrics,
        cover: item.cover ? item.cover : logo,
        musicSrc: item.record
      };
      return obj;
    });

    this.setState({ audioLists: newList });
    // toast.success("Playlist added to player");
  };

  addToQueue = song => {
    const newList = [...this.state.audioLists];
    const songToAdd = {
      name: song.poem.name,
      singer: song.poem.poet,
      lyric: song.poem.lyrics,
      cover: song.cover ? song.cover : logo,
      musicSrc: song.record
    };
    newList.push(songToAdd);
    this.setState({ audioLists: newList });
    toast.info("Recording added to queue");
  };

  clearAudioList = () => {
    this.audioInstance.clear();
  };

  componentDidUpdate() {
    if (localStorage.getItem("activeUser") === "undefined") {
      // window.location to home page
      window.location = "/";
      console.log("user is undefined");
    }
  }

  toggleSideMenu = () => {
    this.setState({ sideMenuOpen: !this.state.sideMenuOpen });
  };

  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route
              path="/"
              exact
              render={props =>
                this.state.isAuthenticated ? (
                  <Home
                    {...props}
                    {...this.state}
                    setAuthStatus={this.setAuthStatus}
                    accessPlayer={this.accessPlayer}
                    accessPlayerList={this.accessPlayerList}
                    toggleSideMenu={this.toggleSideMenu}
                  />
                ) : (
                  <Login
                    {...props}
                    {...this.state}
                    setAuthStatus={this.setAuthStatus}
                    toggleSideMenu={this.toggleSideMenu}
                  />
                )
              }
            />
            <Route
              path="/reset_password/:email/:code/"
              render={props => (
                <ResetPassword
                  {...props}
                  {...this.state}
                  isAuthenticated={this.state.isAuthenticated}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            />

            <Route
              path="/verify_email/:email/:code/"
              render={props => (
                <VerifyEmail
                  {...props}
                  {...this.state}
                  isAuthenticated={this.state.isAuthenticated}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            />
            <Route
              path="/login/"
              render={props =>
                this.state.isAuthenticated ? (
                  <Redirect to="/" />
                ) : (
                  <Login
                    {...props}
                    {...this.state}
                    setAuthStatus={this.setAuthStatus}
                    toggleSideMenu={this.toggleSideMenu}
                  />
                )
              }
            />
            <Route
              path="/search/"
              render={props => (
                <Search
                  {...props}
                  {...this.state}
                  isAuthenticated={this.state.isAuthenticated}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            />
            <Route
              path="/user/:id/"
              render={props => (
                <Profile
                  {...props}
                  {...this.state}
                  key={props.match.params.id}
                  setAuthStatus={this.setAuthStatus}
                  accessPlayerList={this.accessPlayerList}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            />
            <Route
              path="/user/:id/edit/"
              render={props => (
                <Profile
                  {...props}
                  {...this.state}
                  setAuthStatus={this.setAuthStatus}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            />
            <Route
              path="/poem/:id/"
              render={props => (
                <Poem
                  {...props}
                  {...this.state}
                  setAuthStatus={this.setAuthStatus}
                  accessPlayer={this.accessPlayer}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            />
            <Route
              path="/upload"
              render={props => (
                <Upload
                  {...props}
                  {...this.state}
                  setAuthStatus={this.setAuthStatus}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            />
            <Route
              path="/uploadrecording"
              render={props => (
                <UploadForm
                  {...props}
                  {...this.state}
                  setAuthStatus={this.setAuthStatus}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            />
            <Route
              path="/recordnew"
              render={props => (
                <RecordForm
                  {...props}
                  {...this.state}
                  setAuthStatus={this.setAuthStatus}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            />
            <Route
              path="/notifications"
              render={props => (
                <Notifications
                  {...props}
                  {...this.state}
                  updateNotificationCount={this.updateNotificationCount}
                  setAuthStatus={this.setAuthStatus}
                  accessPlayer={this.accessPlayer}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            />
            <Route
              path="/recitations/:id/"
              render={props => (
                <Poem
                  {...props}
                  {...this.state}
                  setAuthStatus={this.setAuthStatus}
                  accessPlayer={this.accessPlayer}
                  toggleSideMenu={this.toggleSideMenu}
                  addToQueue={this.addToQueue}
                />
              )}
            />
            <Route
              path="/groups/:id/"
              render={props => (
                <Group
                  {...props}
                  {...this.state}
                  setAuthStatus={this.setAuthStatus}
                  accessPlayer={this.accessPlayer}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            />
            <Route
              path="/followers/:userId/"
              render={props => (
                <Followers
                  {...props}
                  {...this.state}
                  setAuthStatus={this.setAuthStatus}
                  accessPlayer={this.accessPlayer}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            />
            <Route
              path="/followings/:userId/"
              render={props => (
                <Followings
                  {...props}
                  {...this.state}
                  setAuthStatus={this.setAuthStatus}
                  accessPlayer={this.accessPlayer}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            />
            <Route
              path="/playlists/:id/"
              render={props => (
                <Playlist
                  {...props}
                  {...this.state}
                  setAuthStatus={this.setAuthStatus}
                  accessPlayer={this.accessPlayer}
                  accessPlayerList={this.accessPlayerList}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            />
            <Route
              path="/discussion/:id/"
              render={props => (
                <Discussion
                  {...props}
                  {...this.state}
                  key={props.match.params.id}
                  setAuthStatus={this.setAuthStatus}
                  accessPlayerList={this.accessPlayerList}
                  toggleSideMenu={this.toggleSideMenu}
                  accessPlayer={this.accessPlayer}
                />
              )}
            />
            <Route
              path="/contactus"
              render={props => (
                <ContactUs
                  {...props}
                  {...this.state}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            />
            <Route
              path="/about"
              render={props => (
                <About
                  {...props}
                  {...this.state}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            />
            <Route
              path="/aboutus"
              render={props => (
                <AboutUs
                  {...props}
                  {...this.state}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            />
            <Route
              path="/faq"
              render={props => (
                <FAQ
                  {...props}
                  {...this.state}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            />
            <Route
              path="/projects"
              render={props => (
                <Projects
                  {...props}
                  {...this.state}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            />
            <Route
              path="/project/:id/"
              render={props => (
                <Project
                  {...props}
                  {...this.state}
                  setAuthStatus={this.setAuthStatus}
                  accessPlayer={this.accessPlayer}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            />
            <Route
              path="/uploadimage"
              render={props => (
                <UploadImage
                  {...props}
                  {...this.state}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            ></Route>
            <Route
              path="/uploadpoet"
              render={props => (
                <UploadPoet
                  {...props}
                  {...this.state}
                  toggleSideMenu={this.toggleSideMenu}
                />
              )}
            />
            <Route component={FAQ} />
          </Switch>
          <div>
            {this.state.isAuthenticated ? (
              <ReactJkMusicPlayer
                audioLists={this.state.audioLists}
                className="music-player"
                mode="full"
                theme="light"
                toggleMode={true}
                showLyric={true}
                showMiniModeCover={true}
                autoHiddenCover={true}
                showThemeSwitch={false}
                showDownload={false}
                showMediaSession={true}
                autoPlay={true}
                showReload={false}
                showPlayMode={true}
                playIndex={this.state.playIndex}
                clearPriorAudioLists={true}
                quietUpdate={true}
                onAudioError={errMsg => {
                  console.log(errMsg["message"]);
                }}
                onAudioPlayModeChange={playMode => {
                  console.log(playMode);
                }}
                getAudioInstance={instance => {
                  this.setState({ audioInstance: instance });
                }}
                onPlayIndexChange={index => {}}
                onAudioPlayTrackChange={currentPlayId => {}}
                onAudioEnded={currentPlayId => {
                  const newList = this.state.audioLists.slice();
                  const currentPlayIndex = this.state.playIndex;

                  if (newList.length > 0) {
                    newList.splice(currentPlayIndex, 1);
                    this.setState({
                      audioLists: newList,
                      playIndex: currentPlayIndex > 0 ? currentPlayIndex - 1 : 0
                    });
                  }
                }}
                onAudioListsChange={currentPlayId => {}}
              />
            ) : null}
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
