import React, { Component } from "react";
import FeaturedRecordingItem from "./FeaturedRecordingItem.js";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class FeaturedRecordingsCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: this.props.items,
      responsive: {
        0: { items: 1 },
        330: { items: 2 },
        500: { items: 3 },
        660: { items: 4 },
        940: { items: 5 },
        1200: { items: 6 },
        1450: { items: 7 }
      },
      currentIndex: 0,
      buttonsDisabled: false
    };
  }

  thumbItem = (item, i) => (
    <span key={item} onClick={() => this.Carousel.onDotClick(i)}>
      *
    </span>
  );

  render() {
    const items = this.props.items.map((item, i) => (
      <div>
        <FeaturedRecordingItem
          {...this.props}
          accessPlayer={this.props.accessPlayer}
          item={item}
          key={i}
        />
      </div>
    ));

    return (
      <div>
        <div className="content-header">
          <h4 className="section-title text-uppercase">{this.props.title}</h4>
          <div>
            <FontAwesomeIcon
              className="fa-1-5x mr-2 craousel-control"
              icon="caret-square-left"
              onClick={() => this.Carousel.slidePrev()}
            />
            <FontAwesomeIcon
              className="fa-1-5x craousel-control"
              icon="caret-square-right"
              onClick={() => this.Carousel.slideNext()}
            />
          </div>
        </div>
        <AliceCarousel
          items={items}
          duration={400}
          autoPlay={false}
          buttonsDisabled={true}
          // dotsDisabled={true}
          startIndex={1}
          fadeOutAnimation={false}
          responsive={this.state.responsive}
          ref={el => (this.Carousel = el)}
        />
      </div>
    );
  }
}

export default FeaturedRecordingsCarousel;
