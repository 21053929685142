import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { Button, FormGroup, Input, FormFeedback } from "reactstrap";
import axios from "axios";
import "./reset_password_form.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      dup_password: "",
      first_name_valid: false,
      last_name_valid: false,
      email_valid: false,
      password_valid: false,
      dup_password_valid: false,
      formError: false,
      formErrorText: "",
      authSuccess: false,
      code: "",
      authSuccessText: "User Created. Login to continue"
    };
    this.validateForm = this.validateForm.bind(this);
    this.handlePasswordCheck = this.handlePasswordCheck.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      email: this.props.match.params.email,
      code: this.props.match.params.code
    });
  }

  validateForm() {
    if (this.state.password.length > 0) {
      return true;
    } else {
      this.setState({
        formErrorText: "Fields cannot be empty",
        formError: true
      });
      return false;
    }
  }

  handlePasswordCheck() {
    if (this.state.password !== this.state.dup_password) {
      this.setState({
        formErrorText: "Passwords do not match",
        formError: true
      });
      return false;
    }
    return true;
  }

  handleChange(event) {
    const isValid = event.target.value.length > 0;
    this.setState({
      [event.target.name]: event.target.value,
      [event.target.name + "_valid"]: isValid
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    const postUrl = this.props.apiHost + "reset_password/";
    if (this.validateForm() && this.handlePasswordCheck()) {
      axios
        .post(postUrl, {
          email: this.state.email,
          code: this.state.code,
          password: this.state.password
        })
        .then(() => {
          confirmAlert({
            title: "Success",
            message: "Password has been reset successfully!",
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  window.location.href = "/";
                }
              }
            ]
          });

          //this.handleAuthResponse(response.data);
        })
        .catch(function(error) {
          confirmAlert({
            title: "Error",
            message: "An error occurred. Please try again.",
            buttons: [
              {
                label: "Yes",
                onClick: () => {}
              }
            ]
          });
          console.log(error.response);
        });
    }
  }

  render() {
    const formError = this.state.formError;
    return (
      <div className="reset_password">
        <form onSubmit={this.handleSubmit}>
          <FormFeedback className={formError ? "show" : ""} tooltip>
            {this.state.formErrorText}
          </FormFeedback>
          <FormGroup className="text-left">
            <Input
              className="form-input half-width"
              id="user_password"
              type="password"
              name="password"
              valid={this.state.password_valid}
              value={this.state.password}
              onChange={this.handleChange}
              placeholder="Password"
            />
            <FormFeedback>Password cannot be empty</FormFeedback>
          </FormGroup>
          <FormGroup className="text-left">
            <Input
              className="form-input half-width"
              id="dup_password"
              type="password"
              name="dup_password"
              valid={this.state.dup_password_valid}
              value={this.state.dup_password}
              onChange={this.handleChange}
              placeholder="Confirm Password"
            />
            <FormFeedback>Password cannot be empty</FormFeedback>
          </FormGroup>
          <Button className="signup-button" type="submit">
            Submit
          </Button>
        </form>
      </div>
    );
  }
}

ResetPasswordForm.propTypes = {
  match: PropTypes.object,
  toggle: PropTypes.func,
  apiHost: PropTypes.string
};

export default ResetPasswordForm;
