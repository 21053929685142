import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import RecitationList from "../../components/RecitationList";
import logo from "./../../static/images/logo-white.png";

class PopularPlaylistItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playlistItems: [],
      user: {}
    };
  }

  componentDidMount() {
    this.getPlaylistRecordings();
    //this.getPlaylistUser();
  }

  /*
    fetch the author of the current playlist
  */
  getPlaylistUser = () => {
    const userId = this.props.item.user;
    const url = this.props.apiHost + "user_profile/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    axios
      .get(url, {
        headers: headerData
      })
      .then(response => {
        let userToUpdate = response.data.filter(item => item.id === userId)[0];
        this.setState({ user: userToUpdate });
      })
      .catch(error => {
        toast.error("There was an error loading your playlist");
      });
  };

  getPlaylistRecordings = () => {
    // get playlist recordings here...
    const playlitsUuid = this.props.item.playlist_uuid;

    const url =
      this.props.apiHost + "playlists/" + playlitsUuid + "/recitations/";

    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    axios
      .get(url, { headers: headerData })
      .then(response => {
        if (response.status === 200) {
          let dataToUpdate = response.data.map(item => {
            return item.recitation;
          });
          this.setState({ playlistItems: dataToUpdate });
        }
      })
      .catch(() => {
        toast.error("There was an error loading your playlist");
      });
  };

  fillPlaylistImages = item => {
    const images = [];

    for (let i = 0; i < 4; i++) {
      if (i >= item.length || item[i] === null) {
        images.push(<div key={i} className="playlist-image"></div>);
      }
    }

    return images;
  };

  playPlaylist = () => {
    this.props.accessPlayer(this.state.playlistItems);
  };

  render() {
    const item = this.props.item;
    const playlistItems = this.state.playlistItems;

    const handleImageError = e => {
      e.target.src = logo;
    };

    return (
      <div>
        <Link to={"/playlists/" + item.playlist_uuid}>
          <div className="playlist-images">
            {playlistItems.map((item, index) => {
              if (item && item !== null) {
                const image = item.cover;

                return (
                  <div key={index} className="playlist-image">
                    <img src={image} alt={image} onError={handleImageError} />
                  </div>
                );
              }
            })}
            {this.fillPlaylistImages(playlistItems)}
          </div>
        </Link>
        <h5 className="text-uppercase">{item.name}</h5>
        <p className="font-weight-light">
          <Link
            to={"/user/" + this.state.user?.user_uuid}
            className="featured-poet"
          >
            {this.state.user?.first_name} {this.state.user?.last_name}
          </Link>
        </p>
      </div>
    );
  }
}

PopularPlaylistItem.propTypes = {
  accessPlayer: PropTypes.func,
  item: PropTypes.object
};

export default PopularPlaylistItem;
