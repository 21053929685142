import React, { Component } from "react";
import Header from "./../Headers/Header.js";
import PlaylistMainContent from "./components/PlaylistMainContent";
import "./Playlist.scss";

class Playlist extends Component {
  constructor(props) {
    super(props);

    // this.editable =
    //   this.props.activeUser &&
    //   this.props.match.params.id === this.props.activeUser.user_uuid;
    //this.editable = this.props.activeUser && this.props.playlist.user === this.props.user.id
  }

  render() {
    return (
      <div className="App">
        <Header {...this.props} />
        <div className="page-wrapper playlist-page">
          <PlaylistMainContent
            {...this.props}
            {...this.state}
            accessPlayer={this.props.accessPlayer}
            editable={this.editable}
          />
        </div>
      </div>
    );
  }
}

export default Playlist;
