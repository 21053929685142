import React, { Component } from "react";
import GroupItem from "./GroupItem";
import ModalGroupEdit from "./Modals/ModalGroupEdit";
import ModalGroupDelete from "./Modals/ModalGroupDelete";

export default class GroupList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalEdit: false,
      modalDelete: false,
      selectedGroup: {}
    };
  }

  // toggle the edit modal
  toggleModalEdit = group => {
    this.setState(prevState => ({
      modalEdit: !prevState.modalEdit,
      selectedGroup: group
    }));
  };

  // toggle the delete modal
  toggleModalDelete = group => {
    this.setState(prevState => ({
      modalDelete: !prevState.modalDelete,
      selectedGroup: group
    }));
  };

  addGroup = () => {
    this.props.addGroup();
  };

  render() {
    const groups = this.props.items;
    const groupId = this.state.selectedGroup?.id;
    return (
      <div className="tab-container">
        {groups && groups.length > 0 ? (
          <div>
            <ul className="content-list group-list">
              {groups.map((group, i) => (
                <GroupItem
                  {...this.props}
                  key={group.id}
                  item={group}
                  toggleModalEdit={this.toggleModalEdit}
                  toggleModalDelete={this.toggleModalDelete}
                  editable={
                    group.group_creator.user_uuid ===
                      this.props.activeUser.user_uuid ||
                    this.props.editable ||
                    this.props.admin
                  }
                />
              ))}
              {this.props.editable ? (
                <li
                  className="group-list-item add-group"
                  onClick={this.addGroup}
                ></li>
              ) : (
                <span></span>
              )}
            </ul>
            {/* Render modal of editing a group */}
            <ModalGroupEdit
              {...this.props}
              key={`edit-${groupId}`}
              selectedGroup={this.state.selectedGroup}
              modalEdit={this.state.modalEdit}
              toggleModalEdit={this.toggleModalEdit}
            />
            {/* Render modal of deleting a group */}
            <ModalGroupDelete
              {...this.props}
              key={`delete-${groupId}`}
              selectedGroup={this.state.selectedGroup}
              modalDelete={this.state.modalDelete}
              toggleModalDelete={this.toggleModalDelete}
            />
          </div>
        ) : (
          <div className="wrap center">
            <h2 className="page-title">No Groups to show</h2>
          </div>
        )}
      </div>
    );
  }
}
