import React, { Component } from "react";
import Header from "./../Headers/Header.js";
import recordImage from "../static/images/record.png";
import axios from "axios";
import profileImage from "../static/images/profile.png";
import { Link } from "react-router-dom";
import { FilePicker } from "react-file-picker";
import { Input } from "reactstrap";
import { toast } from "react-toastify";
import "./Upload.scss";

export default class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      blob: ""
    };
    this.handleUpload = this.handleUpload.bind(this);
    this.fileToBase64 = this.fileToBase64.bind(this);
  }

  fileToBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      cb(reader.result);
    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
    };
  }

  handleUpload(event) {
    let data = {};
    data["audioData"] = event.target.files[0];
    if (
      data?.audioData?.type === "audio/mp3" ||
      data?.audioData?.type === "audio/mpeg" ||
      data?.audioData?.type === "audio/x-m4a"
    ) {
      // this.setState({blob: data})
      this.props.history.push({
        state: data,
        currentPoem: this.props.location.currentPoem,
        pathname: "/uploadrecording"
      });
    } else {
      toast.error("File type is unsupported. Please try again.");
    }
  }

  render() {
    return (
      <div className="App upload-page">
        <Header {...this.props} />
        <div className="record-container page-wrapper">
          <div className="wrap center">
            <img className="recitation-icon" src={recordImage} />
            <h1 className="page-title-sm">SHARE YOUR RECORDING</h1>
            <div className="upload-box">
              {this.props.activeUser.avatar === "" ? (
                <img
                  style={{ maxHeight: "200px", width: "auto" }}
                  src={profileImage}
                  alt="user-profile-img"
                  className="p-0 upload-img"
                />
              ) : (
                <img
                  style={{ maxHeight: "200px", width: "auto" }}
                  src={this.props.activeUser.avatar}
                  alt="user-profile-img"
                  className="p-0 upload-img"
                />
              )}
              <p className="user-name">
                {this.props.activeUser.first_name}{" "}
                {this.props.activeUser.last_name}
              </p>
              <div className="upload-button-group">
                {this.state.error}
                {/* <FilePicker
                onChange={this.handleUpload}
                onError={errMsg => this.setState({ error: errMsg })}
              >
                <button className="upload-button">Upload </button>
              </FilePicker> */}
                <div className="upload-button">
                  <Input
                    onChange={this.handleUpload}
                    type="file"
                    name="profilePicture"
                    id="profilePicture"
                    className="hide-default-upload"
                  />
                  <label className="custom-input" for="profilePicture">
                    <div className="button primary upload-div"> Upload</div>
                  </label>
                </div>
                <div className="upload-button">
                  <Link
                    to={{
                      pathname: "/recordnew",
                      currentPoem: this.props.location.currentPoem
                    }}
                    className="button primary"
                  >
                    Record
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
