import React, { Component } from "react";
import Header from "../../Headers/Header.js";

export default class ContactUs extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="App">
        <Header {...this.props} />
        <div className="page-wrapper">
          <div className="wrap center">
            <h1 className="page-title">Contact Us</h1>
            <p>
              Running into an error? Have feedback to help us develop Recited
              Verse? Contact us{" "}
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSeemiSaflaRp1LXXpdWUjJqzagy_eS2EVmsX1Zwxptu_FkKsw/viewform">
                here
              </a>
              . For all other inquiries, contact us here:{" "}
              <a href="mailto:hello@recitedverse.com">hello@recitedverse.com</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
