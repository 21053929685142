import React, { Component } from "react";
import MoreReadingItem from "./MoreReadingItem";

class MoreReading extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const recordings = this.props.items;
    const currentRecording = this.props.currentRecording;

    return (
      <div className="poem-more-readings tab-container ">
        <h4 className="section-title text-uppercase">More Readings</h4>
        <ul className="content-list">
          {recordings.map((item, i) => {
            // only show the ones except the current one
            if (item.id !== currentRecording.id) {
              return (
                <MoreReadingItem
                  {...this.props}
                  key={i}
                  item={item}
                  playRecording={this.props.playRecording}
                />
              );
            }
          })}
        </ul>
      </div>
    );
  }
}

export default MoreReading;
