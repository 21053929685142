import React, { Component } from "react";
import RecommendedPoemItem from "./RecommendedPoemItem.js";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Img1 from "../../static/images/rv-default-01.jpg";
import Img2 from "../../static/images/rv-default-02.jpg";
import Img3 from "../../static/images/rv-default-03.jpg";
import Img4 from "../../static/images/rv-default-04.jpg";
import Img5 from "../../static/images/rv-default-05.jpg";
import Img6 from "../../static/images/rv-default-06.jpg";
import Img7 from "../../static/images/rv-default-07.jpg";
import Img8 from "../../static/images/rv-default-08.jpg";
import Img9 from "../../static/images/rv-default-09.jpg";
import Img10 from "../../static/images/rv-default-10.jpg";
import Img11 from "../../static/images/rv-default-11.jpg";
import Img12 from "../../static/images/rv-default-12.jpg";
import Img13 from "../../static/images/rv-default-13.jpg";
import Img14 from "../../static/images/rv-default-14.jpg";
import Img15 from "../../static/images/rv-default-15.jpg";
import Img16 from "../../static/images/rv-default-16.jpg";
import Img17 from "../../static/images/rv-default-17.jpg";
import Img18 from "../../static/images/rv-default-18.jpg";
import Img19 from "../../static/images/rv-default-19.jpg";
import Img20 from "../../static/images/rv-default-20.jpg";

class RecommendedPoemsCarousel extends Component {
  constructor(props) {
    super(props);

    this.imageList = [
      Img1,
      Img2,
      Img3,
      Img4,
      Img5,
      Img6,
      Img7,
      Img8,
      Img9,
      Img10,
      Img11,
      Img12,
      Img13,
      Img14,
      Img15,
      Img16,
      Img17,
      Img18,
      Img19,
      Img20
    ];
  }

  state = {
    items: this.props.items,
    responsive: {
      0: { items: 1 },
      330: { items: 1 },
      500: { items: 2 },
      660: { items: 2 },
      940: { items: 3 },
      1200: { items: 3 },
      1450: { items: 3 }
    },
    currentIndex: 0,
    buttonsDisabled: false
  };

  thumbItem = (item, i) => (
    <span key={item} onClick={() => this.Carousel.onDotClick(i)}>
      *
    </span>
  );

  getDefaultImage() {
    let imageIndex = Math.floor(Math.random() * 20);
    return this.imageList[imageIndex];
  }

  render() {
    const items = this.props.items.map((item, i) => (
      <RecommendedPoemItem item={item} image={this.getDefaultImage()} />
    ));

    return (
      <Row className="p-0 m-0 px-2">
        <Col md="12" className="p-0 mx-0 my-3">
          <Row className="justify-content-between px-3 mb-2">
            <h4 className="text-left d-inline mb-4 text-uppercase">
              {this.props.title}
            </h4>
            <div className="d-inline">
              <FontAwesomeIcon
                className="fa-1-5x mr-2 craousel-control"
                icon="caret-square-left"
                onClick={() => this.Carousel.slidePrev()}
              />
              <FontAwesomeIcon
                className="fa-1-5x craousel-control"
                icon="caret-square-right"
                onClick={() => this.Carousel.slideNext()}
              />
            </div>
          </Row>
          <AliceCarousel
            items={items}
            duration={400}
            autoPlay={false}
            buttonsDisabled={true}
            // dotsDisabled={true}
            startIndex={1}
            fadeOutAnimation={true}
            responsive={this.state.responsive}
            ref={el => (this.Carousel = el)}
          />
        </Col>
      </Row>
    );
  }
}

export default RecommendedPoemsCarousel;
