import React, { Component } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "./../../static/images/logo-white.png";
import { PropTypes } from "prop-types";

class LikesPlaylistsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playlistItems: [],
      dropdownOpen: false
    };
  }

  componentDidMount() {
    this.getPlaylistRecordings();
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  // fetch the playlist item images
  getPlaylistRecordings = () => {
    // get playlist recordings here...
    const playlitsUuid = this.props.item.playlist.playlist_uuid;

    const playlistEndpoint =
      this.props.apiHost + "playlists/" + playlitsUuid + "/recitations/";

    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    axios
      .get(playlistEndpoint, {
        header: headerData
      })
      .then(response => {
        if (response.status === 200) {
          let dataToUpdate = response.data.map(item => {
            return item.recitation;
          });
          this.setState({ playlistItems: dataToUpdate });
        }
      })
      .catch(() => {
        toast.error("There was an error loading your playlist");
      });
  };

  toggleModalEdit = playlist => {
    this.props.toggleModalEdit(playlist);
  };

  toggleModalDelete = playlist => {
    this.props.toggleModalDelete(playlist);
  };

  render() {
    const handleImageError = e => {
      e.target.src = logo;
    };

    const playlist = this.props.item.playlist;
    const editable = this.props.editable;
    const playlistItems = this.state.playlistItems;

    return (
      <li className="content-list-item playlist-list-item">
        <div className="playlist-images">
          <Link
            to={"/playlists/" + playlist.playlist_uuid}
            className="featured-poet"
          >
            {playlistItems.map((item, index) => {
              /* Only render not null items */
              if (item && item !== null) {
                const image = item.cover ? item.cover : logo;

                return (
                  <img
                    key={item.id}
                    src={image}
                    alt={image}
                    onError={handleImageError}
                  />
                );
              }
            })}
          </Link>
        </div>
        <div className="item-header">
          <div className="item-info">
            <h5 className="text-uppercase">
              <Link
                to={"/playlists/" + playlist.playlist_uuid}
                className="featured-poet"
              >
                {playlist.name}
              </Link>
            </h5>
            <p className="font-weight-light p-0 m-0">
              <Link
                to={"/user/" + this.state.user?.user_uuid}
                className="featured-poet"
              >
                {this.state.user?.first_name} {this.state.user?.last_name}
              </Link>
            </p>
          </div>
        </div>
      </li>
    );
  }
}

export default LikesPlaylistsList;
