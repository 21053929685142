import React, { Component } from "react";
import Header from "../Headers/Header.js";
import PdfButton from "./PdfButton";
import { Row, Col } from "reactstrap";
import Axios from "axios";
import ProjectView from "./ProjectView.js";
import { Link } from "react-router-dom";

export default class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      loading: true
    };
  }

  getProjects = () => {
    const url = this.props.apiHost + "projects/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    Axios.get(url, { headers: headerData })
      .then(response => {
        this.setState({
          projects: response.data,
          loading: false
        });
        console.log(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.getProjects();
  }

  render() {
    return (
      <div className="App">
        <Header {...this.props} />
        <div className="interior-page page-wrapper">
          <div className="wrap center">
            <p className="page-title">Recited Verse Virtual Residency</p>
            <p className="page-title-sm">Inaugural Cohort, 2022</p>
            <br></br>

            <Row>
              {this.state.projects.map(project => (
                <Col md="4">
                  <Link to={"/project/" + project.project_uuid}>
                    <PdfButton
                      project={project}
                      name={project?.title}
                      author={project?.author + ", " + project?.university}
                    />
                  </Link>
                </Col>
              ))}
              <Col md="4">
                <PdfButton
                  project={this.state.projects[0]}
                  url="https://recitedverse-dev.s3.us-west-1.amazonaws.com/virtual_residency/Black+Herstories+in+Poetic+Form.pdf"
                  name="Black Herstories in Poetic Form"
                  author="Shamethia Webb, Texas Woman's University"
                />
              </Col>
              <Col md="4">
                <PdfButton
                  url="https://recitedverse-dev.s3.us-west-1.amazonaws.com/virtual_residency/Four+Quartets+--+T.S.+Eliot+and+the+Wheel+of+Time.pdf"
                  name="Four Quartets -- T.S. Eliot and the Wheel of Time"
                  author="Henry Coburn, University of California, Santa Barbara"
                />
              </Col>
              <Col md="4">
                <PdfButton
                  url="https://recitedverse-dev.s3.us-west-1.amazonaws.com/virtual_residency/Identity+in+Scottish+and+Irish+Poetry.pdf"
                  name="Identity in Scottish and Irish Poetry"
                  author="K.C. Crawford, University of San Francisco"
                />
              </Col>
              <Col md="4">
                <PdfButton
                  url="https://recitedverse-dev.s3.us-west-1.amazonaws.com/virtual_residency/Recited+Verse+and+Sonic+Desire-+Helping+Humans+Be+More+Humane.pdf"
                  name="Recited Verse and Sonic Desire - Helping Humans Be More Humane"
                  author="Jason Treviño, University of California, Berkeley"
                />
              </Col>
              <Col md="4">
                <PdfButton
                  url="https://recitedverse-dev.s3.us-west-1.amazonaws.com/virtual_residency/Recited+Verses.pdf"
                  name="Recited Verses"
                  author="Sabrina Yates, Stanford University"
                />
              </Col>
              <Col md="4">
                <PdfButton
                  url="https://recitedverse-dev.s3.us-west-1.amazonaws.com/virtual_residency/Relax!+A+Poetics+of+ASMR+.pdf"
                  name="Relax! A Poetics of ASMR"
                  author="Zack Brown, SUNY Buffalo"
                />
              </Col>
              <Col md="4">
                <PdfButton
                  url="https://recitedverse-dev.s3.us-west-1.amazonaws.com/virtual_residency/The+unexpected+function+of+ambiance+in+di+Prima%E2%80%99s+Revolutionary+Letters.pdf"
                  name="The unexpected function of ambiance in di Prima’s Revolutionary Letters"
                  author="Jesslyn Whittell, University of California, Los Angeles"
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
