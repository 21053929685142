import React, { Component } from "react";
import Img1 from "../../static/images/rv-default-01.jpg";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";

class MemberItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const member = this.props.member;
    const handleImageError = e => {
      e.target.src = Img1;
    };

    return (
      <li className="content-list-item member-list-item">
        <div className="item-image">
          <img
            src={member.avatar}
            alt={member.first_name + " " + member.last_name}
            onError={handleImageError}
          ></img>
        </div>
        <div className="item-info">
          <Link to={`/user/${member.user_uuid}/`}>
            <h6>
              {member.first_name} {member.last_name}
            </h6>
            {/* <p>{group.members.length} members</p> */}
          </Link>
        </div>
      </li>
    );
  }
}

export default MemberItem;
