import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  FormText
} from "reactstrap";

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export default class ModalGroupEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userToAddInModal: "", // User email field in modal. If you know a cleaner way i'm all ears - @marko ??
      name: props.selectedGroup?.name,
      group_info: props.selectedGroup?.group_info,
      main_photo: props.selectedGroup?.main_photo,
      main_photo_uploaded: null,
      cover_photo: props.selectedGroup?.cover_photo,
      cover_photo_uploaded: null,
      group_members: [],
      private: props.selectedGroup?.private
    };
  }

  componentDidMount() {
    const selectedGroup = this.props.selectedGroup;

    if (selectedGroup && Object.keys(selectedGroup).length > 0) {
      this.getGroupInfo(selectedGroup);
    }
  }

  handleGroupNameChange = event => {
    const name = event.target.value;

    this.setState({ name: name });
  };

  handleGroupInfoChange = event => {
    const group_info = event.target.value;

    this.setState({ group_info: group_info });
  };

  handleGroupMainPhotoChange = event => {
    const main_photo = event.target.files[0];

    this.setState({
      main_photo: main_photo,
      main_photo_uploaded: URL.createObjectURL(main_photo)
    });
  };

  handleGroupCoverPhotoChange = event => {
    const cover_photo = event.target.files[0];

    this.setState({
      cover_photo: cover_photo,
      cover_photo_uploaded: URL.createObjectURL(cover_photo)
    });
  };

  submitGroupChange = groupData => {
    if (!this.state.name || this.state.name === "") {
      toast.error("Group name is required");
      return;
    }

    // update header
    const groupURL = this.props.apiHost + "groups/" + groupData.group_uuid;

    var formData = new FormData();
    // Prevent null submission
    const name = this.state.name;
    const group_info = this.state.group_info;
    const main_photo = this.state.main_photo;
    const cover_photo = this.state.cover_photo;

    formData.append("name", name);
    formData.append("group_info", group_info);
    formData.append("main_photo", main_photo);
    formData.append("cover_photo", cover_photo);
    formData.append("private", this.state.private);

    groupData.members.forEach(member => {
      formData.set("members", member.id);
    });

    const header = {
      Authorization: "Token " + this.props.activeUser.token,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json"
    };

    axios
      .put(groupURL, formData, {
        headers: header
      })
      .then(res => {
        if (res.status === 200) {
          toast.success("Updated!");
          setTimeout(() => {
            window.location.reload(); // @TODO this will wipe playlist
          }, 1000);
        }
      })
      .catch(err => {
        console.error(err.response);
        toast.error("Could not update group information. Please try again.");
      });
  };

  getGroupInfo = groupData => {
    const groupURL = this.props.apiHost + "groups/" + groupData.group_uuid;
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    axios
      .get(groupURL, {
        headers: headerData
      })
      .then(res => {
        if (res.status === 200) {
          this.setState({
            group_members: res.data.members
          });
        }
      })
      .catch(err => {
        toast.error(`Could get group information. Please try again.`);
      });
  };

  // Adds user to group from the modal.
  addUserToGroup = groupData => {
    const groupURL =
      this.props.apiHost + "groups/" + groupData.group_uuid + "/utils/add/";

    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let header = {
      Authorization: "Token " + activeUser.token
    };

    var formData = new FormData();

    formData.set("user_uuid", activeUser.user_uuid);
    formData.set("email", this.state.userToAddInModal);

    axios
      .post(groupURL, formData, {
        headers: header
      })
      .then(res => {
        if (res.status === 200) {
          toast.success(
            `${this.state.userToAddInModal} is now a member of ${groupData.name}`
          );

          this.getGroupInfo(groupData);
          // this.props.getGroups(this.props);
        }
        this.setState({ userToAddInModal: "" });
      })
      .catch(err => {
        console.error(err.response);
        toast.error(
          `Could not add ${this.state.userToAddInModal}. Please try again.`
        );
        this.setState({ userToAddInModal: "" });
      });
  };

  handlePrivateChange = () => {
    const group_private = !this.state.private;
    this.setState({ private: group_private });
  };

  // remove a user from the group
  removeUserFromGroup = (groupData, user) => {
    if (user.user_uuid === groupData.group_creator.user_uuid) {
      toast.error("You cannot remove yourself from the group.");
      return;
    }

    const groupURL =
      this.props.apiHost + "groups/" + groupData.group_uuid + "/utils/delete/";
    const header = {
      Authorization: "Token " + this.props.activeUser.token
    };

    var formData = new FormData();

    formData.set("user_uuid", user.user_uuid);

    axios
      .post(groupURL, formData, {
        headers: header
      })
      .then(res => {
        if (res.status === 200) {
          toast.success(`${user.first_name} was removed.`);
          this.getGroupInfo(groupData);
          // this.props.getGroups(this.props);
        }
      })
      .catch(err => {
        toast.error(`Could not remove ${user.first_name}. Please try again.`);
        this.setState({ userToAddInModal: "" });
      });
  };

  toggleModalEdit = () => {
    this.props.toggleModalEdit();
  };

  render() {
    const groupData = this.props.selectedGroup;
    const groupName = this.state.name;
    const groupInfo = this.state.group_info;
    const mainPhoto = this.state.main_photo;
    const mainPhotoUploaded = this.state.main_photo_uploaded;
    const coverPhoto = this.state.cover_photo;
    const coverPhotoUploaded = this.state.cover_photo_uploaded;

    return (
      <Modal
        backdrop={true}
        fullscreen=""
        size="lg"
        isOpen={this.props.modalEdit}
        toggle={this.toggleModalEdit}
        className="group-manage-modal"
      >
        <ModalHeader toggle={this.toggleModalEdit}>Edit Group</ModalHeader>
        <ModalBody>
          <FormGroup>
            <div>
              <Label for="name">Group Name</Label>
              <Input
                id="name"
                type="text"
                defaultValue={groupName}
                onChange={this.handleGroupNameChange}
              />
            </div>
            <div className="space-sm">
              <Label for="description">Group Description</Label>
              <Input
                id="description"
                name="text"
                type="textarea"
                defaultValue={groupInfo}
                onChange={this.handleGroupInfoChange}
              />
            </div>
          </FormGroup>
          <hr className="divider space-sm" />
          <FormGroup className="space-sm">
            <Label for="main-photo">Profile Picture</Label>
            <Input
              id="main-photo"
              type="file"
              name="main-photo"
              onChange={this.handleGroupMainPhotoChange}
            />
            <FormText color="muted">
              Select a photo for your new group picture
            </FormText>
            <div className="main-photo space-sm">
              <img
                src={mainPhotoUploaded ? mainPhotoUploaded : mainPhoto}
                alt={"group " + groupName + " profile picture"}
              />
            </div>
          </FormGroup>
          <hr className="divider space-sm" />
          <FormGroup className="space-sm">
            <Label for="cover-photo">Cover Photo</Label>
            <Input
              id="cover-photo"
              type="file"
              name="cover-photo"
              onChange={this.handleGroupCoverPhotoChange}
            />
            <FormText color="muted">
              Select a photo for your new group cover photo
            </FormText>
            <div className="space-sm">
              <img
                src={coverPhotoUploaded ? coverPhotoUploaded : coverPhoto}
                alt={"group " + groupName + " cover photo"}
              />
            </div>
          </FormGroup>
          <hr className="divider space-sm" />
          <div className="space-sm">
            <p>Group Members:</p>
            <div className="member-field">
              <Input
                type="email"
                value={this.state.userToAddInModal}
                onChange={val => {
                  this.setState({
                    userToAddInModal: val.target.value
                  });
                }}
                placeholder="User's email address"
              />
              <button
                style={{ marginLeft: "1rem" }}
                className="button small primary"
                onClick={() => this.addUserToGroup(groupData)}
              >
                Invite
              </button>
            </div>
          </div>
          <div className="space-sm">
            {this.state.group_members?.map(item => (
              <p key={item.id}>
                {item.user_uuid === groupData.group_creator.user_uuid ? (
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    {item.first_name + " " + item.last_name + "  "}
                    {/* add a tag to the right */}
                    <span
                      style={{
                        color: "grey",
                        fontStyle: "italic"
                      }}
                    >
                      Admin
                    </span>
                  </span>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <span>{item.first_name + " " + item.last_name + " "}</span>
                    <a style={{ cursor: "pointer" }}>
                      <span
                        onClick={() => {
                          this.removeUserFromGroup(groupData, item);
                        }}
                        style={{ color: "red", fontSize: "1.7em" }}
                      >
                        &times;
                      </span>
                    </a>
                  </div>
                )}
              </p>
            ))}
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={this.state.private}
                  onChange={this.handlePrivateChange}
                />{" "}
                Private Group
              </Label>
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => this.submitGroupChange(groupData)}
          >
            Save
          </Button>
          <Button color="secondary" onClick={this.toggleModalEdit}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
