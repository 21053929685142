import React from "react";
import { Row, Col, ListGroup } from "reactstrap";
import FAQItem from "./FAQItem";

export default class FAQList extends React.Component {
  getFAQList = () => {
    return [
      {
        question: "What is recitedverse",
        answer: "Cras justo odio"
      },
      {
        question: "What is our goal",
        answer: "Dapibus ac facilisis in"
      }
    ];
  };

  renderFAQList = () => {
    const faqList = this.getFAQList();
    const faqListItems = faqList.map((faqItem, i) => (
      <FAQItem key={i} question={faqItem.question} answer={faqItem.answer} />
    ));
    return faqListItems;
  };

  render() {
    return (
      <Row>
        <Col xs="12" sm="10" md="8" lg="8" className="py-5 m-auto">
          <ListGroup>{this.renderFAQList()}</ListGroup>
        </Col>
      </Row>
    );
  }
}
