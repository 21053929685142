import React, { Component } from "react";
import PlaylistsList from "./PlaylistsList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index";
import { PropTypes } from "prop-types";
import ModalPlaylistAdd from "./Modals/ModalPlaylistAdd";

class ProfilePlaylists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalAdd: false
    };
  }

  toggleModalAdd = () => {
    this.setState(prevState => ({ modalAdd: !prevState.modalAdd }));
  };

  render() {
    const playlists = this.props.playlists;

    return (
      <div className="profile-section">
        <ModalPlaylistAdd
          {...this.props}
          modalAdd={this.state.modalAdd}
          toggleModalAdd={this.toggleModalAdd}
        />
        <PlaylistsList
          {...this.props}
          items={playlists}
          addPlaylist={this.toggleModalAdd}
        />
      </div>
    );
  }
}

// // TODO proptypes
// ProfilePlaylists.propTypes = {
//   apiHost: PropTypes.string,
//   activeUser: PropTypes.object,
//   user: PropTypes.object,
// };

export default ProfilePlaylists;
