import React, { Component } from "react";
import Img1 from "../../static/images/rv-default-01.jpg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

class GroupItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  toggleModalEdit(group) {
    this.props.toggleModalEdit(group);
  }

  toggleModalDelete(group) {
    this.props.toggleModalDelete(group);
  }

  render() {
    const group = this.props.item;
    const handleImageError = e => {
      e.target.src = Img1;
    };
    const editable = this.props.editable;

    return (
      <li className="content-list-item group-list-item">
        <div className="item-image">
          <img
            src={group.main_photo}
            alt={group.group_info}
            onError={handleImageError}
          ></img>
        </div>
        <div className="item-info">
          <Link to={`/groups/${group.group_uuid}/`}>
            <h6>{group.name}</h6>
          </Link>
          <p>{group.members.length} members</p>
        </div>
        <div className="item-cta">
          {/* <button class="dropdown-item" type="button">
                  </button> */}
          {editable ? (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
              <DropdownToggle caret className="edit-button">
                <FontAwesomeIcon icon={faEllipsisH} className="mr-2" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => this.toggleModalEdit(group)}>
                  Edit
                </DropdownItem>
                <DropdownItem onClick={() => this.toggleModalDelete(group)}>
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          ) : (
            <span></span>
          )}
        </div>
      </li>
    );
  }
}

export default GroupItem;
