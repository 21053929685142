import React, { Component } from "react";
import { Card, CardImg, CardFooter } from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import { toast } from "react-toastify";
import logo from "./../../static/images/logo-white.png";

class FeaturedRecordingItem extends Component {
  constructor(props) {
    super(props);

    this.handlePlay = this.handlePlay.bind(this);
  }

  handlePlay() {
    this.props.accessPlayer(this.props.item);
  }

  updateNumberOfPLays() {
    let headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };
    const url =
      this.props.apiHost +
      "recitations/" +
      this.props.item?.recitation_uuid +
      "/play/";
    axios
      .post(url, new FormData(), {
        headers: headerData
      })
      .then(response => {
        console.log("number of plays increased");
      })
      .catch(err => {
        toast.error("Error incrementing the number of plays");
      });
  }

  render() {
    const handleImageError = e => {
      e.target.src = logo;
    };

    return (
      <div className="d-inline featured-recordings-item">
        <Card className="border-0 shadow-none bg-none">
          <Link to={`/recitations/${this.props.item?.recitation_uuid}`}>
            <CardImg
              className="featured-recording-img card shadow-none test2"
              src={this.props.item.cover}
              alt="Card image cap"
              onError={handleImageError}
            />
          </Link>
          <CardFooter className="p-1 mt-2" style={{ width: "150px" }}>
            <h5>
              <Link
                to={`/recitations/${this.props.item?.recitation_uuid}`}
                className="featured-head"
              >
                {this.props.item.poem?.name}
              </Link>
            </h5>
            <p className="p-0 m-0 featured-poet">
              <Link
                to={`/recitations/${this.props.item?.recitation_uuid}`}
                className="featured-poet"
              >
                {this.props.item.poem?.poet}
              </Link>
            </p>
            <p className="font-weight-light p-0 m-0">
              <Link
                to={"/user/" + this.props.item.user?.user_uuid}
                className="featured-poet"
              >
                {this.props.item.user?.first_name}{" "}
                {this.props.item.user?.last_name}
              </Link>
            </p>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

FeaturedRecordingItem.propTypes = {
  accessPlayer: PropTypes.func,
  item: PropTypes.object
};

export default FeaturedRecordingItem;
