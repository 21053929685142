import React, { Component, Fragment } from "react";
import {
  Menu,
  MenuItem,
  Highlighter,
  AsyncTypeahead
} from "react-bootstrap-typeahead";
import { groupBy, map } from "lodash";
import axios from "axios";

class SolrSearchInput extends Component {
  state = {
    allowNew: false,
    isLoading: false,
    multiple: false,
    options: []
  };

  constructor() {
    super();
    this.makeAndHandleRequest = this.makeAndHandleRequest.bind(this);
  }

  makeAndHandleRequest(query) {
    const SEARCH_URI = "http://54.67.109.174/solr/recitedverse/select?df=name&";

    return axios(`${SEARCH_URI}q=${query}&wt=json&rows=20`, {}).then(
      response => {
        let items = response.data.response.docs;
        items.map(function(item) {
          if (item.type === "recitation") {
            item.name = item.name + " by " + item.author;
          }
          return items;
        });

        this.setState({
          isLoading: false,
          options: items
        });
      }
    );
  }

  _handleSearch = query => {
    this.setState({ isLoading: true });
    this.makeAndHandleRequest(query);
  };

  render() {
    const props = {
      renderMenu: this._renderMenu
    };

    return (
      <Fragment>
        <AsyncTypeahead
          {...props}
          {...this.state}
          labelKey="name"
          minLength={2}
          onSearch={this._handleSearch}
          placeholder="Search"
          className="show-all"
        />
      </Fragment>
    );
  }

  _renderMenu(results, menuProps) {
    let idx = 0;
    const grouped = groupBy(results, r => r.type);
    const items = Object.keys(grouped)
      .sort()
      .map(type => {
        return [
          !!idx && <Menu.Divider key={`${type}-divider`} />,
          <Menu.Header key={`${type}-header`}>{type}</Menu.Header>,
          map(grouped[type], state => {
            const item = (
              <MenuItem key={idx} option={state} position={idx}>
                <Highlighter search={menuProps.text}>{state.name}</Highlighter>
              </MenuItem>
            );

            idx++;
            return item;
          })
        ];
      });

    return <Menu {...menuProps}>{items}</Menu>;
  }
}

export default SolrSearchInput;
