import React, { Component } from "react";
import {
  Row,
  Card,
  CardBody,
  CardImg,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Col,
  Navbar,
  Collapse,
  NavbarToggler,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap";
import "./Login.css";
import Background from "../static/images/login-bg.jpg";
import Logo from "../static/images/rv-logo-blue.png";
import LoginForm from "./components/LoginForm.js";
import RegisterForm from "./components/RegisterForm.js";
import { Redirect } from "react-router-dom";
import classnames from "classnames";

class Home extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "2",
      redirect: false
    };
  }

  componentDidMount() {
    document.getElementById("root").click();
  }

  setRedirect = () => {
    this.setState({
      redirect: true
    });
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    return (
      <div className="App login-page">
        <Navbar className="login-navbar" light>
          <NavbarBrand href="/">RECITED VERSE</NavbarBrand>
          <Nav className="ml-auto login-navbar" navbar>
            <LoginForm {...this.props} toggle={this.toggle} />
          </Nav>
        </Navbar>
        <Row className="login-wrapper">
          <Col md="6" style={{ backgroundColor: "#FFFAF0" }}>
            <div style={{ marginTop: "15%", marginLeft: "5%" }}>
              <CardImg
                style={{ width: "50%" }}
                src={Logo}
                onClick={this.setRedirect}
              ></CardImg>
            </div>
          </Col>
          <Col
            className="register-form"
            md="6"
            style={{ backgroundColor: "#FFFAF0" }}
          >
            {this.renderRedirect()}
            <div style={{ marginTop: "25%", marginRight: "5%" }}>
              <h3 className="create-account-text">CREATE A NEW ACCOUNT</h3>
              <p className="create-account-subtext">
                {" "}
                Access a world of free high-quality online poetry recordings.
              </p>
              <RegisterForm {...this.props} toggle={this.toggle} />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Home;
