import React, { Component } from "react";
import logo from "./../../static/images/rv-logo-blue.png";
import poetBackground from "./../../static/images/profile.png";

class GroupInfo extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const groupData = this.props.groupData;
    const editable = this.props.editable;
    const colorBackground = "#" + groupData?.color_header;
    const coverPhoto =
      groupData && groupData.cover_photo ? (
        <img
          src={encodeURI(groupData.cover_photo)}
          alt={"group " + groupData.name + " cover photo"}
        />
      ) : (
        <div
          style={{
            backgroundColor: groupData.color_header,
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        ></div>
      );
    const mainPhoto =
      groupData && groupData.main_photo
        ? encodeURI(groupData.main_photo)
        : logo;
    return (
      <div className="group-banner-wrapper">
        <div
          className={`group-banner-background-${(
            groupData.cover_photo === ""
          ).toString()}`}
        >
          {coverPhoto}
        </div>
        <div className="group-banner-content wrap">
          <div className="group-banner-image">
            <img
              src={mainPhoto}
              style={{
                backgroundColor: "white"
              }}
              alt={"group " + groupData.name + " profile picture"}
            />
          </div>
          <div className="group-banner-title">
            <h2>{groupData?.name}</h2>
            <p>{groupData?.group_info}</p>
          </div>
          {editable ? (
            <div className="group-banner-cta">
              <h5>{groupData?.members?.length} member(s)</h5>
              <button
                className="button primary"
                onClick={() => {
                  this.props.toggleModal();
                }}
              >
                Manage
              </button>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  }
}

export default GroupInfo;
