import React, { Component } from "react";
import { PropTypes } from "prop-types";
import axios from "axios";
import { Alert } from "reactstrap";
import Header from "./../Headers/Header.js";
import NotificationItem from "./NotificationItem";
import "./Notifications.css";
import { toast } from "react-toastify";

class Notifications extends Component {
  constructor(props) {
    super(props);

    const userJSON = localStorage.getItem("activeUser");
    const user = JSON.parse(userJSON);

    this.state = {
      activeUser: user,
      notifications: [],
      loading: true
    };
  }

  componentDidMount() {
    this.getNotifications();
  }

  // getApiData = async () => {
  //   const user_uuid = this.props.activeUser.user_uuid;
  //   const url = this.props.apiHost + "users/" + user_uuid + "/";
  //   const headerData = {
  //     Authorization: "Token " + this.props.activeUser.token
  //   };
  //   await axios
  //     .get(url, {
  //       headers: headerData
  //     })
  //     .then(response => {
  //       var token = JSON.parse(localStorage.getItem("activeUser")).token;
  //       response.data.token = token;
  //       this.setState({
  //         user: response.data
  //       });
  //       localStorage.setItem("activeUser", JSON.stringify(response.data));
  //     })
  //     .catch(error => {
  //       console.error(error);
  //     });
  // };

  getNotifications = () => {
    if (this.state.activeUser === null) {
      return null;
    }

    this.setState({ loading: true });

    // get notifications
    const notificationEndpoint = this.props.apiHost + "notifications/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    axios
      .get(notificationEndpoint, {
        headers: headerData
      })
      .then(response => {
        const data = response.data;
        console.log(data);
        if (data.error) {
          console.error(data.error);
        } else {
          // this.getApiData();
          this.props.activeUser.notification_count = data.length;
          this.setState({
            notifications: data.reverse()
          });
        }
      })
      .catch(error => {
        toast.error("Error fetching notifications");
      });

    // const notificationCountEndpoint =
    //   this.props.apiHost + "notifications_count/";

    // axios
    //   .get(notificationCountEndpoint, {
    //     headers: headerData,
    //   })
    //   .then((response) => {});

    this.setState({ loading: false });
  };

  render() {
    const notifications = this.state.notifications;

    return (
      <div className="App">
        <Header {...this.props} />
        <div className="page-wrapper">
          {this.state.loading === true ? (
            <div></div>
          ) : (
            <div className="wrap notifications-content">
              <h5>Your Notifications</h5>
              <div className="notifications-list">
                {!notifications || notifications.length === 0 ? (
                  <Alert color="success">You have no new notifications</Alert>
                ) : (
                  notifications.map((notification, i) => (
                    <li className="notifications-list-item" key={i}>
                      <NotificationItem
                        {...this.props}
                        activeUser={this.props.activeUser}
                        notification={notification}
                      />
                    </li>
                  ))
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Notifications.propTypes = {
  apiHost: PropTypes.string,
  activeUser: PropTypes.object
};

export default Notifications;
