import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

export default class ModalRecitationDelete extends Component {
  constructor(props) {
    super(props);
  }

  deleteFromArray = () => {
    var new_recitations = this.state.recitations;
    const recitation_uuid = this.props.selectedRecitation.recitation_uuid;
    for (let i = 0; i < new_recitations.length; i++) {
      const element = new_recitations[i];
      if (element["recitaton_uuid"] === recitation_uuid) {
        new_recitations.splice(i, 1);
      }
    }
    return new_recitations;
  };

  deleteRecording = () => {
    const recitation_uuid = this.props.selectedRecitation.recitation_uuid;
    const playlist_uuid = this.props.playlist.playlist_uuid;
    const url =
      this.props.apiHost + "playlists/" + playlist_uuid + "/utils/delete";
    const headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };

    var bodyFormData = new FormData();
    bodyFormData.set("recitation_uuid", recitation_uuid);

    axios
      .post(url, bodyFormData, {
        headers: headerData
      })
      .then(() => {
        toast.success("You have deleted a recitation from playlist");
        this.props.toggleModalDelete();
        this.props.removeFromList(this.props.selectedRecitation["id"]);
      })
      .catch(err => {
        console.error(err);
        toast.error("An error occurred deleting recitation from playlist");
      });
  };

  closeModal = event => {
    event.preventDefault();

    this.props.toggleModalDelete();
  };

  render() {
    return (
      <Modal isOpen={this.props.modalDelete} toggle={this.toggleModalDelete}>
        <ModalHeader toggle={this.toggleModalDelete}>
          Delete Recitation
        </ModalHeader>
        <ModalBody>
          <div>
            <h4 className="upload-header">
              Are you sure you want to delete this recitation from the playlist?
            </h4>
            <Button
              style={{ margin: "10px" }}
              color="danger"
              onClick={() => {
                this.deleteRecording();
              }}
            >
              Yes
            </Button>
            <Button
              style={{ margin: "10px" }}
              color="primary"
              outline
              onClick={this.closeModal}
            >
              No
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
