import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { Button } from "reactstrap";
import logo from "./../../static/images/logo-white.png";
import favorite from "./../../static/images/baseline_favorite_black_18dp.png";
import notFavorite from "./../../static/images/baseline_favorite_border_black_18dp.png";
import axios from "axios";
import { toast } from "react-toastify";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  Row,
  Col
} from "reactstrap";
import { Link } from "react-router-dom";

const likedImage = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
    <path d="M19 24l-7-6-7 6v-24h14v24z" />
  </svg>
);
const notLikedImage = (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 0v24l7-6 7 6v-24h-14zm1 1h12v20.827l-6-5.144-6 5.144v-20.827z" />
  </svg>
);

class PlaylistInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLiked: false,
      numLikes: 0
    };
  }

  fillPlaylistImages = item => {
    const images = [];

    for (let i = 0; i < 4; i++) {
      if (i >= item.length || item[i] === null) {
        images.push(<div className="playlist-cover" key={i}></div>);
      }
    }

    return images;
  };

  likeRecording = () => {
    var bodyFormData = new FormData();
    const playlist_uuid = this.props.match.params.id;

    this.setState(prevState => ({ isLiked: !prevState.isLiked }));

    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    var url = "";
    if (!this.state.isLiked) {
      url = this.props.apiHost + "playlists/" + playlist_uuid + "/likes/";

      axios
        .post(url, bodyFormData, {
          headers: headerData
        })
        .then(() => {
          toast.success("Added to playlist to likes");
        })
        .catch(() => {
          toast.error("An error occurred saving this playlist");
        });
    } else {
      url = this.props.apiHost + "playlists/" + playlist_uuid + "/unlikes/";

      axios
        .post(url, bodyFormData, {
          headers: headerData
        })
        .then(() => {
          toast.success("Removed playlist from likes");
        })
        .catch(() => {
          toast.error("An error occurred saving this playlist");
        });
    }
  };

  // getIsLiked = () => {
  //   const playlist_uuid = this.props.match.params.id;
  //   let url = this.props.apiHost + "playlists/" + playlist_uuid + "/isLiked/";

  //   let activeUser = this.props.activeUser;

  //   if (!activeUser) {
  //     activeUser = JSON.parse(localStorage.getItem("activeUser"));
  //   }

  //   let headerData = {
  //     Authorization: "Token " + activeUser.token
  //   };

  //   axios
  //     .get(url, {
  //       headers: headerData
  //     })
  //     .then(response => {
  //       this.setState({ isLiked: response.data.liked });
  //     }).catch(() => {
  //       console.log("An error occurred getting playlist likes");
  //       toast.error("An error occurred getting playlist likes");
  //     });
  // };

  componentDidMount() {
    //this.getIsLiked();
  }

  render() {
    const playlist = this.props.playlist;
    const user = this.props.user;
    const playlistItems = this.props.playlistItems;
    const handleImageError = e => {
      e.target.src = logo;
    };

    return (
      <div className="playlist-banner">
        <div className="wrap">
          <div className="playlist-covers">
            {playlistItems.map((item, index) => {
              if (item !== null) {
                const image = item.cover;

                return (
                  <div className="playlist-cover" key={index}>
                    <img
                      key={index}
                      src={image}
                      alt={image}
                      onError={handleImageError}
                    ></img>
                  </div>
                );
              }
            })}
            {this.fillPlaylistImages(playlistItems)}
          </div>
          <div className="playlist-banner-body">
            <div className="playlist-info">
              <h1>{playlist.name}</h1>

              <h5>
                Created by{" "}
                <Link to={"/user/" + user.user_uuid}>
                  {user ? user.first_name + " " + user.last_name : "Unknown"}
                </Link>
              </h5>
            </div>
            <div className="playlist-cta">
              <Button color="primary" onClick={() => this.props.playPlaylist()}>
                Play Playlist
              </Button>
            </div>
            {/* <div className="poem-actions">
              <button onClick={this.likeRecording}>
                {!this.state.isLiked ? notLikedImage : likedImage}
              </button>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

PlaylistInfo.propTypes = {
  playPlaylist: PropTypes.func
};

export default PlaylistInfo;
