import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";

export default class ModalPlaylistDelete extends Component {
  deletePlaylist = () => {
    const playlist_uuid = this.props.selectedPlaylist.playlist_uuid;
    const deleteUrl =
      this.props.apiHost + "playlists/" + playlist_uuid + "/delete/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    axios
      .get(deleteUrl, { headers: headerData })
      .then(response => {
        toast.success("Your playlist has successfully been deleted");
        // reload page
        if (response.status == 200 || response.status == 201) {
          setTimeout(() => {
            window.location.reload(); // @TODO this will wipe playlist
          }, 1000);
        }
      })
      .catch(err => {
        console.error(err);
        toast.error("There was an error in deleting your playlist");
      });
  };

  closeModal = event => {
    event.preventDefault();

    this.props.toggleModalDelete();
  };

  render() {
    return (
      <Modal isOpen={this.props.modalDelete} toggle={this.toggleModalDelete}>
        <ModalHeader toggle={this.toggleModalDelete}>
          Delete Playlist
        </ModalHeader>
        <ModalBody>
          <h4 className="upload-header">
            Are you sure you want to delete this playlist?
          </h4>
          <Button
            style={{ margin: "10px" }}
            color="danger"
            onClick={this.deletePlaylist}
          >
            Yes
          </Button>
          <Button
            style={{ margin: "10px" }}
            color="primary"
            outline
            onClick={this.closeModal}
          >
            No
          </Button>
        </ModalBody>
      </Modal>
    );
  }
}
