import React, { Component } from "react";
import axios from "axios";
import FeaturedRecordingsCarousel from "./FeaturedRecordingsCarousel.js";
import RecommendedPoemsCarousel from "./RecommendedPoemsCarousel.js";
import PopularPlaylistsCarousel from "./PopularPlaylistsCraousel";
import RecentUploadsList from "./RecentUploadsList.js";
import RecommendedGroupCarousel from "./RecommendedGroupsCarousel.js";
import { toast } from "react-toastify";

class StreamingMainContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      featuredRecordings: [],
      recentlyUploaded: [],
      recommendedPoems: [],
      recommendedGroups: [],
      popularPlaylists: [],
      featuredPoet: {}
    };
  }

  componentDidMount() {
    this.getFeaturedRecordings();
    this.getRecommendedPoems();
    this.getRecentUploads();
    this.getRecommendedGroups();
    this.getPopularPlaylists();
  }

  getFeaturedRecordings = () => {
    const getUrlFeaturedRecordings =
      this.props.apiHost + "recitations/featured/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(getUrlFeaturedRecordings, { featured: true, headers: headerData })
      .then(response => {
        let data = response.data.filter(item => {
          const shouldRender =
            Boolean(item.user) !== false && Boolean(item.poem) !== false
              ? true
              : false;
          if (shouldRender) {
            return item;
          }
        });
        //randomize data
        data.sort(() => Math.random() - 0.5);
        this.setState({
          featuredRecordings: data
        });
      })
      .catch(error => {
        toast.error("There was an error getting featured recordings");
      });
  };

  getRecommendedPoems = () => {
    const getUrlRecommendedPoems = this.props.apiHost + "poems/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(getUrlRecommendedPoems, { recommended: true, headers: headerData })
      .then(response => {
        let data = response.data.filter(item => {
          const shouldRender =
            Boolean(item.poet) !== false && Boolean(item.poem) !== false
              ? true
              : false;
          if (shouldRender) {
            return item;
          }
        });
        this.setState({ recommendedPoems: response.data });
      })
      .catch(error => {
        toast.error("There was an error getting recommended poems");
      });
  };

  getPopularPlaylists = () => {
    const popularPlaylists = this.props.apiHost + "playlists/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(popularPlaylists, { headers: headerData })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          data.sort(() => Math.random() - 0.5);
          this.setState({ popularPlaylists: data });
        }
      })
      .catch(error => {
        toast.error("There was an error getting popular playlists");
      });
  };

  getRecommendedGroups = () => {
    const recommendedGroups = this.props.apiHost + "groups/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(recommendedGroups, { headers: headerData })
      .then(response => {
        if (response.status === 200) {
          let data = response.data;
          this.setState({ recommendedGroups: data });
        }
      })
      .catch(error => {
        toast.error("There was an error getting recommended groups");
      });
  };

  getRecentUploads = () => {
    const recentUploadsUrl = this.props.apiHost + "latest_recitations/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(recentUploadsUrl, { headers: headerData })
      .then(response => {
        if (response.status === 200) {
          let data = response.data.filter(item => {
            const shouldRender =
              Boolean(item.user) !== false && Boolean(item.poem) !== false
                ? true
                : false;
            if (shouldRender) {
              return item;
            }
          });
          this.setState({ recentlyUploaded: data });
        }
      })
      .catch(error => {
        toast.error("There was an error getting recent uploads");
      });
  };

  render() {
    let featuredRecordings;
    if (this.state.featuredRecordings.length > 0) {
      featuredRecordings = (
        <div className="featured-content">
          <div className="content-section">
            <FeaturedRecordingsCarousel
              accessPlayer={this.props.accessPlayer}
              title={"Featured Readings"}
              items={this.state.featuredRecordings}
              {...this.props}
            />
          </div>
        </div>
      );
    }

    let recommendedPoems;
    if (this.state.recommendedPoems.length > 0) {
      recommendedPoems = (
        <RecommendedPoemsCarousel
          title={"Recommended Poems"}
          items={this.state.recommendedPoems}
        />
      );
    }

    let popularPlaylists;
    if (this.state.popularPlaylists.length > 0) {
      popularPlaylists = (
        <div className="content-section">
          <PopularPlaylistsCarousel
            accessPlayer={this.props.accessPlayer}
            title={"Popular Playlists"}
            items={this.state.popularPlaylists}
            {...this.props}
          />
        </div>
      );
    }

    let recommendedGroups;
    if (this.state.recommendedGroups.length > 0) {
      recommendedGroups = (
        <div className="content-section">
          <RecommendedGroupCarousel
            accessPlayer={this.props.accessPlayer}
            title={"Recommended Groups"}
            items={this.state.recommendedGroups}
            {...this.props}
          />
        </div>
      );
    }

    let recentlyUploaded;
    if (this.state.recentlyUploaded.length > 0) {
      recentlyUploaded = (
        <div className="content-section">
          <RecentUploadsList
            accessPlayer={this.props.accessPlayer}
            title={"Recently Uploaded"}
            items={this.state.recentlyUploaded}
            {...this.props}
          />
        </div>
      );
    }

    return (
      <div className="streaming-content">
        {featuredRecordings}
        <div className="main-content">
          <div className="content-main">
            {popularPlaylists}
            {recommendedGroups}
          </div>
          <aside className="content-sub">{recentlyUploaded}</aside>
          {/* <div className={`recommended-poem-wrapper ${this.props.className}`}>
            <div className="recommended-poem-container"></div>
            <div className="recommended-poem-container"></div>
          </div> */}
        </div>
      </div>
    );
  }
}

export default StreamingMainContent;
