import React, { Component } from "react";
import PopularPlaylistItem from "./PopularPlaylistItem";
// import Carousel from "../../components/Carousel.js";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class PopularPlaylistsCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: this.props.items,
      responsive: {
        0: { items: 1 },
        330: { items: 1 },
        500: { items: 2 },
        660: { items: 2 },
        940: { items: 3 },
        1200: { items: 3 },
        1450: { items: 3 }
      },
      currentIndex: 0,
      buttonsDisabled: false,
      galleryItems: null
    };
  }

  thumbItem = (item, i) => (
    <span key={item} onClick={() => this.Carousel.onDotClick(i)}>
      *
    </span>
  );

  componentDidMount() {
    const galleryItems = this.setState({ galleryItems });
  }

  getDefaultImage = () => {
    let imageIndex = Math.floor(Math.random() * 20);
    return this.imageList[imageIndex];
  };

  generateItems = () => {
    const items = this.props.items
      .filter(item => item.name !== "default")
      .slice(0, 9)
      .map((item, i) => (
        <PopularPlaylistItem
          {...this.props}
          key={i}
          item={item}
          accessPlayer={this.props.accessPlayer}
        />
      ));
    return items;
  };

  render() {
    const items = this.generateItems();
    return (
      <div className="popular-playlists">
        <div className="content-header">
          <h4 className="section-title text-uppercase">{this.props.title}</h4>
          <div>
            <FontAwesomeIcon
              className="fa-1-5x mr-2 craousel-control"
              icon="caret-square-left"
              onClick={() => this.Carousel.slidePrev()}
            />
            <FontAwesomeIcon
              className="fa-1-5x craousel-control"
              icon="caret-square-right"
              onClick={() => this.Carousel.slideNext()}
            />
          </div>
        </div>
        <AliceCarousel
          items={items}
          duration={400}
          autoPlay={false}
          buttonsDisabled={true}
          // dotsDisabled={true}
          startIndex={1}
          fadeOutAnimation={true}
          responsive={this.state.responsive}
          ref={el => (this.Carousel = el)}
        />
      </div>
    );
  }
}

export default PopularPlaylistsCarousel;
