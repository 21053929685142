import React from "react";
import { useDrag, useDrop } from "react-dnd";

const DraggableComment = ({ comment, index, moveComment }) => {
  const ref = React.useRef(null);

  const [, drop] = useDrop({
    type: "comment",
    accept: "comment",
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      moveComment(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: "comment",
    item: { type: "comment", id: comment.key, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity }}>
      {comment}
    </div>
  );
};

export default DraggableComment;
