import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import ReactQuill from "react-quill";
import Axios from "axios";
import logo from "../static/images/logo-white.png";
import "./Project.scss";
import PdfModal from "./PdfModal.js";

export default class ProjectView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: this.props.project,
      text: this.props.text,
      user: this.props.user,
      project_id: this.props.project_id,
      projectItems: this.props.projectItems,
      loading: true,
      editable: false,
      isModalOpen: false
    };
  }

  getProjectDetail = project_id => {
    this.setState({ loading: true });
    const poem_uuid = this.props.match.params.id;
    const url = this.props.apiHost + "projects/" + poem_uuid + "/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    Axios.get(url, { headers: headerData })
      .then(response => {
        this.setState(
          {
            project: response.data,
            text: response.data.text,
            loading: false
          },
          () => {
            this.replaceURLsWithLinks(response.data.text);
          }
        );
      })
      .catch(error => {
        console.log(error);
      });
  };

  replaceURLsWithLinks = str => {
    console.log(this.props);
    const regex = /(https?:\/\/)([^\/\s]+)([^\s]*)/gi;
    let newStr = str.replace(
      regex,
      `<a href="$1www.recitedverse.com$3">$1recitedverse.com$3</a>`
    );
    console.log(newStr);
    console.log("--");
    console.log(str);
    this.setState(
      {
        text: newStr,
        project: {
          ...this.state.project,
          text: newStr
        }
      },
      () => {
        console.log(this.state.project);
      }
    );
  };

  showPdf = () => {
    console.log("show pdf");
    this.setState({
      isModalOpen: !this.state.isModalOpen
    });
  };

  closePdf = () => {
    this.setState({
      isModalOpen: false
    });
  };

  componentDidMount() {
    this.getProjectDetail(this.state.project_id);
  }

  render() {
    return this.state.loading === true ? (
      <div className="poem-container wrap" style={{ paddingTop: "40px" }}>
        <div className="center">
          <h2>Loading recoding...</h2>
        </div>
      </div>
    ) : (
      <div>
        <div className="poem-banner-wrapper">
          <div className="poem-banner-background">
            <img src={logo} alt={this.state.project.title} />
          </div>
          <div className="poem-banner-content wrap">
            <div className="poem-banner-title">
              <h3>Recited Verse Virtual Residency 2022</h3>
              <h2>{this.state.project.title}</h2>
              {/* make a column with two rows */}
              <div className="poem-banner-cta">
                <div className="poem-banner-cta-row">
                  <ul className="poem-intro poem-banner-subtitle">
                    <li>
                      <b>By: </b>
                      {this.state.project.author}
                    </li>
                    <li>{this.state.project.university}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="poem-banner-cta">
              <Button className="button primary" onClick={this.showPdf}>
                View PDF
              </Button>
            </div>
          </div>
        </div>
        <div className="wrap">
          <div className="project-script">
            <ReactQuill
              className="lyrics-text"
              value={this.state.text}
              modules={{ toolbar: false }}
              readOnly={true}
            />
          </div>
        </div>
        <PdfModal
          fileUrl={this.state.project.pdf}
          fileName={this.state.project.title}
          isOpen={this.state.isModalOpen}
          onClose={this.closePdf}
          toggle={this.showPdf}
        />
      </div>
    );
  }
}
