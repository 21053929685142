import React, { Component } from "react";
import Header from "./../Headers/Header.js";

import ResetPasswordForm from "./ResetPasswordForm.js";

class ResetPassword extends Component {
  render() {
    return (
      <div className="App">
        <Header isAuthenticated={false} />
        <div style={{ paddingTop: "80px", width: "50%", margin: "auto" }}>
          <h6 className="create-account-text">Reset Password</h6>
          <div style={{ paddingTop: "10px", width: "50%", margin: "auto" }}>
            <ResetPasswordForm
              {...this.props}
              {...this.state}
              toggle={this.toggle}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
