import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";

class ModalGroupAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groupName: ""
    };
  }

  handleTextChange = e => {
    this.setState({ groupName: e.target.value });
  };

  createGroup = () => {
    if (!this.state.groupName || this.state.groupName === "") {
      toast.error("Group name is required");
      return;
    }

    const postUrl = this.props.apiHost + "groups/";

    const headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };

    const bodyFormData = new FormData();
    bodyFormData.set("name", this.state.groupName);
    bodyFormData.set("members", this.props.activeUser.id); // add self as member when creating
    bodyFormData.set("group_creator", this.props.activeUser.id);

    axios
      .post(postUrl, bodyFormData, {
        headers: headerData
      })
      .then(res => {
        if (res.status == 200 || res.status == 201) {
          this.props.history.push("/groups/" + res.data.group_uuid);
        }
      })
      .catch(function(err) {
        toast.error("Something went wrong with creating the group!");
      });
  };

  toggleModalAdd = () => {
    this.props.toggleModalAdd();
  };

  render() {
    return (
      <Modal isOpen={this.props.modalAdd} toggle={this.toggleModalAdd}>
        <ModalHeader toggle={this.toggleModalAdd}>Create a Group</ModalHeader>
        <ModalBody>
          <p>
            <i>
              Creating a group for your class? Make sure to add your section
              name
            </i>
          </p>
          <input
            placeholder="Enter a Group Name"
            required
            type="text"
            value={this.state.groupName}
            onChange={this.handleTextChange}
            className="form-input form-control"
          />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={this.toggleModalAdd}>
            Close
          </Button>
          <Button color="primary" onClick={this.createGroup}>
            Create Group
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ModalGroupAdd;
