import React, { Component } from "react";
import MemberItem from "./MemberItem";

class MemberList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const members = this.props.groupMembers;
    return members && members.length > 0 ? (
      <ul className="content-list member-list">
        {members.map((member, i) => (
          <MemberItem key={i} member={member} />
        ))}
      </ul>
    ) : (
      <h2>This group has no member yet.</h2>
    );
  }
}

export default MemberList;
