import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap";

class ModalProfileInfoEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: props.user.first_name,
      last_name: props.user.last_name,
      desc: props.user.desc,
      avatar: props.user.avatar,
      avatar_image: props.user.avatar,
      background: props.user.background,
      background_image: props.user.background
    };
  }

  handleFirstNameChange = event => {
    const first_name = event.target.value;

    this.setState({ first_name: first_name });
  };

  handleLastNameChange = event => {
    const last_name = event.target.value;

    this.setState({ last_name: last_name });
  };

  handleDescChange = event => {
    const desc = event.target.value;

    this.setState({ desc: desc });
  };

  handleAvatarChange = event => {
    const avatar = event.target.files[0];

    this.setState({
      avatar: avatar,
      avatar_image: URL.createObjectURL(avatar)
    });
  };

  handleBackgroundChange = event => {
    const background = event.target.files[0];

    this.setState({
      background: background,
      background_image: URL.createObjectURL(background)
    });
  };

  updateInfo = () => {
    let url =
      this.props.apiHost + "users/" + this.props.activeUser.user_uuid + "/";

    var formData = new FormData();
    // Prevent null submission
    const first_name = this.state.first_name ? this.state.first_name : "";
    const last_name = this.state.last_name ? this.state.last_name : "";
    const desc = this.state.desc ? this.state.desc : "";
    const avatar = this.state.avatar ? this.state.avatar : "";
    const background = this.state.background ? this.state.background : "";

    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("desc", desc);
    formData.append("avatar", avatar);
    formData.append("background", background);

    const header = {
      Authorization: "Token " + this.props.activeUser.token,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json"
    };

    axios
      .put(url, formData, {
        headers: header,
        credentials: "same-origin",
        mode: "no-cors"
      })
      .then(res => {
        if (res.status === 200) {
          toast.success("Updated!");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch(error => {
        toast.error("Something went wrong while updating your profile!");
      });
  };

  toggleModalEdit = () => {
    this.props.toggleModalEdit();
  };

  render() {
    const first_name = this.state.first_name;
    const last_name = this.state.last_name;
    const desc = this.state.desc;
    const avatar = this.state.avatar;
    const avatar_image = this.state.avatar_image;
    const background = this.state.background;
    const background_image = this.state.background_image;
    return (
      <Modal
        isOpen={this.props.modalEdit}
        toggle={this.toggleModalEdit}
        className="profile-manage-modal"
      >
        <ModalHeader toggle={this.toggleModalEdit}>Edit Profile</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <div className="space-sm">
                <Label for="first-name">First Name</Label>
                <Input
                  id="first-name"
                  type="text"
                  name="first-name"
                  defaultValue={first_name}
                  onChange={e => this.handleFirstNameChange(e)}
                />
              </div>
              <div className="space-sm">
                <Label for="last-name">Last Name</Label>
                <Input
                  id="last-name"
                  type="text"
                  name="last-name"
                  defaultValue={last_name}
                  onChange={e => this.handleLastNameChange(e)}
                />
              </div>
              <div className="space-sm">
                <Label for="desc">Description</Label>
                <Input
                  id="desc"
                  type="textarea"
                  name="desc"
                  defaultValue={desc}
                  onChange={e => this.handleDescChange(e)}
                />
              </div>
              <div className="space-sm">
                <Label for="avatar">Profile Picture</Label>
                <Input
                  id="avatar"
                  type="file"
                  name="avatar"
                  onChange={e => this.handleAvatarChange(e)}
                />
                <FormText color="muted">
                  Select a photo for your new profile picture
                </FormText>
                <div className="main-photo">
                  <img
                    src={avatar_image ? avatar_image : avatar}
                    alt={
                      "user " +
                      first_name +
                      " " +
                      last_name +
                      " profile picture"
                    }
                  />
                </div>
              </div>
              <div className="space-sm">
                <Label for="background">Cover Photo</Label>
                <Input
                  id="background"
                  type="file"
                  name="background"
                  onChange={e => this.handleBackgroundChange(e)}
                />
                <FormText color="muted">
                  Select a photo for your new cover photo
                </FormText>
                <img
                  src={background_image ? background_image : background}
                  alt={
                    "user " + first_name + " " + last_name + " background image"
                  }
                />
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.updateInfo}>
            Update
          </Button>
          <Button color="secondary" onClick={this.toggleModalEdit}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ModalProfileInfoEdit;
