import React, { Component } from "react";
import UploadList from "./UploadList";

class Uploads extends Component {
  constructor(props) {
    super(props);

    this.editable = this.props.editable;
  }

  render() {
    return (
      <UploadList
        {...this.props}
        recitations={this.props.recitations}
        editable={this.editable}
      />
    );
  }
}

export default Uploads;
