import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faQuestionCircle,
  faAddressCard,
  faICursor
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./Sidebar.css";

const Sidebar = props => {
  return (
    <div className={`${props.className} sidebar-content`}>
      {/* <div style={styles.divider} /> */}
      {/* <h6
          style={styles.sidebarLink}
          className="pl-4 m-0 text-left font-weight-bold"
        >
          MY ACCOUNT
        </h6> */}
      <ul className="sidebar-menu">
        <li>
          <Link to="/" className="sidebar-link">
            <FontAwesomeIcon icon="home" className="icon" />
            <span>Home</span>
          </Link>
        </li>
        <li>
          <Link to="/upload" className="sidebar-link">
            <FontAwesomeIcon icon="upload" className="icon" />
            <span>Upload</span>
          </Link>
        </li>
        <Link to="/projects" className="sidebar-link">
          <FontAwesomeIcon icon={faICursor} className="icon" />
          <span>Projects</span>
        </Link>
        <Link to="/about" className="sidebar-link">
          <FontAwesomeIcon icon="pen-nib" className="icon" />
          <span>About Recited Verse</span>
        </Link>
        <Link to="/aboutus" className="sidebar-link">
          <FontAwesomeIcon icon={faInfoCircle} className="icon" />
          <span>About Us</span>
        </Link>
        <Link to="/faq" className="sidebar-link">
          <FontAwesomeIcon icon={faQuestionCircle} className="icon" />
          <span>FAQ</span>
        </Link>
        <Link to="/contactus" className="sidebar-link">
          <FontAwesomeIcon icon={faAddressCard} className="icon" />
          <span>Contact Us</span>
        </Link>
        <div className="sidebar-divider" />
        <a
          target="_blank"
          href="https://forms.gle/DT3tKbZqT7A98HDd6"
          className="sidebar-link"
        >
          <FontAwesomeIcon icon="comments" className="icon" />
          <span>Feedback</span>
        </a>
      </ul>
    </div>
  );
};

Sidebar.propTypes = {
  style: PropTypes.object,
  children: PropTypes.elementType
};

export default Sidebar;
