import React, { Component, StrictMode } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Header from "../Headers/Header.js";
import AudioRecorder from "react-audio-recorder";
import MicRecorder from "mic-recorder-to-mp3";
import Logo from "../static/images/Mic.png";
import recordImage from "../static/images/record.png";
import Timer from "../components/Timer.js";
import classnames from "classnames";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  Button,
  CardImg,
  FormGroup,
  Input,
  ListGroup,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ButtonGroup
} from "reactstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export default class RecordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // recording: {},
      // duration: 0,
      isRecording: false,
      blobURL: "",
      blob: "",
      isBlocked: false,
      searchPorm: "",
      poem: "",
      poemHTML: "",
      poems: [],
      paste: false,
      activeTab: "0",
      isLoading: false
      //editorState: EditorState.createEmpty(),
    };
    // this.handleRecordingChange = this.handleRecordingChange.bind(this);
    this.Mp3Recorder = new MicRecorder({ bitRate: 128 });

    this.tabs = ["Paste Text", "Upload File", "Search Poem"];
  }

  quillRef = React.createRef();

  componentDidMount() {
    if (this.props.location.currentPoem) {
      let poem = this.props.location.currentPoem;
      this.setState({
        poem: poem.lyrics
      });
    }
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab)
      this.setState({ activeTab: tab.toString() });
  };

  // handleRecordingChange(recording) {
  //   console.log(recording);
  //   this.setState(recording);
  // }

  start = () => {
    if (this.state.isBlocked) {
      console.log("Permission Denied");
    } else {
      this.Mp3Recorder.start()
        .then(() => {
          this.setState({ isRecording: true });
        })
        .catch(e => console.error(e));
    }
  };

  stop = () => {
    this.Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        this.setState({ blob: blob });
        this.setState({ blobURL, isRecording: false });
      })
      .catch(e => console.log(e));
  };

  writePoem = e => {
    const text = e.target.value;

    this.updatePoem(text);
  };

  uploadPoem = async e => {
    const file = e.target.files[0];

    if (file.type !== "text/plain") {
      //toast.error("Sorry, we only support text file");
      alert("Sorry, we only support text file.");
      return;
    }
    const reader = new FileReader();
    reader.onload = async e => {
      const text = e.target.result;
      this.updatePoem(text);
    };
    reader.readAsText(file);
  };

  handleSearch = e => {
    const keyword = e.target.value;

    this.setState({ searchPorm: keyword });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.searchPoem(this.state.searchPorm);
  };

  searchPoem = async keyword => {
    this.setState({ isLoading: true });
    const url = this.props.apiHost + "search/";
    const headerData = {
      Authorization: "Token " + this.props.activeUser.token,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json"
    };
    const bodyFormData = new FormData();
    bodyFormData.set("keyword", keyword);

    axios
      .post(url, bodyFormData, {
        headers: headerData
      })
      .then(response => {
        const poems = response.data.recitations.filter(
          poem => poem.lyrics !== ""
        );
        console.log(poems);

        this.setState({
          poems: poems,
          isLoading: false
        });
      })
      .catch(() => {
        toast.error("Error searching");
      });
  };

  selectPoem = e => {
    const id = e.target.value;
    const poem = this.state.poems.find(item => {
      console.log(item.poem.id);
      return item.poem.id === parseInt(id);
    });

    if (poem && poem.poem.lyrics) {
      this.updatePoem(poem.poem.lyrics);
    } else {
      toast.info("This poem doesn't have a script yet.");
      this.setState({
        poem: "This poem doesn't have a script yet."
      });
    }
  };

  handlePoemChange = value => {
    this.setState({ poemHTML: value, poem: this.stripHTMLTags(value) });
  };

  // Update the poem text in the textarea
  updatePoem = async text => {
    this.setState({ poem: text, poemHTML: text, activeTab: "0" });
  };

  tick() {
    // start timer after button is clicked
    this.interval = setInterval(() => {
      this.setState(prevState => ({
        seconds: prevState.seconds + 1
      }));
    }, 1000);
  }

  stripHTMLTags = str => {
    return str.replace(/<[^>]*>/g, "");
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { editorState } = this.state;
    const poems = this.state.poems;
    return (
      <div className="App upload-page">
        <Header {...this.props} />
        <div className="record-wrapper page-wrapper">
          <div className="wrap center">
            <img className="recitation-icon" src={recordImage} />
            <h1 className="page-title-sm uppercase">Your Audience Awaits</h1>
            <div className="record-container">
              <div className="record-box">
                {/* <CardImg
                  style={{
                    height: "178px",
                    width: "89px",
                    marginBottom: "20px",
                  }}
                  src={Logo}
                  onClick={this.setRedirect}
                ></CardImg> */}

                {/* <AudioRecorder
                onChange={this.handleRecordingChange}
                className="upload-button"
                downloadable={false}
              /> */}
                <div>{this.state.isRecording ? <Timer></Timer> : null}</div>
                <div>
                  {this.state.isRecording ? (
                    <Button outline color="danger" onClick={this.stop}>
                      Stop
                    </Button>
                  ) : (
                    <Button outline color="primary" onClick={this.start}>
                      Record
                    </Button>
                  )}
                </div>

                <div className="spaced-item">
                  {this.state.blob?.size > 0 && !this.state.isRecording && (
                    <audio src={this.state.blobURL} controls="controls" />
                  )}
                </div>
                <div className="spaced-item">
                  {this.state.blob?.size > 0 && !this.state.isRecording && (
                    <Link
                      to={{
                        pathname: "/uploadrecording",
                        state: { blob: this.state.blob },
                        currentPoem: this.props.location.currentPoem,
                        currentText: this.state.poem,
                        currentTextHTML: this.state.poemHTML
                      }}
                    >
                      <Button className="button primary">Continue</Button>
                    </Link>
                  )}
                </div>
              </div>
              <div className="upload-control">
                <div className="record-tab">
                  <Nav tabs>
                    {this.tabs.map((item, i) => (
                      <NavItem key={i}>
                        <NavLink
                          className={classnames({
                            active: this.state.activeTab === i.toString()
                          })}
                          onClick={() => {
                            this.toggleTab(i);
                          }}
                        >
                          {item}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </div>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="0">
                    <ReactQuill
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, false] }],
                          ["bold", "italic", "underline"],
                          [{ align: [] }]
                        ]
                      }}
                      style={{ textAlign: "left", height: "300px" }}
                      value={this.state.poemHTML}
                      onChange={this.handlePoemChange}
                      theme="snow"
                      ref={this.quillRef}
                    />
                    {/* <textarea
                      ref={this.buttonRef}
                      id="textareaid"
                      placeholder="Place your text here..."
                      value={this.state.poem}
                      onChange={this.writePoem}
                    /> */}
                  </TabPane>
                  <TabPane tabId="1">
                    <FormGroup>
                      <Input
                        type="file"
                        name="poem"
                        onChange={this.uploadPoem}
                      />
                    </FormGroup>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="search-poem">
                      <h6 className="section-title">Search Poem</h6>
                      <form onSubmit={this.handleSubmit}>
                        <Input
                          type="search"
                          name="poem"
                          placeholder="search..."
                          onChange={this.handleSearch}
                        />
                        <button type="submit" className="button primary">
                          Search
                        </button>
                      </form>
                      <div className="search-list">
                        {this.state.isLoading ? (
                          <p>Searching...</p>
                        ) : this.state.poems ? (
                          <ListGroup>
                            {poems.map(poem => (
                              <li key={poem.poem.id}>
                                <button
                                  onClick={this.selectPoem}
                                  value={poem.poem.id}
                                >
                                  {poem.poem.name}
                                  <span>{poem.poem.poet}</span>
                                </button>
                              </li>
                            ))}
                          </ListGroup>
                        ) : (
                          <span></span>
                        )}
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RecordForm.propTypes = {
  location: PropTypes.object.isRequired
};
