import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";

class ModalPlaylistRecitationDelete extends Component {
  removeRecitationFromPlaylist = () => {
    const selectedPlaylist = this.props.selectedPlaylist;
    const selectedRecitation = this.props.selectedRecitation;

    if (!selectedPlaylist || !selectedRecitation) {
      return;
    }

    const playlist_uuid = selectedPlaylist.playlist_uuid;
    const recitation_uuid = selectedRecitation.recitation_uuid;

    const deleteUrl =
      this.props.apiHost + "playlists/" + playlist_uuid + "/utils/delete";
    const formData = new FormData();

    formData.set("recitation_uuid", recitation_uuid);

    const headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };

    axios
      .post(deleteUrl, formData, {
        headers: headerData
      })
      .then(response => {
        // reload page
        if (response.status == 200 || response.status == 201) {
          toast.success("Your recitation has successfully been removed");
          setTimeout(() => {
            window.location.reload(); // @TODO this will wipe playlist
          }, 1000);
        }
      })
      .catch(err => {
        console.error(err);
        toast.error("There was an error in deleting your playlist");
      });
  };

  closeModal = event => {
    event.preventDefault();

    this.props.toggleModalDelete();
  };

  render() {
    return (
      <Modal isOpen={this.props.modalDelete} toggle={this.toggleModalDelete}>
        <ModalHeader toggle={this.toggleModalDelete}>
          Remove Recording
        </ModalHeader>
        <ModalBody>
          <h4 className="upload-header">
            Are you sure you want to remove this recording?
          </h4>
          <Button
            style={{ margin: "10px" }}
            color="danger"
            onClick={this.removeRecitationFromPlaylist}
          >
            Yes
          </Button>
          <Button
            style={{ margin: "10px" }}
            color="primary"
            outline
            onClick={this.closeModal}
          >
            No
          </Button>
        </ModalBody>
      </Modal>
    );
  }
}

export default ModalPlaylistRecitationDelete;
