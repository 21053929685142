import React, { Component } from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import PlaylistItem from "./PlaylistItem";
import { PropTypes } from "prop-types";
import ModalPlaylistDelete from "./Modals/ModalPlaylistDelete";
import ModalPlaylistEdit from "./Modals/ModalPlaylistEdit";

class PlaylistsCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalEdit: false,
      modalDelete: false,
      selectedPlaylist: {}
    };
  }

  // toggle the edit modal
  toggleModalEdit = playlist => {
    this.setState(prevState => ({
      modalEdit: !prevState.modalEdit,
      selectedPlaylist: playlist
    }));
  };

  // toggle the delete modal
  toggleModalDelete = playlist => {
    this.setState(prevState => ({
      modalDelete: !prevState.modalDelete,
      selectedPlaylist: playlist
    }));
  };

  addPlaylist = () => {
    this.props.addPlaylist();
  };

  render() {
    const playlists = this.props.items;
    const playlistId = this.state.selectedPlaylist?.playlist_uuid;
    const editable = this.props.editable;

    return (
      <div className="tab-container">
        <ul className="content-list playlist-list">
          {playlists.map((item, index) => (
            <PlaylistItem
              {...this.props}
              key={index}
              item={item}
              toggleModalEdit={this.toggleModalEdit}
              toggleModalDelete={this.toggleModalDelete}
            />
          ))}
          {editable ? (
            <li
              className="content-list-item playlist-list-item playlist-add"
              onClick={this.addPlaylist}
            ></li>
          ) : (
            <span></span>
          )}
        </ul>
        {/*  */}
        <ModalPlaylistEdit
          {...this.props}
          key={`edit-${playlistId}`}
          selectedPlaylist={this.state.selectedPlaylist}
          modalEdit={this.state.modalEdit}
          toggleModalEdit={this.toggleModalEdit}
        />
        {/*  */}
        <ModalPlaylistDelete
          {...this.props}
          key={`delete-${playlistId}`}
          selectedPlaylist={this.state.selectedPlaylist}
          modalDelete={this.state.modalDelete}
          toggleModalDelete={this.toggleModalDelete}
        />
      </div>
    );
  }
}

PlaylistsCarousel.propTypes = {
  playlists: PropTypes.array
};

export default PlaylistsCarousel;
