import React, { Component, KeyboardEventHandler } from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Alert } from "reactstrap";
import { ImagePicker } from "react-file-picker";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import {
  AvForm,
  AvField,
  AvInput,
  AvGroup,
  AvFeedback
} from "availity-reactstrap-validation";
import Header from "../Headers/Header.js";

const animatedComponents = makeAnimated();

const KeyCodes = {
  comma: 188,
  enter: 13
};

class UploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // selectedImage: '',
      name: "",
      lyrics: "",
      lyricsHTML: "",
      user: this.props.activeUser,
      pubYear: "",
      poet: "",
      showSuccess: false,
      res: {},
      poemExists: false,
      poemId: null,
      tags: [],
      inputValue: "",
      isPrivate: false,
      suggestions: [],
      modal: false,
      groupList: [],
      groups: []
    };
  }

  componentDidMount() {
    // console.log(this.props.location.recording.audioData);
    if (
      this.props.location.state != undefined &&
      (this.props.location.state.blob == null &&
        this.props.location.state.audioData == null)
    ) {
      this.props.history.push("/upload");
    }

    if (this.props.location.currentPoem) {
      let poem = this.props.location.currentPoem;
      this.setState({
        poemExists: true,
        name: poem.name,
        lyrics: poem.lyrics,
        pubYear: poem.year,
        poet: poem.poet,
        poemId: poem.id
      });
    }

    if (
      this.props.location.currentText &&
      this.props.location.currentTextHTML
    ) {
      let poem = this.props.location.currentText;
      let poemHTML = this.props.location.currentTextHTML;
      this.setState({
        lyrics: poem,
        lyricsHTML: poemHTML
      });
    }

    this.fetchGroupData();
  }

  toggleModal = () => {
    this.setState(prevState => ({ modal: !prevState.modal }));
  };

  fetchGroupData = () => {
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    const groupURL =
      this.props.apiHost + "users/" + activeUser.user_uuid + "/groups/";

    axios
      .get(groupURL, {
        headers: headerData
      })
      .then(response => {
        if (response.status === 200) {
          let groupInput = [];

          response.data.forEach(item => {
            let group = {};
            group["value"] = item.id;
            group["label"] = item.name;
            groupInput.push(group);
          });

          this.setState({ groupList: groupInput });
        }
      })
      .catch(error => {
        toast.error("Error fetching group data");
      });
  };

  handleCheckbox = event => {
    this.setState({ isPrivate: event.target.checked });
  };

  handleTagDelete = i => {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i)
    });
  };

  handleTagAddition = tag => {
    this.setState(state => ({ tags: [...state.tags, tag] }));
  };

  handleTagDrag = (tag, currPos, newPos) => {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  };

  handleTextChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChange = checked => {
    this.setState({ checked });
  };

  handleImageChange = base64 => {
    fetch(base64)
      .then(res => res.blob())
      .then(blob => {
        this.setState({ cover: blob });
      });

    this.setState({ selectedImage: base64 });
  };

  generateRandomImage = () => {
    const imageEndpoint = this.props.apiHost + "cover_library";
    axios
      .get(imageEndpoint)
      .then(response => {
        let images = response.data;
        var randomImage = images[Math.floor(Math.random() * images.length)];
        this.handleImageChange(randomImage);
        // TODO: this doesn't work
      })
      .catch(error => {
        toast.error("Error fetching cover images");
      });
  };

  handleSelectChange = groups => {
    this.setState({ groups });
  };

  handleValidSubmit = event => {
    event.preventDefault();
    if (!this.state.poemExists) {
      const headerData = {
        Authorization: "Token " + this.props.activeUser.token,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      };

      const data = {
        name: this.state.name,
        lyrics: this.state.lyricsHTML,
        poet: this.state.poet,
        tags: this.state.tags,
        year: parseInt(this.state.pubYear)
      };

      const postUrl = this.props.apiHost + "poems/";
      axios
        .post(postUrl, data, {
          headers: headerData
        })
        .then(response => {
          if (response.status === 201) {
            const poemId = response.data.id;

            var bodyFormData = new FormData();
            bodyFormData.set("poem_id", poemId);
            let generatedBlobName =
              parseInt(Math.round(new Date().getTime() / 1000)) + "-" + poemId;

            if (this.state.cover === undefined) {
              bodyFormData.append("cover", this.state.cover);
              // throw new Error(`No Image uploaded`);
            } else {
              bodyFormData.append("cover", this.state.cover, generatedBlobName);
            }

            if (this.props.location.state.blob != null) {
              bodyFormData.append(
                "record",
                this.props.history.location.state.blob
              );
            } else if (this.props.location.state.audioData != null) {
              bodyFormData.append(
                "record",
                this.props.history.location.state.audioData
              );
            }

            bodyFormData.set("tags", this.state.tags);
            if (this.state.groups.length > 0) {
              let groupList = this.state.groups.map(item => {
                return item.value;
              });
              bodyFormData.set("groups", groupList);
            }

            bodyFormData.set("private", this.state.isPrivate);
            bodyFormData.set("name", this.state.name);

            const recPostUrl = this.props.apiHost + "create_recitation/";

            bodyFormData.get("record");

            axios
              .post(recPostUrl, bodyFormData, {
                headers: headerData
              })
              .then(res => {
                if (res.status === 200) {
                  this.setState({
                    // name: "",
                    // year: "",
                    // lyrics: "",
                    // pubYear: "",
                    // poet: "",
                    selectedImage: null,
                    showSuccess: true,
                    res: res.data
                  });
                  window.location.replace(
                    `/recitations/${this.state.res.recitation_uuid}`
                  );
                }
              })
              .catch(function(err) {
                toast.error("Something went wrong with the upload!");
              });
          }
        })
        .catch(function(error) {
          if (error.message === "No Image uploaded") {
            toast.error("Please upload an image");
          } else {
            toast.error("Something went wrong with the upload!");
          }
        });
    } else {
      console.log("response was NOT 201");
      let headerData = {
        Authorization: "Token " + this.props.activeUser.token
      };

      var bodyFormData = new FormData();
      bodyFormData.set("poem_id", this.state.poemId);
      let generatedBlobName =
        parseInt(Math.round(new Date().getTime() / 1000)) +
        "-" +
        this.state.poemId;
      if (this.state.cover === undefined) {
        bodyFormData.append("cover", this.state.cover);
        throw new Error(`No Image uploaded`);
      } else {
        bodyFormData.append("cover", this.state.cover, generatedBlobName);
      }
      bodyFormData.append("record", this.props.history.location.state.blob);
      const recPostUrl = this.props.apiHost + "create_recitation/";

      axios
        .post(recPostUrl, bodyFormData, {
          headers: headerData
        })
        .then(res => {
          if (res.status === 200) {
            this.setState({
              name: "",
              year: "",
              lyrics: "",
              pubYear: "",
              poet: "",
              tags: [],
              selectedImage: null,
              showSuccess: true,
              res: res.data
            });

            toast.success("your recitation was uploaded Successfully !");
          }
        })
        .catch(function(err) {
          console.log(err.response);
          if (err.message === "No Image uploaded") {
            toast.error("Please upload an image");
          } else {
            toast.error("Something went wrong with the upload!");
          }
        });
    }
  };

  handleInvalidSubmit = (event, errors, values) => {
    this.setState({ errors, values });
  };

  stripHTMLTags = str => {
    return str.replace(/<[^>]*>/g, "");
  };

  handlePoemChange = value => {
    this.setState({ lyricsHTML: value, lyrics: this.stripHTMLTags(value) });
  };

  // createOption = (label) => ({
  //   label,
  //   value: label.toLowerCase().replace(/\W/g, ''),
  // });

  // handleCreateTag = tag => {
  //   const newOption = this.createOption(tag);
  //   this.setState({ tags: [...this.state.tags, newOption] });
  // }

  handleInputChange = inputValue => {
    this.setState({ inputValue });
  };

  // handleAddTag = () => {
  //   const { tags, inputValue } = this.state;
  //   console.log(inputValue);
  //   if (inputValue.length > 0) {
  //     const newTags = [...tags, { value: inputValue, label: inputValue }];
  //     this.setState({ tags: newTags, inputValue: "" });
  //   }
  // };

  // handleChange = selectedTags => {
  //   this.setState({ tags: selectedTags });
  // };

  handleAddTag = (newValue, actionMeta) => {
    console.log(actionMeta);
    console.log(newValue);

    var tags = this.state.tags;

    if (actionMeta.action === "remove-value") {
      const tagToRemove = actionMeta.removedValue.value;
      tags = tags.filter(item => item !== tagToRemove);
    } else if (actionMeta.action === "clear") {
      tags = [];
    } else if (actionMeta.action === "create-option") {
      const tag = newValue[newValue.length - 1].value;
      tags.push(tag);
    }

    this.setState({ tags: tags });
  };

  render() {
    //Calling here because activeUser does not update in componentDidMount
    // this.fetchGroupData();

    return (
      <div className="App upload-page bg-reg">
        <Header {...this.props} />
        <div className="page-wrapper">
          <div className="wrap center">
            <h1 className="page-title-sm uppercase">Poem and Title Details</h1>
            {/* upload div */}
            {/* <FontAwesomeIcon icon="check-square" /> */}
            <div className="upload-image">
              {!this.state.selectedImage ? (
                <div className="chosen-image"></div>
              ) : (
                <img src={`${this.state.selectedImage}`} />
              )}
              <div className="file-picker">
                <ImagePicker
                  extensions={["jpg", "jpeg", "png"]}
                  dims={{
                    minWidth: 100,
                    maxWidth: 5000,
                    minHeight: 100,
                    maxHeight: 5000
                  }}
                  onChange={this.handleImageChange}
                  onError={errMsg => toast.error(errMsg)}
                >
                  <button className="button small">Upload Cover Image</button>
                </ImagePicker>
              </div>
            </div>

            <AvForm
              onValidSubmit={this.handleValidSubmit}
              className="form-fields"
            >
              <AvGroup className="form-field">
                <AvInput
                  onChange={this.handleTextChange}
                  name="name"
                  value={this.state.name}
                  className="form-input"
                  type="text"
                  placeholder="Title"
                  required
                ></AvInput>
                <AvFeedback>Please enter the title</AvFeedback>
              </AvGroup>

              <AvGroup className="form-field">
                <AvField
                  onChange={this.handleTextChange}
                  name="poet"
                  value={this.state.poet}
                  type="text"
                  placeholder="Poet"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter the Poet's name"
                    },
                    pattern: {
                      value: "[A-Za-z]",
                      errorMessage: "The name can only have letters"
                    }
                  }}
                ></AvField>
              </AvGroup>

              <AvGroup className="form-field">
                <AvField
                  onChange={this.handleTextChange}
                  name="pubYear"
                  value={this.state.pubYear}
                  type="text"
                  placeholder="Year of Publication"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Please enter the year of publication"
                    },
                    pattern: {
                      value: "[0-9]",
                      errorMessage: "The year can only have number"
                    },
                    minLength: {
                      value: 4,
                      errorMessage: "The year must have 4 characters"
                    },
                    maxLength: {
                      value: 4,
                      errorMessage: "The year must have 4 characters"
                    }
                  }}
                ></AvField>
              </AvGroup>

              <AvGroup className="form-field">
                <ReactQuill
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ["bold", "italic", "underline"],
                      [{ align: [] }]
                    ]
                  }}
                  style={{
                    textAlign: "left",
                    height: "300px",
                    marginBottom: "20px"
                  }}
                  value={this.state.lyricsHTML}
                  onChange={this.handlePoemChange}
                  theme="snow"
                  ref={this.quillRef}
                />
                {/* <Input
                  onChange={this.handleTextChange}
                  name="lyrics"
                  value={this.state.lyrics}
                  type="textarea"
                  placeholder="Text of Poem"
                ></Input> */}
              </AvGroup>

              <AvGroup className="form-field">
                {/* <WithContext
                  tags={tags}
                  suggestions={suggestions}
                  handleDelete={this.handleTagDelete}
                  handleAddition={this.handleTagAddition}
                  handleDrag={this.handleTagDrag}
                  delimiters={delimiters}
                /> */}
              </AvGroup>
              <br></br>
              <br></br>
              <br></br>
              <AvGroup className="form-field">
                <CreatableSelect
                  isMulti
                  onChange={this.handleAddTag}
                  options={this.state.tags}
                  placeholder="Add a tag"
                />
              </AvGroup>
              <AvGroup className="form-field">
                <button onClick={this.toggleModal} className="button small">
                  Add to Group
                </button>
              </AvGroup>

              <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
                <ModalHeader toggle={this.toggleModal}>
                  Add Recordings to Group
                </ModalHeader>
                <ModalBody>
                  <p>
                    Groups are a private collection of recordings. Don't see
                    your group? Ask the group creator to add you
                  </p>
                  <hr />
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    options={this.state.groupList}
                    onChange={this.handleSelectChange}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={this.toggleModal}>
                    Done
                  </Button>
                </ModalFooter>
              </Modal>

              <FormGroup check className="form-field">
                <Label check>
                  <Input
                    type="checkbox"
                    value={this.state.isPrivate}
                    onChange={this.handleCheckbox}
                  />
                  This upload is private
                </Label>
              </FormGroup>

              {this.state.showSuccess === true && (
                <Alert color="success">
                  Congrats- your recording has been uploaded! You can view it{" "}
                  <Link to={`/recitations/${this.state.res.recitation_uuid}`}>
                    here
                  </Link>
                </Alert>
              )}
              {this.state.showSuccess === true && (
                <Alert color="success">
                  To upload a new recording, please click here{" "}
                  <Link to={"/uploadrecording"}>upload new recording</Link>
                </Alert>
              )}
              <div className="form-action">
                <button className="button primary">Submit</button>
              </div>
            </AvForm>
          </div>
        </div>
      </div>
    );
  }
}

UploadForm.propTypes = {
  history: PropTypes.object,
  apiHost: PropTypes.string,
  activeUser: PropTypes.object,
  location: PropTypes.object
};

export default UploadForm;
