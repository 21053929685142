import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import logo from "./../../static/images/logo-white.png";

class UploadItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const handleImageError = e => {
      e.target.src = logo;
    };

    const recitation = this.props.recitation;
    const image = recitation.cover ? recitation.cover : logo;

    return (
      <li className="content-list-item reading-list-item">
        <div className="item-image">
          <img
            src={image}
            alt={recitation.poem.name}
            onError={handleImageError}
          ></img>
        </div>
        <div className="item-info">
          <h5>
            <Link to={`/recitations/${recitation.recitation_uuid}`}>
              {recitation.poem.name}
            </Link>
          </h5>
          <h5></h5>
          <p className="p-0 m-0 featured-poet"></p>
          <p>
            <span>By</span>
            <Link
              to={"/user/" + recitation.user?.user_uuid}
              className="featured-poet"
            >
              {recitation.user?.first_name} {recitation.user?.last_name}
            </Link>
          </p>
          <p>
            <Link
              to={`/recitations/${recitation?.recitation_uuid}`}
              className="featured-poet"
            >
              {recitation.poem?.poet}
            </Link>
          </p>
        </div>
        <div className="item-cta">
          <span>{recitation.duration}</span>
          {this.editable ? <button>edit</button> : <span></span>}
        </div>
      </li>
    );
  }
}

export default UploadItem;
