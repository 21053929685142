import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import logo from "./../../static/images/logo-white.png";

class RecentUploadItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const handleImageError = e => {
      e.target.src = logo;
    };

    const item = this.props.item;

    return (
      <div className="content-list-item">
        <div className="item-image">
          <img
            src={item.cover}
            alt={item.poem.name}
            onError={handleImageError}
          ></img>
        </div>
        <div className="item-info">
          <h5>
            <Link to={`/recitations/${item.recitation_uuid}`}>
              {item.poem.name}
            </Link>
          </h5>
          <h5></h5>
          <p className="p-0 m-0 featured-poet"></p>
          <p>
            <b>By </b>
            <Link
              to={"/user/" + item.user?.user_uuid}
              className="featured-poet"
            >
              {item.user?.first_name} {item.user?.last_name}
            </Link>
          </p>
          <p>{item.poem?.poet}</p>
        </div>
        <div className="item-cta">
          <span>{item.duration}</span>
        </div>
      </div>
    );
  }
}

export default RecentUploadItem;
