import React, { Component } from "react";
import Header from "../../Headers/Header.js";

export default class FAQ extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="App">
        <Header {...this.props} />
        <div className="interior-page page-wrapper">
          <div className="wrap">
            <h1 className="page-title">Frequently Asked Questions [FAQs]</h1>
            <div className="faq-group">
              <h6>When did this project originate?</h6>
              <p>
                This project was born in 2013 at New York University through an
                interdisciplinary collaboration between Literature and Computer
                Science students.
              </p>
              <h6>What inspired this idea?</h6>
              <p>
                Some people find poetry to be inaccessible or difficult. Yet
                those same individuals often feel more connected to poems when
                they hear them uttered aloud rather than read as written or
                printed language. In response to this, Recited Verse was born as
                a platform for experiencing the oral and aural pleasures of
                poetry. RV users may either listen to a vast range of recorded
                verse or personally record poems through our digital software.
              </p>
              <h6>Why is this project called “Recited Verse”?</h6>
              <p>
                In the context of poetry, the art of recitation means to speak
                verse aloud from one's memory. On one level, user-generated RV
                content exists and can be accessed through digital memory. On
                another, Recited Verse draws on the ancient roots of poetry as
                an art form that was spoken and/or performed. It also promotes
                the experience of listening to poems through repetition –
                whether one is at home or on a commute – because of the option
                of creating lists of favorite recordings or playlists based on
                theme, mood, period, and more. By virtue of the nature of
                repetition, then, Recited Verse helps to aid the memory and, in
                turn, foster the art of recitation.
              </p>
            </div>
            <div className="faq-group">
              <h5>GENERAL GUIDELINES / TERMS OF USE</h5>
              <h6>
                Are there any specific RV rules for submitting a recording of a
                poem?
              </h6>
              <p>
                Yes. At Recited Verse, we have four golden rules for submitting
                recordings of poems:
              </p>
              <p>
                1. When adding a recording to the archive, you authorize for the
                recording to be circulated within and to be used by the entire
                online community.
              </p>
              <p>
                2. Each recording – whether created through our software or
                uploaded – must begin with the title and author of the poem.
              </p>
              <p>
                One must begin the recording by stating, for example, ‘“Because
                I Could Not Stop for Death” by Emily Dickinson’ before reading
                the poem’s first line.
              </p>
              <p>
                NOTE: If a poem does not have a title, we encourage using its
                first line.
              </p>
              <p>
                3. The exclusive medium for all of our recordings is the human
                voice. We thus discourage the use of any musical accompaniment –
                or any other enhancing touches.
              </p>
              <p>
                Any recordings that violate these conditions may be promptly
                removed from our system.
              </p>

              <h6>
                How do I ensure that I share a high-quality recording of a poem?
              </h6>
              <p>
                RV allows you the option to upload an audio file or to use our
                own software to create your recording. If you prefer, other free
                recording apps exist that will allow you to create mp3 or other
                audio files.
              </p>
              <h6>
                Is there any limit to the number of recordings allowed per poem?
              </h6>
              <p>
                No. Recited Verse resists the idea of offering a single
                authoritative audio recording of any poem – even if that
                recording was produced by its author. Instead, we intentionally
                welcome as many recordings of a given poem as possible. In doing
                so, we invite an array of readings and interpretations: we
                welcome the range of voices, tones, cadences, and energies that
                breathes life into any given poetic text. Recited Verse was
                intended to be an ever-growing hub of recordings for each and
                every poem.
              </p>
              <h6>
                If Recited Verse resists the idea of a single authoritative
                audio recording of a poem, then why does it offer a “like”
                feature to single out a top recording for any given poem?
              </h6>
              <p>
                Yes, Recited Verse offers a “liking” feature that singles out a
                “top recording” based on the number of likes that an individual
                recording has received in relation to other recordings of the
                same poem. Such a distinction is never permanent, as any new
                recording of a poem can surpass any other based on user likes.
                We believe that this feature allows users not only the chance to
                discover what recordings are receiving the most attention but
                also the ability to compare the variety of ways a poem can be
                read.
              </p>
              <p>
                The Recited Verse “liking” feature exists for other reasons, as
                well. This includes the ability for users to create lists of
                favorite recordings, which can be turned easily into playlists
                for continuous listening. It also allows the reader to know that
                other users are receiving the recording positively.
              </p>
              <h6>
                Can I include music or any other background effects in my
                recordings?
              </h6>
              <p>
                No. We only accept poems read aloud with the human voice –
                instead of the printed page or the screen – as the optimal and
                only medium.
              </p>
              <h6>
                Can I share video files of my personal readings of poems onto my
                profile and to the RV community?
              </h6>
              <p>No. Recited Verse is strictly an audio tool.</p>
              <h6>
                I am a poet who wishes to share my original and non-published
                work on Recited Verse. Is this possible?
              </h6>
              <p>
                Yes! We encourage this kind of sharing. When you share a
                recording of your work on Recited Verse, all of your followers
                will receive a notification. You may keep the work on RV as long
                as desired or remove it from your profile at any time.
              </p>
              <p>
                By sharing original poetry on Recited Verse, you certify to us
                that you have not published the material elsewhere and are,
                therefore, not violating any copyright. Moreover, should you
                wish to have the work published elsewhere in the future, you
                must either remove the work from the RV archive or negotiate
                with your publisher to keep the work on our website.
              </p>
            </div>
            <div className="faq-group">
              <h5>COST / FEES:</h5>
              <h6>How much does it cost to use Recited Verse?</h6>
              <p>
                Use of Recited Verse is entirely free. In exchange, we ask that
                our BETA site users contribute as many readings of poems as
                possible.{" "}
              </p>
            </div>
            <div className="faq-group">
              <h5>INAPPROPRIATE CONTENT</h5>
              <h6>How do I report inappropriate content on the site?</h6>
              <p>
                We have implemented a community-flagging system on our site. A
                flag icon can be found either beside a poem’s title or beside
                any user comment. By pressing on this icon, users can report
                inappropriate content. Flagging any content on our site – a
                recording, a comment, a photo – will immediately send a
                notification to our team for speedy review.
              </p>
            </div>
            <div className="faq-group">
              <h5>THE FUTURE OF RECITED VERSE</h5>
              <h6>What happens after the BETA site testing period?</h6>
              <p>
                Recited Verse will continue to improve its website by responding
                to user feedback. The future of the site will depend entirely on
                the response of its BETA users.
              </p>
            </div>
            <div className="faq-group">
              <h5>SUPPORTING US</h5>
              <h6>How does RV support itself?</h6>
              <p>
                In the past few years, super intelligent undergraduate and
                graduate students at both New York University and the University
                of San Francisco have made the development of Recited Verse
                possible. We are currently looking for donations to be able to
                expand our team of developers. If you are interested in donating
                to Recited Verse, please send a message to
                hello@recitedverse.com
              </p>
            </div>
            <div className="faq-group">
              <h5>VOLUNTEER / INTERNSHIP OPPORTUNITIES</h5>
              <h6>How can I join the RV team as a volunteer or intern?</h6>
              <p>
                We are currently seeking all poetry enthusiasts as volunteers to
                help grow Recited Verse. We welcome anyone, including students,
                instructors, and scholars, to help us with expanding our
                archive, recruitment, social media campaigns, and spearheading
                local events.
              </p>
              <p>
                If interested in any position, please email us at
                hello@recitedverse.com with a resume/CV and a cover letter. Use
                the subject line “Volunteer / Intern Application.”
              </p>
            </div>
            <div className="faq-group">
              <h5>RECITED VERSE & THE CLASSROOM</h5>
              <h6>
                I teach poetry and would like to know how to integrate RV into
                my classroom.
              </h6>
              <p>
                Recited Verse offers an archive of recordings from which you can
                use recordings in the classroom. Instructors can play several
                recordings and have students compare them.
              </p>
              <p>
                Instructors can also encourage students to create their own
                profiles, contribute their own recordings of poems, and follow
                or generate personal playlists for use in the classroom or on
                the go.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
