import React, { Component } from "react";
import CommentList from "./CommentList";

class Comments extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="group-comments">
        <h4>Comments</h4>
        <CommentList {...this.props} />
      </div>
    );
  }
}

export default Comments;
