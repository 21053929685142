import React, { Component } from "react";
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import ProfileInfo from "./ProfileInfo";
// import ProfileDetails from './ProfileDetails';
// import ProfileFavorites from './ProfileFavorites';
import ProfileRecitations from "./ProfileRecitations";
import ProfilePlaylists from "./ProfilePlaylists";
import ProfileLikesRecitations from "./ProfileLikesRecitations";
import ProfileGroups from "./ProfileGroups";
import ProfileLikes from "./LikesPlaylistsList";

class ProfileMainContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "0"
    };

    this.tabs = ["Uploads", "Likes", "Groups", "Playlists"];
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab)
      this.setState({ activeTab: tab.toString() });
  };

  render() {
    return (
      <div>
        <div className="profile-banner-wrapper">
          {this.props.user.background === "" ? ( // display default
            <div
              style={{
                backgroundColor: this.props.user.color_header,
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            ></div>
          ) : (
            // else image
            <img
              className="profile-background-image"
              src={this.props.user.background}
            />
          )}
        </div>
        <div className="wrap">
          <ProfileInfo {...this.props} getApiData={this.props.getApiData} />
          <div className="tab-control">
            <Nav tabs>
              {this.tabs.map((item, i) => (
                <NavItem key={i}>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === i.toString()
                    })}
                    onClick={() => {
                      this.toggleTab(i);
                    }}
                  >
                    {item}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </div>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="0">
              <ProfileRecitations {...this.props} />
            </TabPane>
            <TabPane tabId="1">
              <ProfileLikesRecitations {...this.props} />
            </TabPane>
            <TabPane tabId="2">
              <ProfileGroups {...this.props} />
            </TabPane>
            <TabPane tabId="3">
              <ProfilePlaylists {...this.props} />
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

export default ProfileMainContent;
