import React, { Component } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Container,
  FormFeedback,
  CardImg
} from "reactstrap";
import axios from "axios";
import "../Login.css";

class RegisterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      dup_password: "",
      first_name_valid: false,
      last_name_valid: false,
      email_valid: false,
      password_valid: false,
      dup_password_valid: false,
      formError: false,
      formErrorText: "",
      authSuccess: false,
      authSuccessText: "User Created. Login to continue"
    };
    this.initializeForm = this.initializeForm.bind(this);
  }

  initializeForm() {
    this.setState({
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      dup_password: "",
      first_name_valid: false,
      last_name_valid: false,
      email_valid: false,
      password_valid: false,
      dup_password_valid: false,
      formError: false,
      formErrorText: "",
      authSuccess: false,
      authSuccessText: "User Created. Login to continue"
    });
    this.props.toggle("1");
  }

  validateForm() {
    if (
      this.state.first_name.length > 0 &&
      this.state.last_name.length > 0 &&
      this.state.email.length > 0 &&
      this.state.password.length > 0
    ) {
      return true;
    } else {
      this.setState({
        formErrorText: "Fields cannot be empty",
        formError: true
      });
      return false;
    }
  }

  handleAuthResponse(response) {
    if (response.id === undefined) {
      this.setState({
        formError: true,
        formErrorText: response.email
      });
    } else {
      this.setState({
        authSuccess: true
      });
      setTimeout(this.initializeForm, 2000);
    }
  }

  handlePasswordCheck = event => {
    if (this.state.password !== this.state.dup_password) {
      this.setState({
        formErrorText: "Passwords do not match",
        formError: true
      });
      return false;
    }
    return true;
  };

  handleChange = event => {
    const isValid = event.target.value.length > 0;
    this.setState({
      [event.target.name]: event.target.value,
      [event.target.name + "_valid"]: isValid
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const postUrl = this.props.apiHost + "users/";
    if (this.validateForm() && this.handlePasswordCheck()) {
      axios
        .post(postUrl, this.state)
        .then(response => {
          this.handleAuthResponse(response.data);
        })
        .catch(function(error) {
          console.log(error.response);
        });
    }
  };

  render() {
    const formError = this.state.formError;
    const authSucess = this.state.authSuccess;
    return (
      <Container className="p-4 m-0">
        <Row className="justify-content-center">
          <Col className="p-0">
            <Form onSubmit={this.handleSubmit}>
              <FormFeedback className={formError ? "show" : ""} tooltip>
                {this.state.formErrorText}
              </FormFeedback>
              <FormFeedback valid className={authSucess ? "show" : ""} tooltip>
                {this.state.authSuccessText}
              </FormFeedback>
              <Row form>
                <Col md={6}>
                  <FormGroup className="text-left">
                    <Input
                      className="form-input"
                      autoFocus
                      id="first_name"
                      type="input"
                      name="first_name"
                      valid={this.state.first_name_valid}
                      value={this.state.first_name}
                      onChange={this.handleChange}
                      placeholder="First Name"
                    />
                    <FormFeedback>Email cannot be empty</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="text-left">
                    <Input
                      className="form-input"
                      autoFocus
                      id="last_name"
                      type="input"
                      name="last_name"
                      valid={this.state.last_name_valid}
                      value={this.state.last_name}
                      onChange={this.handleChange}
                      placeholder="Last Name"
                    />
                    <FormFeedback>Email cannot be empty</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup className="text-left">
                <Input
                  className="form-input half-width"
                  autoFocus
                  id="user_email"
                  type="email"
                  name="email"
                  valid={this.state.email_valid}
                  value={this.state.email}
                  onChange={this.handleChange}
                  placeholder="Email"
                />
                <FormFeedback>Email cannot be empty</FormFeedback>
              </FormGroup>
              <FormGroup className="text-left">
                <Input
                  className="form-input half-width"
                  id="user_password"
                  type="password"
                  name="password"
                  valid={this.state.password_valid}
                  value={this.state.password}
                  onChange={this.handleChange}
                  placeholder="Password"
                />
                <FormFeedback>Password cannot be empty</FormFeedback>
              </FormGroup>
              <FormGroup className="text-left">
                <Input
                  className="form-input half-width"
                  id="dup_password"
                  type="password"
                  name="dup_password"
                  valid={this.state.dup_password_valid}
                  value={this.state.dup_password}
                  onChange={this.handleChange}
                  placeholder="Confirm Password"
                />
                <FormFeedback>Password cannot be empty</FormFeedback>
                <Button className="signup-button" type="submit">
                  SIGN UP
                </Button>
                <p className="small-text">
                  By clicking "Sign Up" you agree to our Terms and Conditions
                  and Privacy Policy.
                </p>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default RegisterForm;
