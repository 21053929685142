import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import logo from "./../../static/images/logo-white.png";

class RecitationItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  toggleModalEdit(recording) {
    this.props.toggleModalEdit(recording);
  }

  toggleModalDelete(recording) {
    this.props.toggleModalDelete(recording);
  }

  render() {
    const handleImageError = e => {
      e.target.src = logo;
    };

    const recitation = this.props.recitation;
    const editable = this.props.editable;

    return (
      <li className="content-list-item reading-list-item">
        <div className="item-image">
          <img
            src={recitation.cover}
            alt={recitation.poem.name}
            onError={handleImageError}
          ></img>
        </div>
        <div className="item-info">
          <h5>
            <Link to={`/recitations/${recitation.recitation_uuid}`}>
              {recitation.poem.name}
            </Link>
          </h5>
          <h5></h5>
          <p className="p-0 m-0 featured-poet"></p>
          <p>
            <span>By</span>
            <Link
              to={"/user/" + recitation.user?.user_uuid}
              className="featured-poet"
            >
              {recitation.user?.first_name} {recitation.user?.last_name}
            </Link>
          </p>
          <p>
            <Link
              to={`/recitations/${recitation?.recitation_uuid}`}
              className="featured-poet"
            >
              {recitation.poem?.poet}
            </Link>
          </p>
        </div>
        <div className="item-cta">
          {/* <button class="dropdown-item" type="button">
                  </button> */}
          <span>{recitation.duration}</span>
          {editable ? (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
              <DropdownToggle caret className="edit-button">
                <FontAwesomeIcon icon={faEllipsisH} className="mr-2" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => this.toggleModalEdit(recitation)}>
                  Edit
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.toggleModalDelete(recitation)}
                >
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          ) : (
            <span></span>
          )}
        </div>
      </li>
    );
  }
}

export default RecitationItem;
