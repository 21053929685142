import React from "react";
import axios from "axios";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row
} from "reactstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import profileImage from "../../static/images/profile.png";

class CommentList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: 1,
      is_reply: false,
      comment: "",
      main_comment: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.postComment = this.postComment.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addReply = this.addReply.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.postComment();
  }

  addReply(comment) {
    this.setState({
      is_reply: true,
      comment: "@" + comment.user.first_name,
      main_comment: comment.id
    });
  }

  handleChange(event) {
    this.setState({ comment: event.target.value });
  }

  postComment() {
    const url =
      this.props.apiHost +
      "groups/" +
      this.props.groupData.group_uuid +
      "/comments/";

    let headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };
    var bodyFormData = new FormData();
    bodyFormData.set("user", this.state.user);
    bodyFormData.set("group", this.props.groupData.id);
    if (this.state.is_reply) {
      bodyFormData.set("main_comment", this.state.main_comment);
      bodyFormData.set("is_reply", this.state.is_reply);
    }

    bodyFormData.set("text", this.state.comment);

    axios
      .post(url, bodyFormData, {
        headers: headerData
      })
      .then(response => {
        if (response.status === 201) {
          window.location.reload();
        }
      })
      .catch(error => {
        toast.error("Could not post your comment!");
        // self.setState({
        // 	isLoaded:true,
        // 	error
        // })
      });
  }

  calculateDate(date) {
    const diff = Date.now() - date;
    const minute = 1000 * 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;
    const month = week * 30;
    const year = month * 12;
    let res = "";
    if (Math.round(diff / year) > 0) {
      res = Math.round(diff / year) + "y";
    } else if (Math.round(diff / month) > 0) {
      res = Math.round(diff / month) + "mo";
    } else if (Math.round(diff / week) > 0) {
      res = Math.round(diff / week) + "w";
    } else if (Math.round(diff / day) > 0) {
      res = Math.round(diff / day) + "d";
    } else if (Math.round(diff / hour) > 0) {
      res = Math.round(diff / hour) + "h";
    } else if (Math.round(diff / minute) > 0) {
      res = Math.round(diff / minute) + "m";
    } else {
      res = "1m";
    }
    return res;
  }

  renderPoemComments(groupComments) {
    const orderedComments = [];
    for (var i = 0; i < groupComments.length; i++) {
      if (!groupComments[i].is_reply) {
        orderedComments.push(groupComments[i]);
      } else {
        orderedComments.splice(
          groupComments[i].main_comment,
          0,
          groupComments[i]
        );
      }
    }
    let list;
    if (groupComments !== undefined && groupComments.length > 0) {
      list = (
        <ul className="comment-list">
          {orderedComments.map(poemComment => {
            const user_images = poemComment.user.avatar;

            if (!poemComment.is_reply) {
              // not reply
              return (
                <li key={poemComment.id} className="pt-2">
                  <div className="commenter-image">
                    {user_images === "" ? ( // display default
                      <img
                        src={profileImage}
                        alt="user-profile-img"
                        className="p-0"
                      />
                    ) : (
                      // display profile img
                      <img
                        src={user_images}
                        alt="user-profile-img"
                        className="p-0"
                      />
                    )}
                  </div>
                  <div className="comment-text pl-2">
                    <Link to={`/user/${poemComment.user.user_uuid}`}>
                      <span className="comment-text-bold">
                        {poemComment.user.first_name}
                      </span>
                      <span className="comment-text-bold pl-1">
                        {poemComment.user.last_name}
                      </span>
                    </Link>
                    <p>{poemComment.text}</p>
                    {/* <span className="sub-text">Like</span> */}
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => this.addReply(poemComment)}
                    >
                      <span className="sub-text pl-2">Reply</span>
                    </a>
                    <span className="comment-date sub-text pl-2">
                      {this.calculateDate(
                        new Date(poemComment.created_at).getTime()
                      )}
                    </span>
                  </div>
                </li>
              );
            } else {
              // reply
              return (
                <li key={poemComment.id} className="pl-5 pt-2">
                  <div className="commenter-image">
                    {user_images === "" ? ( // display default
                      <img
                        src={profileImage}
                        alt="user-profile-img"
                        className="p-0"
                      />
                    ) : (
                      // display profile img
                      <img
                        src={user_images}
                        alt="user-profile-img"
                        className="p-0"
                      />
                    )}
                  </div>
                  <div className="comment-text pl-2">
                    <Link to={`/user/${poemComment.user.user_uuid}`}>
                      <span className="comment-text-bold">
                        {poemComment.user.first_name}
                      </span>
                      <span className="comment-text-bold pl-1">
                        {poemComment.user.last_name}
                      </span>
                    </Link>
                    <p>{poemComment.text}</p>
                    {/* <span className="sub-text">Like</span> */}
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => this.addReply(poemComment)}
                    >
                      <span className="sub-text pl-2">Reply</span>
                    </a>
                    <span className="comment-date sub-text pl-2">
                      {this.calculateDate(
                        new Date(poemComment.created_at).getTime()
                      )}
                    </span>
                  </div>
                </li>
              );
            }
          })}
        </ul>
      );
    } else {
      list = <p>No Group Comments to show</p>;
    }
    return list;
  }

  renderPoemForm() {
    return (
      <div className="comment-form">
        <Form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Input
              onChange={this.handleChange}
              type="textarea"
              name="text"
              placeholder="Write something here..."
              value={this.state.comment}
            />
            <Button
              className="poem-comment-submit button small primary"
              type="submit"
            >
              Send
            </Button>
          </FormGroup>
        </Form>
      </div>
    );
  }

  render() {
    const groupForm = this.renderPoemForm();
    const groupComments = this.renderPoemComments(this.props.groupComments);
    return (
      <div>
        {groupForm}
        <div className="comment-section">{groupComments}</div>
      </div>
    );
  }
}

export default CommentList;
