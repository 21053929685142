import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";

export default class ModalPoemDelete extends Component {
  constructor(props) {
    super(props);
  }

  deleteRecording = () => {
    const recitation_uuid = this.props.selectedRecitation.recitation_uuid;
    const deleteUrl =
      this.props.apiHost + "recitations/" + recitation_uuid + "/delete/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    axios
      .get(deleteUrl, { headers: headerData })
      .then(response => {
        toast.success("Your recording has successfully been deleted");
        //redirect to home page
        if (response.status == 200 || response.status == 201) {
          setTimeout(() => {
            window.location.reload(); // @TODO this will wipe playlist
          }, 1000);
        }
        // window.location.replace("https://development.recitedverse.com");
      })
      .catch(err => {
        console.error(err);
        toast.error("There was an error in deleting your recording");
      });
  };

  closeModal = event => {
    event.preventDefault();

    this.props.toggleModalDelete();
  };

  render() {
    return (
      <Modal isOpen={this.props.modalDelete} toggle={this.toggleModalDelete}>
        <ModalHeader toggle={this.toggleModalDelete}>
          Delete Recording
        </ModalHeader>
        <ModalBody>
          <div>
            <h4 className="upload-header">
              Are you sure you want to delete this recording?
            </h4>
            <Button
              style={{ margin: "10px" }}
              color="danger"
              onClick={this.deleteRecording}
            >
              Yes
            </Button>
            <Button
              style={{ margin: "10px" }}
              color="primary"
              outline
              onClick={this.closeModal}
            >
              No
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
