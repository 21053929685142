import React, { Component } from "react";
import Header from "./../Headers/Header.js";
import FAQSearch from "../components/main-content/FAQSearch.js";
import FAQList from "../components/main-content/FAQList.js";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { PropTypes } from "prop-types";

class VerifyEmail extends Component {
  componentDidMount() {
    const postUrl = this.props.apiHost + "verify_email/";

    axios
      .post(postUrl, {
        email: this.props.match.params.email,
        code: this.props.match.params.code
      })
      .then(() => {
        confirmAlert({
          title: "Success",
          message: "Congrats, your email has been confirmed",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                window.location.href = "/";
              }
            }
          ]
        });
      })
      .catch(function() {
        confirmAlert({
          title: "Failed",
          message: "Sorry, your email was unable to be confirmed.",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                //  window.location.href = "/"
              }
            }
          ]
        });
      });
  }
  render() {
    return (
      <div className="App">
        <Header isAuthenticated={false} />
        <FAQSearch />
        <FAQList />
      </div>
    );
  }
}

VerifyEmail.propTypes = {
  apiHost: PropTypes.string,
  match: PropTypes.object
};

export default VerifyEmail;
