import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "./../../static/images/logo-white.png";

class MoreReadingItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const handleImageError = e => {
      e.target.src = logo;
    };

    const item = this.props.item;
    const image = item.cover && item.cover !== "" ? item.cover : logo;

    return (
      <li className="content-list-item reading-list-item">
        <div className="item-image">
          <img src={image} alt={item.poem.name} onError={handleImageError} />
        </div>
        <div className="item-info">
          <h5>
            <div>
              <Link to={`/recitations/${item.recitation_uuid}`}>
                {item.poem.name}
              </Link>
              <p>{item.duration}</p>
            </div>
            <a
              className="play-button"
              style={{ cursor: "pointer" }}
              onClick={() => this.props.playRecording(item)}
            >
              <FontAwesomeIcon icon="caret-right" className="fa-3x" />
            </a>
          </h5>
          <p className="p-0 m-0 featured-poet"></p>
          <p>
            <b>By </b>
            <Link
              to={"/user/" + item.user?.user_uuid}
              className="featured-poet"
            >
              {item.user?.first_name} {item.user?.last_name}
            </Link>
          </p>
          <p>{item.poem?.poet}</p>
        </div>
      </li>
    );
  }
}

export default MoreReadingItem;
