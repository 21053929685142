import React, { Component } from "react";
import { Collapse, CardBody, Card, ListGroupItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class FAQItem extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
    this.question = props.question;
    this.answer = props.answer;
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  render() {
    return (
      <ListGroupItem className="px-0 pb-0 text-left">
        <div
          className="px-5 mb-3 justify-content-between d-flex"
          color="primary"
          onClick={this.toggle}
        >
          <div className="d-inline">{this.question}</div>
          <div className="d-inline">
            <FontAwesomeIcon icon="caret-down" />
          </div>
        </div>
        <Collapse isOpen={this.state.collapse}>
          <Card className="faq-answer px-5">
            <CardBody className="px-0">{this.answer}</CardBody>
          </Card>
        </Collapse>
      </ListGroupItem>
    );
  }
}

export default FAQItem;
