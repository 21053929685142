import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Card, CardText, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import Header from "./../Headers/Header.js";
import "./Search.css";
import logo from "../static/images/logo-white.png";
import profileImage from "../static/images/profile.png";

export default class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchResults: [],
      searchTerm: "",
      searchField: 0,
      loading: true
    };
  }

  componentDidMount() {
    let searchTerm = "";
    // loaded the searchTerm if exists
    if (this.props.location.state && this.props.location.state.searchTerm) {
      searchTerm = this.props.location.state.searchTerm;
    }

    this.getResults(searchTerm);
  }

  async getResults(param) {
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    this.setState({ loading: true });
    const url = this.props.apiHost + "search/";

    let headerData = {
      Authorization: "Token " + activeUser.token,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json"
    };

    var bodyFormData = new FormData();
    bodyFormData.set("keyword", param);

    await axios
      .post(url, bodyFormData, {
        headers: headerData
      })
      .then(response => {
        this.setState({ searchResults: response.data });
      })
      .catch(() => {
        toast.error("Error searching");
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  // pass the search trigger to the header
  handleSearch = search => {
    this.setState({ searchTerm: search.toLowerCase().trim() });
    this.getResults(search);
  };

  handleField = field => {
    this.setState({ searchField: parseInt(field) });
  };

  render() {
    //recitations
    const handleImageError = e => {
      e.target.src = logo;
    };

    //const empty = this.state.searchResults.recitations.length > 0 && this.state.searchResults.userId.length > 0 && this.state.searchResults.poets.length > 0;

    let results = null;
    if (this.state.searchResults.recitations !== undefined) {
      let num =
        this.state.searchField === 0
          ? 6
          : this.state.searchResults.recitations.length;
      results = this.state.searchResults.recitations.map((item, index) => {
        if (index < num) {
          return (
            <li className="search-result-item" key={index}>
              <div className="item-image">
                <img
                  src={item.cover}
                  alt={item.poem.name}
                  onError={handleImageError}
                ></img>
              </div>
              <div className="item-info">
                <h4>
                  <Link to={`/poem/${item.recitation_uuid}`}>
                    <span>{item.poem.name}</span>
                  </Link>
                </h4>
                <h5>
                  <span>Reader:</span>
                  <Link to={`/user/${item.user.user_uuid}`}>
                    {item.user.first_name} {item.user.last_name}
                  </Link>
                </h5>
                <p>
                  <span>Poet:</span>
                  {item.poem.poet}
                </p>
              </div>
            </li>
          );
        }
      });
    }

    //poems
    let poemResults = null;
    if (this.state.searchResults.poems !== undefined) {
      let num =
        this.state.searchField === 0
          ? 6
          : this.state.searchResults.poems.length;
      poemResults = this.state.searchResults.poems.map((item, index) => {
        const textLimit = 150;

        if (index < num) {
          return (
            <Card className="result-card-item" key={index}>
              <CardBody>
                <CardTitle tag="h5">
                  <Link to={`/poem/${item.poem_uuid}`}>
                    <span>{item.name}</span>
                  </Link>
                </CardTitle>
                <CardSubtitle tag="h6" className="mb-2 text-muted">
                  {item.poet}
                </CardSubtitle>
                <CardText>
                  {item.lyrics.length > textLimit
                    ? item.lyrics.substring(0, textLimit - 3) + "..."
                    : item.lyrics}
                </CardText>
              </CardBody>
            </Card>
          );
        }
      });
    }
    //userId
    const userID = this.state.searchResults.userId;
    let userResult = null;
    if (userID !== undefined) {
      let num = this.state.searchField === 0 ? 6 : userID.length;
      userResult = userID.map((item, index) => {
        if (index < num) {
          const image = item.avatar !== "" ? item.avatar : profileImage;

          return (
            <Card
              className="result-card-item search-result-profile"
              key={index}
            >
              <CardBody>
                <div className="profile-image">
                  <img
                    src={image}
                    alt="user-profile-img"
                    className="p-0"
                    onError={handleImageError}
                  />
                </div>
                <Link to={`/user/${item.user_uuid}`}>
                  <span>{item.first_name + " " + item.last_name}</span>
                </Link>
                <p>{item.desc}</p>
              </CardBody>
            </Card>
          );
        }
      });
    }

    //poems
    let poems = null;
    if (this.state.searchResults.poems !== undefined) {
      poems = this.state.searchResults.poems.map((item, index) => {
        if (index < 10) {
          return (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>
                <Link to={`/poem/${item.poem_uuid}`}>
                  <span>{item.name}</span>
                </Link>
              </td>
              <td>{item.poet}</td>
            </tr>
          );
        }
      });
    }

    //poemLyrics
    let poemLyrics = null;
    if (this.state.searchResults.poemLyrics !== undefined) {
      poemLyrics = this.state.searchResults.poemLyrics.map((item, index) => {
        if (index < 10) {
          return (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>
                <Link to={`/poem/${item.poem_uuid}`}>
                  <span>{item.name}</span>
                </Link>
              </td>
              <td>{item.poet}</td>
            </tr>
          );
        }
      });
    }
    //recitations
    const recitations = this.state.searchResults.recitations;
    let rows = null;
    if (recitations !== undefined) {
      rows = recitations.map((item, index) => {
        if (index < 10) {
          return (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>
                <Link to={`/poem/${item.recitation_uuid}`}>
                  <span>{item.poem.name}</span>
                </Link>
              </td>
              <td>
                <Link to={`/user/${item.user.user_uuid}`}>
                  <span>
                    {item.user.first_name} {item.user.last_name}
                  </span>
                </Link>
              </td>
              <td>{item.poem.poet}</td>
            </tr>
          );
        }
      });
    }

    //userId
    const user = this.state.searchResults.userId;
    let users = null;
    if (user !== undefined) {
      users = user.map((item, index) => {
        if (index < 10) {
          return (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>
                <Link to={`/user/${item.user_uuid}`}>
                  <span>{item.first_name + " " + item.last_name}</span>
                </Link>
              </td>
            </tr>
          );
        }
      });
    }
    return (
      <div className="App">
        <Header {...this.props} onSearch={this.handleSearch} />
        <div className="page-wrapper">
          <div className="wrap center">
            {this.state.loading ? (
              <h2 className="page-title">
                Searching {this.state.searchTerm}...
              </h2>
            ) : (
              <div>
                {this.state.searchResults.length <= 0 ||
                this.state.searchResults === null ? (
                  <h2 className="page-title">No results</h2>
                ) : (
                  <h2 className="page-title">
                    Your search results for: {this.state.searchTerm}
                  </h2>
                )}

                <div>
                  {this.state.searchField !== 0 ? (
                    <a href="#" onClick={() => this.handleField(0)}>
                      Back
                    </a>
                  ) : (
                    <span></span>
                  )}
                </div>
                {results !== null &&
                results.length > 0 &&
                (this.state.searchField === 0 ||
                  this.state.searchField === 1) ? (
                  <div className="search-result-section">
                    <h3 className="search-subtitle">
                      Readings{" "}
                      {this.state.searchField === 0 && results.length > 6 ? (
                        <a href="#" onClick={() => this.handleField(1)}>
                          See All
                        </a>
                      ) : (
                        <span></span>
                      )}
                    </h3>
                    <div className="search-result-list">
                      <ul>{results}</ul>
                    </div>
                  </div>
                ) : null}

                {poemResults !== null &&
                poemResults.length > 0 &&
                (this.state.searchField === 0 ||
                  this.state.searchField === 2) ? (
                  <div className="search-result-section">
                    <h3 className="search-subtitle">
                      Poems
                      {this.state.searchField === 0 &&
                      poemResults.length > 6 ? (
                        <a href="#" onClick={() => this.handleField(2)}>
                          See All
                        </a>
                      ) : (
                        <span></span>
                      )}
                    </h3>
                    <div className="search-result-list">
                      <div className="result-card-list">{poemResults}</div>
                    </div>
                  </div>
                ) : null}

                {userResult !== null &&
                userResult.length > 0 &&
                (this.state.searchField === 0 ||
                  this.state.searchField === 3) ? (
                  <div className="search-result-section">
                    <h3 className="search-subtitle">
                      Profiles
                      {this.state.searchField === 0 && userResult.length > 6 ? (
                        <a href="#" onClick={() => this.handleField(3)}>
                          See All
                        </a>
                      ) : (
                        <span></span>
                      )}
                    </h3>
                    <div className="search-result-list">
                      <div className="result-card-list">{userResult}</div>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Search.propTypes = {
  location: PropTypes.object,
  apiHost: PropTypes.string,
  activeUser: PropTypes.object
};
