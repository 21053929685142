import React from "react";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";

function PdfModal({ fileUrl, isOpen, toggle, fileName }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      className="custom-modal-dialog"
    >
      <ModalHeader toggle={toggle}>{fileName}</ModalHeader>
      <ModalBody>
        <iframe
          title={fileName}
          src={fileUrl}
          style={{ width: "100%", height: "500px", border: "none" }}
          allowFullScreen
        />
      </ModalBody>
    </Modal>
  );
}

export default PdfModal;
