import React, { Component } from "react";
import { Card, CardImg, CardFooter } from "reactstrap";

class RecommendedPoemItem extends Component {
  render() {
    const handleImageError = e => {
      e.target.src = this.props.image;
    };

    const image =
      this.props.item.cover && this.props.item.cover !== ""
        ? this.props.item.cover
        : this.props.image;

    return (
      <div className="d-inline featured-recordings-item">
        <Card className="border-0 shadow-none bg-none">
          <CardImg
            className="featured-recording-img card shadow-none"
            src={image}
            alt="Card image cap"
            onError={handleImageError}
          />
          <CardFooter className="p-1 mt-2">
            <h5>
              <a
                href={"/poem/" + this.props.item.poem_uuid}
                className="font-black"
              >
                {this.props.item.name}
              </a>
            </h5>
            <p className="font-weight-light p-0 m-0">{this.props.item.poet}</p>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

export default RecommendedPoemItem;
