import React, { Component } from "react";
import FeaturedItem from "./FeaturedItem";

class FeaturedList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recitationLike: null
    };
  }

  render() {
    const poems = this.props.poems;

    return poems !== undefined && poems.length > 0 ? (
      <ul className="content-list reading-list">
        {poems.map(poem => (
          <FeaturedItem key={poem.id} poem={poem} />
        ))}
      </ul>
    ) : (
      <div className="wrap center">
        <p>
          No recordings exist for this group. Please upload a recording to see
          Recommended Recordings.
        </p>
      </div>
    );
  }
}

export default FeaturedList;
