import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import poemBackground from "./../../static/images/london.jpg";
import poetBackground from "./../../static/images/profile.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PoemInfo from "./PoemInfo.js";
import PoemRecitations from "./PoemRecitations.js";
import Comments from "./Comments.js";
import axios from "axios";
import { toast } from "react-toastify";
import { PropTypes } from "prop-types";
import PoemDetail from "./PoemDetail";
import MoreReading from "./MoreReading";

class PoemMainContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPoem: {},
      poemRecitations: [],
      poemComments: [],
      poemUuid: this.props.match.params.id,
      loading: true,
      currentRecording: null,
      editable: false,
      isStaff: false,
      private: false
    };
    // this.getPoemComments = this.getPoemComments.bind(this);
    // this.flagContent = this.flagContent.bind(this);
  }

  updateEditable = () => {
    const { activeUser } = this.props;
    if (activeUser) {
      this.setState({
        editable: activeUser.is_staff,
        isStaff: activeUser.is_staff
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.activeUser !== prevProps.activeUser) {
      this.updateEditable();
    }
  }

  componentDidMount() {
    this.updateEditable();
    this.getPoemDetails();
  }

  replaceURLsWithLinks = str => {
    if (this.state.currentRecording === null) {
      return;
    }
    const regex = /((http|https):\/\/[^\s]*)(?=<\/)/gi;
    str = str.replace(regex, '<a href="$1">$1</a>');
    this.setState({
      currentRecording: {
        ...this.state.currentRecording,
        poem: { ...this.state.currentRecording.poem, lyrics: str }
      }
    });
  };

  getPoemDetails = () => {
    this.setState({ loading: true });

    const poem_uuid = this.props.match.params.id;
    const getPoemDetailsURL =
      this.props.apiHost + "recitations/" + poem_uuid + "/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(getPoemDetailsURL, {
        headers: headerData
      })
      .then(response => {
        // response.data.poem.lyrics = this.replaceURLsWithLinks(response.data.poem.lyrics);
        if (response.data.user.user_uuid === activeUser.user_uuid) {
          this.setState({ editable: true });
        }

        this.setState({
          currentPoem: response.data.poem,
          currentRecording: response.data
        });
        this.replaceURLsWithLinks(response.data.poem.lyrics);
        this.getPoemComments();
        this.getPoemRecitations();
      })
      .catch(error => {
        if (error.response.status === 401) {
          this.setState({ private: true, loading: false });
          toast.error("This poem is private.");
          return;
        }

        const getPoemDetailsURL =
          this.props.apiHost + "poems/" + poem_uuid + "/recitations/";

        axios
          .get(getPoemDetailsURL, {
            headers: headerData
          })
          .then(response => {
            const chosenRecording = response.data[0];
            if (response.data.length > 0) {
              this.setState({
                currentPoem: chosenRecording.poem,
                currentRecording: chosenRecording
              });
              this.getPoemComments();
              this.getPoemRecitations();
            } else {
              toast.error("Unable to load the selected poem");
            }
          })
          .catch(error => {
            toast.error("Unable to load the selected poem");
          })
          .finally(() => {
            this.setState({ loading: false });
          });
      });
  };
  getPoemRecitations = () => {
    const getPoemRecitationsURL =
      this.props.apiHost +
      "poems/" +
      this.state.currentPoem.poem_uuid +
      "/recitations/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(getPoemRecitationsURL, {
        headers: headerData
      })
      .then(response => {
        this.setState({ poemRecitations: response.data, loading: false });
      })
      .catch(error => {
        toast.error("Unable to load the selected poem");
      });
  };

  getPoemAuthorDetails = () => {
    if (this.state.currentPoem.user !== undefined) {
      return this.state.currentPoem.user;
    } else {
      return {};
    }
  };

  getPoemComments = () => {
    const url =
      this.props.apiHost +
      "recitations/" +
      this.state.currentRecording.recitation_uuid +
      "/comments/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(url, {
        headers: headerData
      })
      .then(response => {
        this.setState({ poemComments: response.data });
      })
      .catch(error => {
        toast.error("We could not fetch the poem comments!");
      });
  };

  updateNumberOfPLays = recording => {
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    const url =
      this.props.apiHost +
      "recitations/" +
      recording.recitation_uuid +
      "/play/";

    axios
      .post(url, new FormData(), {
        headers: headerData
      })
      .then(response => {
        console.log("number of plays increased");
        this.props.audioInstance.play();
      })
      .catch(err => {
        console.log(err);
        toast.error("Error incrementing the number of plays");
      });
  };

  flagContent = () => {
    const url = `mailto:hello@recitedverse.com?subject=Reported%20Content&body=Content%20Reported:${window.location.href}`;

    window.location.href = url;
  };

  playRecording = recording => {
    this.props.accessPlayer(recording);
    this.updateNumberOfPLays(recording);
  };

  renderMoreReading = () => {
    const poemRecitations = this.state.poemRecitations;

    if (poemRecitations && poemRecitations.length > 1) {
      return (
        <MoreReading
          {...this.props}
          items={poemRecitations}
          currentRecording={this.state.currentRecording}
          playRecording={this.playRecording}
        />
      );
    }
  };

  render() {
    console.log(this.state.editable);

    return this.state.loading === true ? (
      <div className="poem-container wrap" style={{ paddingTop: "40px" }}>
        <div className="center">
          <h2>Loading recoding...</h2>
        </div>
      </div>
    ) : this.state.currentRecording ? (
      <div>
        <PoemInfo
          recording={this.state.currentRecording}
          playRecording={this.playRecording}
        />
        <div className="poem-container wrap">
          <div className="poem-content">
            <div className="poem-detail">
              <PoemDetail recording={this.state.currentRecording} />
              <Comments {...this.props} {...this.state} />
            </div>
            <aside className="poem-sidebar">
              <PoemRecitations
                {...this.props}
                editable={this.state.editable}
                isStaff={this.state.isStaff}
                items={this.state.poemRecitations}
                currentPoem={this.state.currentPoem}
                currentRecording={this.state.currentRecording}
              />
              {this.renderMoreReading()}
            </aside>
          </div>
        </div>
      </div>
    ) : this.state.private ? (
      <div className="poem-container wrap" style={{ paddingTop: "40px" }}>
        <div className="center">
          <h2>This poem is private</h2>
        </div>
      </div>
    ) : (
      <div className="poem-container wrap" style={{ paddingTop: "40px" }}>
        <div className="center">
          <h2>This recording has been deleted.</h2>
        </div>
      </div>
    );
  }
}

PoemMainContent.propTypes = {
  activeUser: PropTypes.object,
  accessPlayer: PropTypes.func,
  apiHost: PropTypes.string,
  match: PropTypes.object
};

export default PoemMainContent;
