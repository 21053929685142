import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { PropTypes } from "prop-types";
import PlaylistInfo from "./PlaylistInfo";
import PlaylistList from "./PlaylistList";

class PlaylistMainContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playlist: {},
      user: {},
      playlistItems: [],
      loading: true,
      editable: false
    };
  }

  updateEditable = () => {
    const { activeUser } = this.props;
    if (activeUser) {
      this.setState({ editable: activeUser.is_staff });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.activeUser !== prevProps.activeUser) {
      this.updateEditable();
    }
  }

  componentDidMount() {
    this.updateEditable();
    this.setState({ loading: true });
    const playlist_uuid = this.props.match.params.id;
    this.getPlaylistDetail(playlist_uuid);
  }

  /*
    First fetch the playlist object
    1. use the user id to fetch the user object
    2. use the uuid to fetch the recordings list
   */
  getPlaylistDetail = playlist_uuid => {
    this.setState({ loading: true });
    const url = this.props.apiHost + "playlists/" + playlist_uuid + "/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(url, { headers: headerData })
      .then(response => {
        this.setState({
          playlist: response.data,
          loading: false
        });

        this.getPlaylistUser(response.data.user);
        this.getPlaylistRecordings(playlist_uuid);
      })
      .catch(error => {
        toast.error("There was an error loading your playlist");
      });
  };

  /*
    fetch the author of the current playlist
  */
  getPlaylistUser = user_id => {
    const url = this.props.apiHost + "user_profile/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(url, { headers: headerData })
      .then(response => {
        let userToUpdate = response.data.filter(item => item.id === user_id)[0];
        this.setState({ user: userToUpdate });
      })
      .catch(error => {
        toast.error("There was an error loading your playlist");
      });
  };

  getPlaylistRecordings = playlist_uuid => {
    // get playlist recordings here...
    const url =
      this.props.apiHost + "playlists/" + playlist_uuid + "/recitations/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(url, { headers: headerData })
      .then(response => {
        if (response.status === 200) {
          let dataToUpdate = response.data.map(item => {
            return item.recitation;
          });
          this.setState({ playlistItems: dataToUpdate });
        }
      })
      .catch(() => {
        toast.error("There was an error loading your playlist");
      });
  };

  playPlaylist = () => {
    this.props.accessPlayerList(this.state.playlistItems);
  };

  removeFromList = id => {
    this.setState({
      playlistItems: this.state.playlistItems.filter(
        (recitation, i) => recitation["id"] !== id
      )
    });
  };

  render() {
    if (this.state.playlistItems.length > 0) {
      return (
        <div>
          <PlaylistInfo
            {...this.props}
            {...this.state}
            playPlaylist={this.playPlaylist}
            editable={this.state.editable}
          />
          <PlaylistList
            {...this.props}
            {...this.state}
            playPlaylist={this.playPlaylist}
            editable={this.state.editable}
            removeFromList={this.removeFromList}
          />
        </div>
      );
    } else {
      return (
        <div>
          <PlaylistInfo
            {...this.props}
            {...this.state}
            playPlaylist={this.playPlaylist}
            editable={this.state.editable}
          />
          <div className="playlist-banner">
            <div className="wrap">
              <p className="playlist-info h1">
                Add recitations to your playlist
              </p>
            </div>
          </div>
        </div>
      );
    }
  }
}

PlaylistMainContent.propTypes = {
  accessPlayerList: PropTypes.func
};

export default PlaylistMainContent;
