import React from "react";

class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seconds: 0,
      minutes: 0
    };
    this.tick = this.tick.bind(this); // bind to the component
  }

  tick() {
    // start timer after button is clicked
    this.interval = setInterval(() => {
      if (this.state.seconds === 60) {
        this.setState({
          seconds: 0,
          minutes: this.state.minutes + 1
        });
      }

      this.setState(prevState => ({
        seconds: prevState.seconds + 1
      }));
    }, 1000);
  }

  componentDidMount() {
    this.tick();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <h2>
        {this.state.seconds < 10 ? (
          <div>
            {this.state.minutes}:0{this.state.seconds}
          </div>
        ) : (
          <div>
            {this.state.minutes}:{this.state.seconds}
          </div>
        )}
        <br />
      </h2>
    );
  }
}

export default Timer;
