import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup
} from "reactstrap";
import PlaylistRecitations from "../PlaylistRecitations";
import ModalPlaylistRecitationDelete from "./ModalPlaylistRecitationDelete";

export default class ModalPlaylistEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playlistItems: [],
      modalDelete: false,
      name: ""
    };
  }

  componentDidMount() {
    const selectedPlaylist = this.props.selectedPlaylist;

    if (selectedPlaylist && Object.keys(selectedPlaylist).length > 0) {
      this.getPlaylistRecordings(selectedPlaylist.playlist_uuid);
    }
  }

  getPlaylistRecordings = playlist_uuid => {
    // get playlist recordings here...
    const url =
      this.props.apiHost + "playlists/" + playlist_uuid + "/recitations/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(url, {
        headers: headerData
      })
      .then(response => {
        if (response.status === 200) {
          let dataToUpdate = response.data.map(item => {
            return item.recitation;
          });
          this.setState({ playlistItems: dataToUpdate });
        }
      })
      .catch(() => {
        toast.error("There was an error loading your playlist");
      });
  };

  handlePlaylistNameChange = event => {
    const name = event.target.value;

    this.setState({ name: name });
  };

  submitPlaylistChange = playlistData => {
    if (!this.state.name || this.state.name === "") {
      toast.error("Playlist name is required");
      return;
    }

    // update header
    const playlistUrl =
      this.props.apiHost + "playlists/" + playlistData.playlist_uuid + "/";

    var formData = new FormData();
    // Prevent null submission
    const name = this.state.name;

    formData.append("name", name);

    const header = {
      Authorization: "Token " + this.props.activeUser.token,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json"
    };

    axios
      .put(playlistUrl, formData, {
        headers: header,
        credentials: "same-origin",
        mode: "no-cors"
      })
      .then(res => {
        if (res.status === 200) {
          toast.success("Updated!");
          setTimeout(() => {
            window.location.reload(); // @TODO this will wipe playlist
          }, 1000);
        }
      })
      .catch(err => {
        console.error(err.response);
        toast.error("Could not update playlist information. Please try again.");
      });
  };

  toggleModalEdit = () => {
    this.props.toggleModalEdit();
  };

  toggleModalDelete = recitation => {
    this.setState(prevState => ({
      modalDelete: !prevState.modalDelete,
      selectedRecitation: recitation
    }));
  };

  render() {
    const selectedPlaylist = this.props.selectedPlaylist;
    const recitationId = this.state.selectedRecitation?.recitation_uuid;

    return (
      <div>
        <Modal isOpen={this.props.modalEdit} toggle={this.toggleModalEdit}>
          <ModalHeader toggle={this.toggleModalEdit}>
            Manage Playlist
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <div className="space-sm">
                <Label for="name">Playlist Name</Label>
                <div className="flex">
                  <Input
                    id="name"
                    type="text"
                    defaultValue={selectedPlaylist?.name}
                    onChange={this.handlePlaylistNameChange}
                  />
                  <Button
                    className="button primary left-space-sm"
                    onClick={() => this.submitPlaylistChange(selectedPlaylist)}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </FormGroup>
            <PlaylistRecitations
              recitations={this.state.playlistItems}
              editable={this.props.editable}
              toggleModalDelete={this.toggleModalDelete}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.toggleModalEdit}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <ModalPlaylistRecitationDelete
          {...this.props}
          key={`delete-${recitationId}`}
          selectedPlaylist={selectedPlaylist}
          selectedRecitation={this.state.selectedRecitation}
          modalDelete={this.state.modalDelete}
          toggleModalDelete={this.toggleModalDelete}
        />
      </div>
    );
  }
}
