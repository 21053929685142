import React, { Component } from "react";
import { Row, Col, Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";

class RecitationList extends Component {
  constructor(props) {
    super(props);
  }

  renderRecitations(recitations) {
    let table;
    if (recitations !== undefined && recitations.length > 1) {
      table = (
        <Table className="w-100 recitation-list">
          <tbody className="recitation-list-body">
            {recitations.map(recitation => (
              <tr key={recitation.id}>
                <td className="text-center py-4 align-middle">
                  <div
                    className="recitation-author-profile-img"
                    style={{
                      backgroundImage: `url(${recitation.user.avatar})`
                    }}
                  ></div>
                </td>
                <td className="text-left py-4 align-middle">
                  <Link to={`/user/${recitation.user.user_uuid}`}>
                    <p className="m-0">
                      {recitation.user.first_name} {recitation.user.last_name}
                    </p>
                  </Link>
                </td>
                <td className="align-middle py-4 recitation-list-body-like">
                  <Link to={`/poem/${recitation.recitation_uuid}`}>
                    <FontAwesomeIcon icon="caret-right" className="fa-1-5x" />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    } else {
      table = <p>No Recordings to show</p>;
    }
    return table;
  }

  render() {
    const recitations = this.renderRecitations(this.props.recitations);
    return (
      <Row className="p-0 m-0">
        <Col sm="12" className="p-0"></Col>
      </Row>
    );
  }
}

RecitationList.propTypes = {
  recitations: PropTypes.array
};

export default RecitationList;
