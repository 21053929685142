import React, { Component } from "react";
import { PropTypes } from "prop-types";
import axios from "axios";
import RecitationItem from "./RecitationItem";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ModalRecitationEdit from "./Modals/ModalRecitationEdit";
import ModalRecitationDelete from "./Modals/ModalRecitationDelete";

class RecitationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalEdit: false,
      modalDelete: false,
      selectedRecitation: {}
    };
  }

  // toggle the edit modal
  toggleModalEdit = recording => {
    this.setState(prevState => ({
      modalEdit: !prevState.modalEdit,
      selectedRecitation: recording
    }));
  };

  // toggle the delete modal
  toggleModalDelete = recording => {
    this.setState(prevState => ({
      modalDelete: !prevState.modalDelete,
      selectedRecitation: recording
    }));
  };

  render() {
    const recitations = this.props.recitations;
    const recitationId = this.state.selectedRecitation?.id;
    const editable = this.props.editable || this.props.admin;

    return (
      <div className="tab-container">
        {recitations !== undefined && recitations.length > 0 ? (
          /* display the recitation list if there's any */
          <div>
            <ul className="content-list reading-list">
              {recitations.map(recitation => {
                if (recitation.private === false || editable) {
                  return (
                    <RecitationItem
                      key={recitation.id}
                      recitation={recitation}
                      editable={editable}
                      toggleModalEdit={this.toggleModalEdit}
                      toggleModalDelete={this.toggleModalDelete}
                    />
                  );
                }
              })}
            </ul>
            <ModalRecitationEdit
              {...this.props}
              key={`edit-${recitationId}`}
              selectedRecitation={this.state.selectedRecitation}
              modalEdit={this.state.modalEdit}
              toggleModalEdit={this.toggleModalEdit}
            ></ModalRecitationEdit>
            <ModalRecitationDelete
              {...this.props}
              key={`delete-${recitationId}`}
              selectedRecitation={this.state.selectedRecitation}
              modalDelete={this.state.modalDelete}
              toggleModalDelete={this.toggleModalDelete}
            ></ModalRecitationDelete>
          </div>
        ) : (
          /* display the notice else */
          <div className="wrap center">
            <h2 className="page-title">No Recordings to show</h2>
          </div>
        )}
      </div>
    );
  }
}

RecitationList.propTypes = {
  recitations: PropTypes.array,
  location: PropTypes.object,
  apiHost: PropTypes.string
};

export default RecitationList;
