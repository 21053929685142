import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup
} from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";

class ModalAddGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groupName: "",
      private: true
    };
  }

  handleTextChange = event => {
    this.setState({ groupName: event.target.value });
  };

  createGroup = () => {
    if (!this.state.groupName || this.state.groupName === "") {
      toast.error("Group name is required");
      return;
    }

    const postUrl = this.props.apiHost + "groups/";

    let headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };

    var bodyFormData = new FormData();
    bodyFormData.set("name", this.state.groupName);
    bodyFormData.set("members", this.props.activeUser.id); // add self as member when creating
    bodyFormData.set("group_creator", this.props.activeUser.id);
    bodyFormData.set("private", this.state.private);

    axios
      .post(postUrl, bodyFormData, {
        headers: headerData
      })
      .then(res => {
        if (res.status == 200 || res.status == 201) {
          this.props.history.push("/groups/" + res.data.group_uuid);
        }
      })
      .catch(function(err) {
        toast.error("Something went wrong with creating the group!");
      });
  };

  toggleModal = () => {
    this.props.toggleGroupModal();
  };

  handlePrivateChange = () => {
    this.setState({ private: !this.state.private });
  };

  render() {
    return (
      <Modal isOpen={this.props.groupModal} toggle={this.toggleModal}>
        <ModalHeader toggle={this.toggleModal}>Create a Group</ModalHeader>
        <ModalBody>
          <p>
            <i>
              Creating a group for your class? Make sure to add your section
              name
            </i>
          </p>
          <input
            placeholder="Enter a Group Name"
            type="text"
            value={this.state.groupName}
            required
            onChange={this.handleTextChange}
            className="form-input form-control"
          />
          <br></br>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                onChange={this.handlePrivateChange}
                checked={this.state.private}
              />{" "}
              Private
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={this.toggleModal}>
            Close
          </Button>
          <Button color="primary" onClick={this.createGroup}>
            Create Group
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ModalAddGroup;
