import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles
import ModalDelete from "../components/ModalDelete";

class ModalPoemEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cover: props.selectedRecitation?.cover,
      cover_uploaded: null,
      name: props.selectedRecitation?.poem?.name,
      lyrics: props.selectedRecitation?.poem?.lyrics,
      pubYear: props.selectedRecitation?.poem?.year,
      poet: props.selectedRecitation?.poem?.poet,
      poem_photo: props.selectedRecitation?.poem?.poem_photo,
      poem_photo_uploaded: null,
      isPrivate: props.selectedRecitation?.private,
      isFeatured: props.selectedRecitation?.featured,
      showSuccess: false,
      res: {},
      poemExists: false,
      poemId: null,
      tags: [],
      modalDelete: false
    };
  }

  handleLyricChange = value => {
    this.setState({
      lyrics: value
    });
  };

  handleTextChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleDelete = i => {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i)
    });
  };

  handlePrivateCheckbox = event => {
    this.setState({ isPrivate: event.target.checked });
  };

  handleFeaturedCheckbox = event => {
    this.setState({ isFeatured: event.target.checked });
  };

  handleAddition = tag => {
    this.setState(state => ({ tags: [...state.tags, tag] }));
  };

  handleDrag = (tag, currPos, newPos) => {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  };

  handleCoverChange = event => {
    const photo = event.target.files[0];

    this.setState({
      cover: photo,
      cover_uploaded: URL.createObjectURL(photo)
    });
  };

  handlePoemPhotoChange = event => {
    const photo = event.target.files[0];

    this.setState({
      poem_photo: photo,
      poem_photo_uploaded: URL.createObjectURL(photo)
    });
  };

  submitRecitationChange = () => {
    const headerData = {
      Authorization: "Token " + this.props.activeUser.token,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json"
    };

    this.submitPoem(headerData);
  };

  deleteRecording = () => {
    const recitation_uuid = this.props.selectedRecitation.recitation_uuid;
    const deleteUrl =
      this.props.apiHost + "recitations/" + recitation_uuid + "/delete/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    axios
      .get(deleteUrl, { headers: headerData })
      .then(response => {
        toast.success("Your recording has successfully been deleted");
        //redirect to home page
        if (response.status === 200 || response.status === 201) {
          setTimeout(() => {
            window.location.reload(); // @TODO this will wipe playlist
          }, 1000);
        }
        // window.location.replace("https://development.recitedverse.com");
      })
      .catch(err => {
        console.error(err);
        toast.error("There was an error in deleting your recording");
      });
  };

  submitPoem = headerData => {
    // First update the poem
    const formData = new FormData();
    const name = this.state.name;
    const lyrics = this.state.lyrics;
    const poet = this.state.poet;
    const year = parseInt(this.state.pubYear);
    const poem_photo = this.state.poem_photo;

    formData.append("name", name);
    formData.append("lyrics", lyrics);
    formData.append("poet", poet);
    formData.append("year", year);
    formData.append("cover", poem_photo);

    // update call to poems
    const poemId = this.props.selectedRecitation.poem.poem_uuid;
    const url = this.props.apiHost + "poems/" + poemId + "/";

    axios
      .put(url, formData, {
        headers: headerData
      })
      .then(response => {
        this.submitRecitation(headerData);
      })
      .catch(err => {
        toast.error("Something went wrong with the update!");
      });
  };

  submitRecitation = headerData => {
    // Second update the recitation
    const formData = new FormData();
    const cover = this.state.cover;
    formData.append("cover", cover);
    formData.append("private", this.state.isPrivate);
    formData.append("featured", this.state.isFeatured);

    const recitationId = this.props.selectedRecitation.recitation_uuid;
    const url = this.props.apiHost + "recitations/" + recitationId + "/";

    axios
      .put(url, formData, {
        headers: headerData
      })
      .then(res => {
        toast.success("Poem was updated successfully");
        setTimeout(() => {
          window.location.reload(); // @TODO this will wipe playlist
        }, 1000);
      })
      .catch(err => {
        toast.error("Something went wrong with the update!");
      });
  };

  toggleModalEdit = () => {
    this.props.toggleModalEdit();
  };

  toggleModalDelete = () => {
    this.setState(prevState => ({ modalDelete: !prevState.modalDelete }));
  };

  render() {
    const { tags, suggestions } = this.state;
    // recitation fields
    const cover = this.state.cover;
    const coverUploaded = this.state.cover_uploaded;

    // poem fields
    const name = this.state.name;
    const lyrics = this.state.lyrics;
    const pubYear = this.state.pubYear;
    const poet = this.state.poet;
    const poemPhoto = this.state.poem_photo;
    const poemPhotoUploaded = this.state.poem_photo_uploaded;
    const isPrivate = this.state.isPrivate;
    const isFeatured = this.state.isFeatured;

    console.log("cover", poemPhotoUploaded);

    const modalDelete = (
      <Modal isOpen={this.state.modalDelete} toggle={this.toggleModalDelete}>
        <ModalHeader toggle={this.toggleModalDelete}>
          Delete Recording
        </ModalHeader>
        <ModalBody>
          <ModalDelete
            {...this.state}
            {...this.props}
            handler={this.toggleModalDelete}
          ></ModalDelete>
        </ModalBody>
      </Modal>
    );

    return (
      <div>
        <Modal
          backdrop={true}
          fullscreen=""
          size="lg"
          className="recitation-edit-manage-modal"
          isOpen={this.props.modalEdit}
          toggle={this.toggleModalEdit}
        >
          <ModalHeader toggle={this.toggleModalEdit}>
            Edit Recording
          </ModalHeader>
          <ModalBody>
            <FormGroup className="space-sm">
              <Label for="cover">Recitation Cover</Label>
              <Input
                name="cover"
                id="cover"
                type="file"
                onChange={this.handleCoverChange}
              />
              <div className="space-sm">
                {cover !== "" ? (
                  <img src={cover} alt={"recitation " + name + " cover"} />
                ) : null}
                {coverUploaded ? (
                  <img
                    src={coverUploaded}
                    alt={"recitation " + name + " cover"}
                  />
                ) : null}
              </div>
            </FormGroup>
            <hr className="divider space-sm" />
            <h4>Edit Poem</h4>
            <FormGroup className="space-sm">
              <Label for="name">Poem Name</Label>
              <Input
                name="name"
                id="name"
                type="text"
                defaultValue={name}
                onChange={this.handleTextChange}
              />
            </FormGroup>
            <FormGroup className="space-sm">
              <Label for="name">Poet Name</Label>
              <Input
                name="poet"
                id="name"
                type="text"
                defaultValue={poet}
                onChange={this.handleTextChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Please enter the Poet's name"
                  },
                  pattern: {
                    value: "[A-Za-z]",
                    errorMessage: "The name can only have letters"
                  }
                }}
              />
            </FormGroup>
            <FormGroup className="space-sm">
              <Label for="name">Year of Publication</Label>
              <Input
                name="pubYear"
                defaultValue={pubYear}
                type="text"
                onChange={this.handleTextChange}
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Please enter the year of publication"
                  },
                  pattern: {
                    value: "[0-9]",
                    errorMessage: "The year can only have number"
                  },
                  minLength: {
                    value: 4,
                    errorMessage: "The year must have 4 characters"
                  },
                  maxLength: {
                    value: 4,
                    errorMessage: "The year must have 4 characters"
                  }
                }}
              />
            </FormGroup>
            <FormGroup className="space-sm">
              <Label for="description">Poem Text</Label>
              <ReactQuill value={lyrics} onChange={this.handleLyricChange} />
              {/* <Input
              onChange={this.handleTextChange}
              name="lyrics"
              value={lyrics}
              type="textarea"
            /> */}
            </FormGroup>
            <FormGroup check className="space-sm">
              <Input
                id="private"
                type="checkbox"
                checked={isPrivate}
                onChange={this.handlePrivateCheckbox}
              />{" "}
              <Label check for="private">
                This upload is private
              </Label>
            </FormGroup>
            {this.props.isStaff ? (
              <FormGroup check className="space-sm">
                <Input
                  id="featured"
                  type="checkbox"
                  checked={isFeatured}
                  onChange={this.handleFeaturedCheckbox}
                />{" "}
                <Label check for="featured">
                  Featured
                </Label>
              </FormGroup>
            ) : null}
            <FormGroup className="space-sm">
              <Button color="danger" onClick={this.toggleModalDelete}>
                Delete Poem
              </Button>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => this.submitRecitationChange()}
            >
              Save
            </Button>
            <Button color="secondary" onClick={this.toggleModalEdit}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        {modalDelete}
      </div>
    );
  }
}

export default ModalPoemEdit;
