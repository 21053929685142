import React, { Component } from "react";
import Header from "./../Headers/Header.js";
import axios from "axios";
import ProfileMainContent from "./components/ProfileMainContent";
import "./Profile.scss";
import { toast } from "react-toastify";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditable: false,
      admin: false,
      // error: null,
      // isLoaded: false,
      user: {},
      recitations: [],
      likesRecitations: [],
      likesPlaylists: [],
      playlists: [],
      groups: [],
      user_uuid: this.props.match.params.id
    };
  }

  updateEditable = () => {
    const { activeUser } = this.props;
    if (activeUser) {
      this.setState({ admin: activeUser.is_staff });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.activeUser !== prevProps.activeUser) {
      this.updateEditable();
    }
  }

  componentDidMount() {
    this.updateEditable();
    this.getUserDetail();
    this.getUserRecitations();
    this.getUserLikesRecitation();
    //this.getUserLikesPlaylist();
    this.getUserPlaylists();
    this.getGroups();
  }

  getUserDetail = () => {
    const user_uuid = this.props.match.params.id;
    const url = this.props.apiHost + "users/" + this.state.user_uuid + "/";

    this.getApiData(url, "user");
  };

  getUserRecitations = () => {
    const currentPath = this.props.location.pathname;
    var pathList = currentPath.split("/");
    const user_uuid = pathList[pathList.length - 1];
    const url =
      this.props.apiHost + "users/" + this.state.user_uuid + "/recitations/";

    this.getApiData(url, "recitations");
  };

  getUserLikesRecitation = () => {
    if (this.props.match.params.id === null) {
      return null;
    }

    const user_uuid = this.props.match.params.id;
    const url =
      this.props.apiHost +
      "users/" +
      this.state.user_uuid +
      "/likes/recitations/";

    this.getApiData(url, "likes_recitations");

    return;
  };

  // getUserLikesPlaylist = () => {
  //   if (this.props.match.params.id === null) {
  //     return null;
  //   }

  //   const user_uuid = this.props.match.params.id;
  //   const url =
  //     this.props.apiHost +
  //     "users/" +
  //     this.state.user_uuid +
  //     "/likes/playlists/";

  //   this.getApiData(url, "likes_playlists");

  //   return;
  // };

  getUserPlaylists = () => {
    const currentPath = this.props.location.pathname;
    var pathList = currentPath.split("/");
    const user_uuid = pathList[pathList.length - 1];
    const url =
      this.props.apiHost + "users/" + this.state.user_uuid + "/playlists/";

    this.getApiData(url, "playlists");
  };

  getGroups = () => {
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    const props = this.props;

    const currentPath = props.location.pathname;
    var pathList = currentPath.split("/");
    const user_uuid = pathList[pathList.length - 1];
    const url = props.apiHost + "users/" + this.state.user_uuid + "/groups/";

    axios
      .post(url, new FormData(), {
        headers: headerData
      })
      .then(response => {
        this.setState({
          groups: response.data
        });
      })
      .catch(error => {
        toast.error("Error in getting groups");
      });

    // const props = this.props;

    // const currentPath = props.location.pathname;
    // var pathList = currentPath.split("/");
    // const user_uuid = pathList[pathList.length - 1];
    // const url = props.apiHost + "users/" + user_uuid + "/groups/";
    // console.log(url);

    // this.getApiData(url, "groups");
  };

  getApiData = (url, name) => {
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    axios
      .get(url, {
        headers: headerData
      })
      .then(response => {
        if (name === "user") {
          var activeUser = JSON.parse(localStorage.getItem("activeUser"));
          this.setState({
            user: response.data
          });
          if (response.data.user_uuid === activeUser.user_uuid) {
            Object.keys(response.data).forEach(key => {
              if (activeUser[key]) activeUser[key] = response.data[key];
            });
            localStorage.setItem("activeUser", JSON.stringify(activeUser));
          }
        } else if (name === "recitations") {
          this.setState({
            recitations: response.data
          });
        } else if (name === "likes_recitations") {
          this.setState({
            likesRecitations: response.data
          });
        } else if (name === "likes_playlists") {
          this.setState({
            likesPlaylists: response.data
          });
        } else if (name === "playlists") {
          this.setState({
            playlists: response.data
          });
        } else if (name === "groups") {
          this.setState({
            groups: response.data
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  render() {
    const user_uuid = this.props.match.params.id;
    const editable =
      this.props.activeUser && user_uuid === this.props.activeUser.user_uuid;

    return (
      <div className="App">
        <Header {...this.props} />
        <div className="page-wrapper profile-page">
          <ProfileMainContent
            {...this.props}
            {...this.state}
            getApiData={this.getApiData}
            editable={editable}
            admin={this.state.admin}
          />
        </div>
      </div>
    );
  }
}

export default Profile;
