import React, { Component } from "react";
import { Link } from "react-router-dom";
import poetBackground from "./../../static/images/profile.png";

class RecommendedGroupItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const group = this.props.item;
    const handleImageError = e => {
      e.target.src = poetBackground;
    };

    return (
      <div className="content-list-item group-list-item">
        <div className="item-image">
          <Link to={`/groups/${group.group_uuid}/`}>
            <img
              src={this.props.item.cover_photo}
              alt={group.group_info}
              onError={handleImageError}
            ></img>
          </Link>
        </div>
        <div className="item-info">
          <Link to={`/groups/${group.group_uuid}/`}>
            <h6>{group.name}</h6>
          </Link>
          <p>{group.members.length} members</p>
        </div>
      </div>
    );
  }
}

export default RecommendedGroupItem;
