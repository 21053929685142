import React, { Component } from "react";
import Header from "./../Headers/Header.js";
import PoemMainContent from "./components/PoemMainContent.js";
import "./Poem.scss";

class Poem extends Component {
  render() {
    return (
      <div className="App poem-page">
        <Header {...this.props} />
        <div className="page-wrapper poem-page">
          <PoemMainContent {...this.props} />
        </div>
      </div>
    );
  }
}

export default Poem;
