import React from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  Dropdown,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from "reactstrap";
import profileImage from "../../static/images/profile.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

class CommentList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: 1,
      is_reply: false,
      comment: "",
      main_comment: -1,
      replying_to: "",
      comments: [],
      commentsList: [],
      modal: false,
      commentId: "",
      editable: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.postComment = this.postComment.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addReply = this.addReply.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.getComments = this.getComments.bind(this);
  }

  updateEditable = () => {
    const { activeUser } = this.props;
    if (activeUser) {
      this.setState({ editable: activeUser.is_staff });
    }
  };

  componentDidMount() {
    library.add(fas, far);
    this.updateEditable();
    this.renderComments(this.props.poemComments, 1);
  }
  componentDidUpdate(prevProps) {
    if (this.props.activeUser !== prevProps.activeUser) {
      this.updateEditable();
    }

    if (this.props.poemComments !== prevProps.poemComments) {
      this.renderComments(this.props.poemComments, 1);
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.postComment();
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }

  addReply(comment) {
    this.setState({
      is_reply: true,
      replying_to: "@" + comment.user.first_name + ' "' + comment.body + '"',
      main_comment: comment.id
    });
    document
      .getElementById("poemForm")
      .scrollIntoView({ behavior: "smooth", block: "center" });
  }

  getComments() {
    const url =
      this.props.apiHost +
      "recitations/" +
      this.props.currentRecording.recitation_uuid +
      "/comments/";

    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(url, {
        headers: headerData
      })
      .then(response => {
        if (response.status === 200) {
          this.setState({
            commentsList: []
          });
          this.renderComments(response.data, 1);
        }
      })
      .catch(error => {
        toast.error("We could not fetch comments!");
      });
  }

  handleChange(event) {
    this.setState({ comment: event.target.value });
  }

  postComment() {
    const url =
      this.props.apiHost +
      "recitations/" +
      this.props.currentRecording.recitation_uuid +
      "/comments/";

    let headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };
    var bodyFormData = new FormData();
    //bodyFormData.set("user", this.state.user);
    bodyFormData.set("main_comment", this.state.main_comment);
    bodyFormData.set("is_reply", this.state.is_reply);
    bodyFormData.set("body", this.state.comment);

    axios
      .post(url, bodyFormData, {
        headers: headerData
      })
      .then(response => {
        if (response.status === 201) {
          //  window.location.reload();
          toast.success("Comment posted!");
          this.setState({
            comment: "",
            is_reply: false,
            main_comment: -1,
            replying_to: ""
          });
          this.getComments();
        }
      })
      .catch(error => {
        toast.error("Could not post your comment!");
        // self.setState({
        // 	isLoaded:true,
        // 	error
        // })
      });
  }

  deleteComment() {
    const commentId = this.state.commentId;
    const url =
      this.props.apiHost +
      "recitations/" +
      this.props.currentRecording.recitation_uuid +
      "/comments/delete/";
    let headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };
    var bodyFormData = new FormData();
    bodyFormData.set("id", commentId);

    axios
      .post(url, bodyFormData, {
        headers: headerData
      })
      .then(response => {
        if (response.status === 200) {
          //window.location.reload();
          toast.info("Comment deleted!");
          this.getComments();
          this.toggleModal();
        }
      })
      .catch(error => {
        toast.error("Could not delete your comment!");
      });
  }

  likeComment(commentId) {
    const url =
      this.props.apiHost +
      "recitations/" +
      this.props.currentRecording.recitation_uuid +
      "/comments/" +
      "like/";
    let headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };
    var bodyFormData = new FormData();
    bodyFormData.set("id", commentId);

    axios
      .post(url, bodyFormData, {
        headers: headerData
      })
      .then(response => {
        if (response.status === 201) {
          //window.location.reload();
          toast.success("Comment liked!");
          this.getComments();
        } else if (response.status === 200) {
          toast.info("Comment unliked!");
          this.getComments();
        }
      })
      .catch(error => {
        toast.error("Could not like your comment!");
      });
  }

  calculateDate(date) {
    const diff = Date.now() - date;
    const minute = 1000 * 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;
    const month = week * 30;
    const year = month * 12;
    let res = "";
    if (Math.round(diff / year) > 0) {
      res = Math.round(diff / year) + "y";
    } else if (Math.round(diff / month) > 0) {
      res = Math.round(diff / month) + "mo";
    } else if (Math.round(diff / week) > 0) {
      res = Math.round(diff / week) + "w";
    } else if (Math.round(diff / day) > 0) {
      res = Math.round(diff / day) + "d";
    } else if (Math.round(diff / hour) > 0) {
      res = Math.round(diff / hour) + "h";
    } else if (Math.round(diff / minute) > 0) {
      res = Math.round(diff / minute) + "m";
    } else {
      res = "1m";
    }
    return res;
  }

  renderPoemReplies(poem, i) {
    let list = (
      <li key={i} className="pl-5 pt-2">
        <div className="commenter-image">
          {poem.user.avatar === "" ? ( // display default
            <img src={profileImage} alt="user-profile-img" className="p-0" />
          ) : (
            // display profile img
            <img
              src={poem.user.avatar}
              alt="user-profile-img"
              className="p-0"
            />
          )}
        </div>
        <div className="comment-text pl-2">
          <Link to={`/user/${poem.user.user_uuid}`}>
            <span className="comment-text-bold">
              {poem.user.first_name} {poem.user.last_name}
            </span>
          </Link>
          <p>{poem.body}</p>
          <a
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setState({
                is_reply: true,
                comment: "@ " + poem.text + " ",
                main_comment: poem.id
              });
            }}
          >
            <span className="sub-text pl-2">Reply</span>
          </a>
        </div>
      </li>
    );
    return list;
  }

  renderCommentsNew(poemComments, indent = 1, list) {
    if (poemComments === null) {
      return <p>There's no comment to show.</p>;
    }

    if (poemComments !== undefined && poemComments.length > 0) {
      poemComments.map(comment => {
        // populate the list array
        list.push(
          <ul className="comment-list">
            <li key={comment.id} style={{ paddingLeft: `${indent * 20}px` }}>
              <div className="commenter-image">
                {comment.user.avatar === "" ? ( // display default
                  <img
                    src={profileImage}
                    alt="user-profile-img"
                    className="p-0"
                  />
                ) : (
                  // display profile img
                  <img
                    src={comment.user.avatar}
                    alt="user-profile-img"
                    className="p-0"
                  />
                )}
              </div>
              <div className="comment-text pl-2">
                <Link to={`/user/${comment.user.user_uuid}`}>
                  <span className="comment-text-bold">
                    {comment.user.first_name} {comment.user.last_name}
                  </span>
                </Link>
                <p>{comment.body}</p>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.inputRef.focus();
                    this.addReply(comment);
                  }}
                >
                  <span className="sub-text pl-2">Reply</span>
                </a>
              </div>
            </li>
          </ul>
        );
        this.renderCommentsNew(comment.replies, indent + 2, list);
      });
    }
    return list;
  }

  renderComments(discussionComments, indent = 1) {
    const editable = this.props.editable;
    if (discussionComments === null) {
      return <p>There's no comment to show.</p>;
    }

    const commentsList = this.state.commentsList;

    if (discussionComments !== undefined && discussionComments.length > 0) {
      discussionComments.map(comment => {
        // check if user_uuid is in the list of users who liked the comment and display the heart icon
        let liked = false;
        if (comment.likes !== undefined && comment.likes.length > 0) {
          comment.likes.map(like => {
            if (like === this.props.activeUser.user_uuid) {
              liked = true;
            }
          });
        }
        let editable = false;
        if (
          comment.user.user_uuid === this.props.activeUser.user_uuid ||
          this.state.editable
        ) {
          editable = true;
        }
        // push the new comment to the commentsList state
        commentsList.push(
          <ul className="comment-list">
            <li key={comment.id} style={{ paddingLeft: `${indent * 20}px` }}>
              <div className="commenter-image">
                {comment.user.avatar === "" ? ( // display default
                  <img
                    src={profileImage}
                    alt="user-profile-img"
                    className="p-0"
                  />
                ) : (
                  // display profile img
                  <img
                    src={comment.user.avatar}
                    alt="user-profile-img"
                    className="p-0"
                  />
                )}
              </div>
              <div className="comment-text pl-2">
                <Link to={`/user/${comment.user.user_uuid}`}>
                  <span className="comment-text-bold">
                    {comment.user.first_name} {comment.user.last_name}
                  </span>
                </Link>
                <p>{comment.body}</p>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => this.likeComment(comment.id)}
                >
                  {liked ? (
                    <span className="">
                      <FontAwesomeIcon
                        icon={["fas", "heart"]}
                        className="mr-2"
                      />
                      {comment.num_likes}
                    </span>
                  ) : (
                    <span className="">
                      <FontAwesomeIcon
                        icon={["far", "heart"]}
                        className="mr-2"
                      />
                      {comment.num_likes}
                    </span>
                  )}
                </a>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.inputRef.focus();
                    this.addReply(comment);
                  }}
                >
                  <span className="sub-text pl-4">Reply</span>
                </a>
              </div>
              <div className="item-cta">
                {/* <button class="dropdown-item" type="button">
                  </button> */}
                {/* <span>{recitation.duration}</span> */}
                {editable ? (
                  <Dropdown
                    isOpen={
                      this.state.dropdownOpen &&
                      this.state.commentId === comment.id
                    }
                    toggle={this.toggle}
                  >
                    <DropdownToggle
                      onClick={() => {
                        this.setState({ commentId: comment.id });
                        this.toggleModal();
                      }}
                      caret
                      className="edit-button"
                    >
                      <FontAwesomeIcon
                        icon={fas.faMinusCircle}
                        className="mr-2"
                      />
                    </DropdownToggle>
                  </Dropdown>
                ) : (
                  <span></span>
                )}
              </div>
            </li>
          </ul>
        );
        this.renderComments(comment.replies, indent + 2);
      });
    }
    // update the state with the new commentsList
    this.setState({ commentsList });

    // return an empty div, as we don't want to render the comments list here
    return <div></div>;
  }

  renderPoemForm() {
    return (
      <div className="comment-form" id="poemForm">
        <Form onSubmit={this.handleSubmit}>
          <FormGroup>
            <Input
              ref={input => (this.inputRef = input)}
              onChange={this.handleChange}
              type="textarea"
              name="text"
              placeholder="Write something here..."
              value={this.state.comment}
            />
            <Button
              className="poem-comment-submit button small primary"
              type="submit"
            >
              Send
            </Button>
          </FormGroup>
        </Form>
      </div>
    );
  }

  render() {
    // create an empty array
    let list = [];
    const undoReply = () => {
      this.setState({
        is_reply: false,
        replying_to: ""
      });
    };
    const poemForm = this.renderPoemForm();
    // const poemComments = this.renderCommentsNew(
    //   this.props.poemComments,
    //   1,
    //   list
    // );
    const poemComments = this.state.commentsList;
    return (
      <div>
        {this.state.is_reply ? (
          <Alert color="warning" toggle={undoReply}>
            Replying to {this.state.replying_to}
          </Alert>
        ) : (
          <div></div>
        )}
        {poemForm}
        <div className="comment-section">{poemComments}</div>
        <Modal isOpen={this.state.modal} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>Delete Comment</ModalHeader>
          <ModalBody>Are you sure you want to delete this comment?</ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.deleteComment}>
              Delete
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

CommentList.propTypes = {
  poemComments: PropTypes.array,
  activeUser: PropTypes.object,
  currentRecording: PropTypes.object,
  apiHost: PropTypes.string
};

export default CommentList;
