import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "./../../static/images/logo-white.png";

class PoemInfoDiscussion extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const handleImageError = e => {
      e.target.src = logo;
    };

    const recording = this.props.recording;

    if (recording !== undefined) {
      const poem = recording.poem;
      const user = recording.user;

      return (
        <div className="discussion-banner">
          <a
            className="play-button-discussion"
            style={{ cursor: "pointer" }}
            onClick={() => this.props.playRecording(recording)}
          >
            <FontAwesomeIcon icon="caret-right" className="fa-3x" />
          </a>
          <h4>{poem.name}</h4>
          <p>
            <span>Read by </span>
            <Link to={`/user/${user.user_uuid}`}>
              {user.first_name} {user.last_name}{" "}
            </Link>
          </p>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default PoemInfoDiscussion;
