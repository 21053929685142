import React, { Component } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles

class PoemDetailDiscussion extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (this.props.recording !== undefined) {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];

      const recording = this.props.recording;
      const createdTime = recording.created_at;
      const createdDate = new Date(createdTime);
      const publishedTime = recording.poem?.year;
      const publishedDate = new Date(publishedTime);
      return (
        <div>
          <ul className="discussion-intro">
            <li>
              <b>By: </b>
              {recording.poem.poet}
            </li>
            <li>
              <b>Created Date: </b>
              <time>
                {monthNames[createdDate.getMonth()]} {createdDate.getUTCDate()},{" "}
                {createdDate.getFullYear()}
              </time>
            </li>
            {publishedTime ? (
              <li>
                <b>Year of Publication: </b>
                {publishedTime}
              </li>
            ) : (
              ""
            )}
          </ul>
          <div className="discussion-script">
            <ReactQuill
              value={recording.poem.lyrics}
              modules={{ toolbar: false }}
              readOnly={true}
            />
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default PoemDetailDiscussion;
