import React from "react";
import CommentList from "./CommentList";
import OrderModal from "./OrderModal";

class Comment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderModal: false,
      commentsList: []
    };
  }

  toggleOrderModal = () => {
    console.log("toggleOrderModal");
    this.setState({ orderModal: !this.state.orderModal });
  };

  render() {
    return (
      <div className="comments">
        {this.props.editable ? (
          <div>
            <button
              className="btn btn-primary btn-sm"
              style={{ marginBottom: "30px" }}
              onClick={this.toggleOrderModal}
            >
              Reorder Comments
            </button>
            <h4>Discussion</h4>
            <OrderModal
              {...this.props}
              key={this.props.discussion.id}
              modalOrder={this.state.orderModal}
              toggle={this.toggleOrderModal}
              commentsList={this.state.commentsList}
            />
          </div>
        ) : null}
        <CommentList {...this.props} discussion={this.props.discussion} />
      </div>
    );
  }
}

export default Comment;
