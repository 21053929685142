import React, { Component } from "react";
import LikesPlaylistsList from "./LikesPlaylistsList";

import LikesRecitationList from "./LikesRecitationList";
import PlaylistItem from "./PlaylistItem";

class ProfileLikesRecitations extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const user = this.props.user;
    const playlists = this.props.likesPlaylists;

    if (Object.keys(user).length > 0) {
      return (
        <div className="tab-container">
          {this.props.likesRecitations.length === 0 &&
          playlists.length === 0 ? (
            <div className="wrap center">
              <h2 className="page-title">Nothing liked yet...</h2>
            </div>
          ) : (
            <span></span>
          )}
          {this.props.likesRecitations.length > 0 ? (
            <div className="wrap">
              <h2 className="page-title">Recitations</h2>
            </div>
          ) : (
            <span></span>
          )}
          <LikesRecitationList {...this.props} />
          {this.props.likesRecitations.length > 0 ? (
            <div>
              <br></br>
              <br></br>
              <br></br>
            </div>
          ) : (
            <span></span>
          )}
          {playlists.length > 0 ? (
            <div className="wrap center">
              <h2 className="page-title">Playlists</h2>
            </div>
          ) : (
            <span></span>
          )}
          <ul className="content-list playlist-list">
            {playlists.map((item, index) => (
              <LikesPlaylistsList {...this.props} key={index} item={item} />
            ))}
          </ul>
        </div>
      );
    } else {
      return <p>You have no uploads yet.</p>;
    }
  }
}

export default ProfileLikesRecitations;
