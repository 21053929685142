import React, { Component } from "react";

import logo from "./../../static/images/logo-white.png";
import profileImage from "./../../static/images/profile.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SolrSearchInput from "./SolrSearchInput.js";

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";

class PreLoginHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.toggle = this.toggle.bind(this);
    this.logoutHandler = this.logoutHandler.bind(this);
    this.triggerSearchHandler = this.triggerSearchHandler.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  triggerSearchHandler() {}

  logoutHandler(event) {
    event.preventDefault();
    if (localStorage.getItem("isAuthenticated") !== undefined) {
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("activeUser");
    }
    this.props.setAuthStatus(false, {});
    this.props.history.push("/");
  }

  render() {
    return (
      <Navbar
        light
        expand="xs"
        sticky="top"
        className="bg-blue"
        style={{ justifyContent: "space-between" }}
      >
        <NavbarToggler onClick={this.toggle} />
        <NavbarBrand>
          <img src={logo} alt="recitedverse-logo" className="logo p-2" />
          RECITED VERSE
        </NavbarBrand>
        <Collapse
          isOpen={this.state.isOpen}
          navbar
          className="text-right"
          style={{ flexGrow: 0 }}
        >
          <Nav navbar>
            <NavItem>
              <NavLink className=" fw-bold" href="/login">
                <button className="login-button">Login/ Signup</button>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}
export default PreLoginHeader;
