import React, { Component } from "react";
import Header from "./../Headers/Header.js";
import "./Discussion.scss";
import DiscussionMainContent from "./components/DiscussionMainContent";

class Discussion extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="App">
        <Header {...this.props} />
        <div className="page-wrapper playlist-page">
          <DiscussionMainContent
            {...this.props}
            {...this.state}
            accessPlayer={this.props.accessPlayer}
            editable={this.editable}
          />
        </div>
      </div>
    );
  }
}

export default Discussion;
