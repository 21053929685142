import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faHeadphones,
  faBars
} from "@fortawesome/free-solid-svg-icons";
import PoemRecitationList from "./PoemRecitationList";
import recordImage from "../../static/images/record.png";
import favorite from "./../../static/images/baseline_favorite_black_18dp.png";
import notFavorite from "./../../static/images/baseline_favorite_border_black_18dp.png";
import AddButton from "./../../static/images/add-button.png";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import { fas } from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Row,
  Col
} from "reactstrap";

import { PropTypes } from "prop-types";
import ModalEdit from "./ModalEdit";
import ModalDelete from "./ModalDelete";
import ModalPoemEdit from "../modals/ModalPoemEdit";
import ModalPoemDelete from "../modals/ModalPoemDelete";

const animatedComponents = makeAnimated();

class PoemRecitations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalGroup: false,
      modalEdit: false,
      modalDelete: false,
      playlists: [],
      isLiked: true,
      numLikes: 0,
      numPlays: 0,
      groupList: [],
      groups: []
    };
    this.getUserPlaylists = this.getUserPlaylists.bind(this);
    this.toggle = this.toggle.bind(this);
    this.addToLibrary = this.addToLibrary.bind(this);
    this.likeRecording = this.likeRecording.bind(this);
    this.flagContent = this.flagContent.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.toggleModalGroup = this.toggleModalGroup.bind(this);
    this.toggleModalEdit = this.toggleModalEdit.bind(this);
    this.toggleModalDelete = this.toggleModalDelete.bind(this);
    this.addToQueue = this.addToQueue.bind(this);
  }

  componentDidMount() {
    this.getUserPlaylists();
    this.getIsLiked();
    this.getNumLikes();
    this.fetchGroupData();
  }

  fetchGroupData() {
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    const groupURL =
      this.props.apiHost + "users/" + activeUser.user_uuid + "/groups/";

    axios
      .get(groupURL, {
        headers: headerData
      })
      .then(response => {
        if (response.status === 200) {
          let groupInput = [];

          response.data.forEach(item => {
            let group = {};
            group["value"] = item.id;
            group["label"] = item.name;
            groupInput.push(group);
          });

          this.setState({ groupList: groupInput });
        }
      })
      .catch(error => {
        toast.error("Error fetching groups");
      });
  }

  handleSelectChange(groups) {
    this.setState({ groups });
  }

  flagContent() {
    const url = `mailto:hello@recitedverse.com?subject=Reported%20Content&body=Content%20Reported:${window.location.href}`;

    window.location.href = url;
  }

  getUserPlaylists() {
    let url =
      this.props.apiHost +
      "users/" +
      this.props.activeUser.user_uuid +
      "/playlists/";

    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    axios
      .get(url, {
        headers: headerData
      })
      .then(response => {
        if (response.status === 200) {
          this.setState({ playlists: response.data });
        }
      })
      .catch(error => {
        toast.error("Error fetching playlists");
      });
  }

  getIsLiked() {
    const poemId = this.props.currentRecording.recitation_uuid;
    let url = this.props.apiHost + "recitations/" + poemId + "/isliked/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(url, {
        headers: headerData
      })
      .then(response => {
        this.setState({ isLiked: response.data.liked });
      })
      .catch(error => {
        toast.error("Error fetching like status");
      });
  }

  getNumLikes() {
    const poemId = this.props.currentRecording.recitation_uuid;
    const url = this.props.apiHost + "recitations/" + poemId;
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    axios
      .get(url, { headers: headerData })
      .then(response => {
        if (response.data.num_likes !== undefined) {
          this.setState({ numLikes: response.data.num_likes });
        }
        if (response.data.num_play !== undefined) {
          this.setState({ numPlays: response.data.num_play });
        }
      })
      .catch(error => {
        toast.error("Error fetching like status");
      });
  }

  toggle() {
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  addToQueue() {
    this.props.addToQueue(this.props.currentRecording);
    //this.props.addToQueue(this.props.currentRecording);
  }

  toggleModalGroup = () => {
    this.setState(prevState => ({ modalGroup: !prevState.modalGroup }));
  };

  toggleModalEdit = () => {
    this.setState(prevState => ({ modalEdit: !prevState.modalEdit }));
  };

  toggleModalDelete = () => {
    this.setState(prevState => ({ modalDelete: !prevState.modalDelete }));
  };

  addToLibrary(playlist_uuid) {
    // now send to default playlist
    //http://development.recitedverse.com/api/playlists/ab63cdab-2d64-4ac8-8754-0ded494ed53f/recitations/
    const playListUrl =
      this.props.apiHost + "playlists/" + playlist_uuid + "/utils/add";
    const bodyFormData = new FormData();

    bodyFormData.set(
      "recitation_uuid",
      this.props.currentRecording.recitation_uuid
    );

    let headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };
    axios
      .post(playListUrl, bodyFormData, {
        headers: headerData
      })
      .then(response => {
        if (response.status === 200) {
          toast.success("This recording has been added to your library.");
          this.toggle();
        }
      })
      .catch(error => {
        if (error.response.status == 409) {
          toast.error("This recording is already in your playlist");
          this.toggle();
        } else {
          toast.error("Error adding to playlist");
        }
      });
  }

  likeRecording() {
    // requires an empty form body -> should fix this
    var bodyFormData = new FormData();
    // get rec id

    //toggle like button
    this.setState(prevState => ({ isLiked: !prevState.isLiked }));

    var url = "";
    if (!this.state.isLiked) {
      const poemId = this.props.currentRecording.recitation_uuid;
      url = this.props.apiHost + "recitations/" + poemId + "/likes/";
      // const url = this.props.apiHost + "recitations/" + poemId + "/likes/";
      const headerData = {
        Authorization: "Token " + this.props.activeUser.token
      };

      axios
        .post(url, bodyFormData, {
          headers: headerData
        })
        .then(() => {
          toast.success("You have liked this recording.");
          this.getNumLikes();
          // window.location.reload(); // @TODO this will wipe playlist
        })
        .catch(() => {
          toast.error("An error occurred liking this recording");
        });
    } else {
      const poemId = this.props.currentRecording.recitation_uuid;
      url = this.props.apiHost + "recitations/" + poemId + "/unlikes/";
      // const url = this.props.apiHost + "recitations/" + poemId + "/likes/";
      let headerData = {
        Authorization: "Token " + this.props.activeUser.token
      };
      axios
        .post(url, bodyFormData, {
          headers: headerData
        })
        .then(() => {
          toast.success("You have unliked this recording.");
          this.getNumLikes();
          // window.location.reload(); // @TODO this will wipe playlist
        })
        .catch(() => {
          toast.error("An error occurred in unliking this recording");
        });
    }
  }

  handleGroupChange() {
    const groupAddUrl =
      this.props.apiHost +
      "groups/" +
      this.props.currentRecording.recitation_uuid +
      "/add/";
    var data = new FormData();
    data.set("group", JSON.stringify(this.state.groups));
    let headerData = {
      Authorization: "Token " + this.props.activeUser.token
    };
    axios
      .post(groupAddUrl, data, {
        headers: headerData
      })
      .then(response => {
        toast.success("Successfully added recitation to group");
      })
      .catch(err => {
        toast.error("Something went wrong with the upload");
        console.log(err);
      });
  }

  render() {
    const modalAddToGroup = (
      <Modal isOpen={this.state.modalGroup} toggle={this.toggleModalGroup}>
        <ModalHeader toggle={this.toggleModalGroup}>
          Add Recording to Group(s)
        </ModalHeader>
        <ModalBody>
          <p>
            Groups are a private collection of recordings. Don't see your group?
            Ask the group creator to add you
          </p>
          <hr />
          <Select
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            options={this.state.groupList}
            onChange={this.handleSelectChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              this.toggleModalGroup();
              this.handleGroupChange();
            }}
          >
            Done
          </Button>
        </ModalFooter>
      </Modal>
    );

    const modalEdit = (
      <ModalPoemEdit
        {...this.props}
        isStaff={this.props.isStaff}
        key={this.props.currentRecording.id}
        selectedRecitation={this.props.currentRecording}
        modalEdit={this.state.modalEdit}
        toggleModalEdit={this.toggleModalEdit}
      ></ModalPoemEdit>
    );

    const colors = [
      "primary",
      "secondary",
      "success",
      "danger",
      "warning",
      "info",
      "dark"
    ];

    const modalDelete = (
      <Modal isOpen={this.state.modalDelete} toggle={this.toggleModalDelete}>
        <ModalHeader toggle={this.toggleModalDelete}>
          Delete Recording
        </ModalHeader>
        <ModalBody>
          <ModalDelete
            {...this.state}
            {...this.props}
            handler={this.toggleModalDelete}
          ></ModalDelete>
        </ModalBody>
      </Modal>
    );
    const poemRecitations = this.props.poemRecitations;
    const playListTable = this.state.playlists.map((item, i) => {
      return (
        <div>
          <ListGroup className="poem-actions-list">
            <ListGroupItem>
              <button
                onClick={() => this.addToLibrary(item.playlist_uuid)}
                className="poem-action-btn"
              >
                {item.name}
              </button>
            </ListGroupItem>
          </ListGroup>
          <br></br>
        </div>
      );
    });
    return (
      <div>
        <div className="poem-actions">
          <button onClick={this.likeRecording}>
            {!this.state.isLiked ? (
              <img src={notFavorite} />
            ) : (
              <img src={favorite} color="blue" />
            )}
            {/* <img src={PenTool} /> */}
            {this.state.numLikes}
          </button>
          <div>
            <FontAwesomeIcon icon={faHeadphones} className="mr-2" />
            {this.state.numPlays}
          </div>
        </div>
        <ListGroup className="poem-actions-list">
          {this.props.editable ? (
            <div>
              <ListGroupItem>
                <button
                  onClick={this.toggleModalEdit}
                  className="poem-action-btn"
                >
                  <FontAwesomeIcon icon={fas.faEdit} className="mr-2" />
                  <p>Edit poem</p>
                </button>
                {modalEdit}
              </ListGroupItem>
              <br></br>
            </div>
          ) : null}
        </ListGroup>
        <ListGroup className="poem-actions-list">
          <ListGroupItem>
            <button onClick={this.addToQueue} className="poem-action-btn">
              <FontAwesomeIcon icon={faBars} className="mr-2" />
              <p style={{ fontWeight: "bold" }}>Add to queue</p>
            </button>
          </ListGroupItem>
        </ListGroup>
        <br></br>
        <ListGroup className="poem-actions-list">
          {this.props.currentPoem.tags &&
          this.props.currentPoem.tags.length > 0 ? (
            <ListGroupItem style={{ padding: "10px" }}>
              {this.props.currentPoem.tags.map(tag => (
                <Badge
                  style={{ margin: "2px" }}
                  color={colors[Math.floor(Math.random() * colors.length)]}
                  pill
                >
                  {tag}
                </Badge>
              ))}
            </ListGroupItem>
          ) : null}
          <ListGroupItem>
            <button onClick={this.toggle} className="poem-action-btn">
              <img src={AddButton} />
              <p>Add to a playlist</p>
            </button>
          </ListGroupItem>
          <ListGroupItem>
            <Link
              to={{
                pathname: "/upload",
                // eslint-disable-next-line react/prop-types
                currentPoem: this.props.currentPoem
              }}
              className="poem-action-btn"
            >
              <img src={AddButton} />
              <p>Record a new reading</p>
            </Link>
          </ListGroupItem>
          <ListGroupItem>
            <button onClick={this.toggleModalGroup} className="poem-action-btn">
              <img src={AddButton} />
              <p>Add to Group</p>
            </button>
            {modalAddToGroup}
          </ListGroupItem>
          <ListGroupItem>
            <button onClick={this.flagContent} className="poem-action-btn">
              <i>
                <span>&#9873; </span>
                &nbsp;
              </i>
              <p>Flag content</p>
            </button>
          </ListGroupItem>
        </ListGroup>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Choose Playlist</ModalHeader>
          <ModalBody>
            <div>{playListTable}</div>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
        {modalDelete}
      </div>
    );
  }
}

PoemRecitations.propTypes = {
  apiHost: PropTypes.string,
  activeUser: PropTypes.object,
  currentRecording: PropTypes.object,
  poemRecitations: PropTypes.array
};

export default PoemRecitations;
