import React, { Component } from "react";
import { PropTypes } from "prop-types";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ModalProfileInfoEdit from "./Modals/ModalProfileInfoEdit";
import profileImage from "../../static/images/profile.png";
import { Row, Col } from "reactstrap";

class ProfileInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalEdit: false,
      following: false,
      activeUser: null
    };
  }

  componentDidUpdate() {
    if (!this.state.activeUser && this.props.activeUser) {
      //this.getFollow(this.props.activeUser);
      this.getFollow();
      this.setState({ activeUser: this.props.activeUser });
    }
  }

  getFollow = () => {
    let userToFollow = this.props.match.params.id;

    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    let url =
      this.props.apiHost +
      "users/" +
      userToFollow +
      "/followedby/" +
      activeUser.user_uuid +
      "/";

    axios
      .get(url, {
        headers: headerData
      })
      .then(response => {
        if (response.status === 200) {
          this.setState({ following: true });
        }
      })
      .catch(error => {
        toast.error("There was an error getting follow status.");
      });
  };

  followUser = () => {
    let userToFollow = this.props.match.params.id;

    let headerData = {
      Authorization: "Token " + this.props.activeUser.token,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json"
    };
    let url = this.props.apiHost + "users/" + userToFollow + "/followers/";
    var bodyFormData = new FormData(); // apparently required for the request to work

    axios
      .post(url, bodyFormData, {
        headers: headerData
      })
      .then(response => {
        if (response.status === 201) {
          toast.success("You are now following this user.");
          // now update data -> this seems weird to me below
          // url, name as params
          const user_uuid = this.props.match.params.id;
          const url = this.props.apiHost + "users/" + user_uuid + "/";
          this.setState({ following: true });

          this.props.getApiData(url, "user");
        }
      })
      .catch(error => {
        toast.error("There was an error following this user.");
      });
  };

  unfollowUser = () => {
    const userToUnfollow = this.props.match.params.id;

    const headerData = {
      Authorization: "Token " + this.props.activeUser.token,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json"
    };
    let url = this.props.apiHost + "users/" + userToUnfollow + "/followers/";
    var bodyFormData = new FormData(); // apparently required for the request to work

    axios
      .put(url, bodyFormData, {
        headers: headerData
      })
      .then(response => {
        if (response.status === 200) {
          toast.success("You unfollowed this user.");
          // now update data -> this seems weird to me below
          // url, name as params
          const user_uuid = this.props.match.params.id;
          const url = this.props.apiHost + "users/" + user_uuid + "/";
          this.setState({ following: false });
          this.props.getApiData(url, "user");
        }
      })
      .catch(error => {
        toast.error("There was an error unfollowing this user.");
      });
  };

  toggleModalEdit = () => {
    this.setState(prevState => ({
      modalEdit: !prevState.modalEdit
    }));
  };

  blockUser = () => {
    const userToBlock = this.props.match.params.id;

    const headerData = {
      Authorization: "Token " + this.props.activeUser.token,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json"
    };
    let url = this.props.apiHost + "users/" + userToBlock + "/block/";

    axios
      .post(url, null, {
        headers: headerData
      })
      .then(response => {
        if (response.status === 200) {
          toast.success(response.data["success"]);
          console.log(response.data);
          // wait 1000 ms and refresh page
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch(error => {
        toast.error("There was an error blocking this user.");
      });
  };

  deleteUser = () => {
    const userToDelete = this.props.match.params.id;

    const headerData = {
      Authorization: "Token " + this.props.activeUser.token,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json"
    };

    console.log(headerData);

    let url = this.props.apiHost + "users/" + userToDelete + "/delete/";
    axios
      .post(url, null, {
        headers: headerData
      })
      .then(response => {
        if (response.status === 204) {
          toast.success("User deleted.");
          // wait 1000 ms and refresh page
          setTimeout(() => {
            this.props.history.push("/");
          }, 1000);
        }
      })
      .catch(error => {
        toast.error("There was an error deleting this user.");
      });
  };

  renderUser = () => {
    let main;
    const user = this.props.user;
    const editable = this.props.editable;
    const following = this.state.following;

    if (Object.keys(user).length > 0) {
      let user_images = user.avatar;
      main = (
        <div className="profile-header">
          <div className="profile-image">
            {user_images === "" ? ( // display default
              <img src={profileImage} alt="user-profile-img" className="p-0" />
            ) : (
              // display profile img
              <img src={user_images} alt="user-profile-img" className="p-0" />
            )}
          </div>

          <div className="profile-info">
            <h3 className="profile-title">
              <span>
                {user.first_name} {user.last_name}
              </span>
              {editable ? (
                <button onClick={this.toggleModalEdit} className="button small">
                  Edit profile
                </button>
              ) : this.props.admin && user.is_active ? (
                // Row of two buttons, the block user one and a new delete user button
                <div>
                  <Row className="justify-content-center">
                    <Col xs="auto">
                      <button
                        onClick={this.blockUser}
                        className="button warning"
                      >
                        Block User
                      </button>
                    </Col>
                    <Col xs="auto">
                      <button
                        onClick={this.deleteUser}
                        className="button danger"
                      >
                        Delete User
                      </button>
                    </Col>
                  </Row>
                </div>
              ) : this.props.admin && user.is_active === false ? (
                <button onClick={this.blockUser} className="button warning">
                  Unblock User
                </button>
              ) : following ? (
                <button
                  onClick={this.unfollowUser}
                  className="button small primary"
                >
                  Following
                </button>
              ) : (
                <button
                  onClick={this.followUser}
                  className="button small primary"
                >
                  Follow User
                </button>
              )}
            </h3>
            <ul className="profile-following">
              <li>
                <Link to={`/followings/${user.user_uuid}/`}>
                  <span>{user.num_followings}</span> Following
                </Link>
              </li>
              <li>
                <Link to={`/followers/${user.user_uuid}/`}>
                  <span>{user.num_followers}</span> Followers
                </Link>
              </li>
            </ul>
            <p className="text-justify">{user.desc}</p>
            {/* <p>0 MUTUAL CONNECTIONS</p> */}
          </div>
        </div>
      );
    } else {
      main = (
        <div>
          {this.props.admin && user ? (
            <div className="profile-info">
              <h3 className="profile-title">
                <span>
                  {user.first_name} {user.last_name}
                </span>
              </h3>
              <button onClick={this.blockUser} className="button warning">
                Unblock User
              </button>
            </div>
          ) : (
            <div className="profile-info">
              <p>User not found</p>
            </div>
          )}
        </div>
      );
    }
    return main;
  };

  render() {
    const userId = this.props.user?.id;

    return (
      <div className="">
        {this.renderUser()}
        <ModalProfileInfoEdit
          key={userId}
          {...this.props}
          {...this.state}
          modalEdit={this.state.modalEdit}
          toggleModalEdit={this.toggleModalEdit}
        />
      </div>
    );
  }
}

ProfileInfo.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  apiHost: PropTypes.string
};

export default ProfileInfo;
