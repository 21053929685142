import React, { Component } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import FeaturedRecordingsCarousel from "../../Home/components/FeaturedRecordingsCarousel.js";
import GroupInfo from "./GroupInfo";
import Uploads from "./Uploads";
import Featured from "./Featured";
import Discussions from "./Discussions";
import Comments from "./Comments";
import Members from "./Members";
import classnames from "classnames";
import GroupModal from "./GroupModal";

class GroupMainContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      featuredRecordings: [],
      recentlyUploaded: [],
      recommendedPoems: [],
      featuredPoet: {},
      modal: false,
      groupData: {},
      userToAddInModal: "", // User email field in modal. If you know a cleaner way i'm all ears - @marko ??
      groupComments: [],
      groupDiscussions: [],
      activeTab: "0",
      member: false,
      editable: false,
      is_staff: false
    };

    this.tabs = ["Uploads", "Featured", "Members", "Discussions"];

    // this.toggleModal = this.toggleModal.bind(this);
    // this.toggleTab = this.toggleTab.bind(this);
    // this.getGroupComments = this.getGroupComments.bind(this);
  }

  updateEditable = () => {
    const { activeUser } = this.props;
    if (activeUser) {
      this.setState({
        is_staff: activeUser.is_staff
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.activeUser !== prevProps.activeUser) {
      this.updateEditable();
    }
  }

  componentDidMount() {
    this.updateEditable();
    this.getGroupInfo();
  }

  toggleModal = () => {
    this.setState(prevState => ({ modal: !prevState.modal }));
  };

  toggleTab = tab => {
    if (this.state.activeTab !== tab)
      this.setState({ activeTab: tab.toString() });
  };

  getGroupInfo = () => {
    this.setState({ loading: true });
    const group_uuid = this.props.match.params.id;
    const getGroupUrl = this.props.apiHost + "groups/" + group_uuid;

    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };

    // make a post request to url
    axios
      .post(getGroupUrl, new FormData(), {
        headers: headerData
      })
      .then(response => {
        if (response.status === 200) {
          const data = response.data;
          if (data && data.group_uuid) {
            this.setState({
              groupData: data
            });

            // Give editable access to user who's the author
            if (
              data.group_creator.id === this.props.activeUser.id ||
              this.state.is_staff
            ) {
              this.setState({ editable: true });
            }

            // Get group related other posts
            this.getGroupRecitations(data.id);
            this.getGroupComments(data.group_uuid);
            this.getFeaturedRecordings(data.id);
            this.getGroupDiscussions(data.group_uuid);
            this.getRecommendedPoems(data.id);
          }
          this.setState({
            member: true
          });
        }
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          this.setState({
            member: false
          });
          toast.error("You are not a member of this group");
        } else {
          toast.error("Error getting group info");
        }
      });
  };

  // getGroupInfo = () => {
  //   this.setState({ loading: true });
  //   const group_uuid = this.props.match.params.id;
  //   const getGroupUrl = this.props.apiHost + "groups/" + group_uuid;

  //   // include the user.token in the request data
  //   // let headerData = {
  //   //   Authorization: "Token " + this.props.activeUser.token
  //   // };

  //   axios
  //     .get(getGroupUrl)
  //     .then(response => {
  //       if (response.status === 200) {
  //         const data = response.data;

  //         // If data is valid
  //         if (data && data.group_uuid) {
  //           this.setState({
  //             groupData: data
  //           });

  //           // Give editable access to user who's the author
  //           if (data.group_creator.id === this.props.activeUser.id) {
  //             this.setState({ editable: true });
  //           }

  //           // Get group related other posts
  //           this.getGroupRecitations(data.id);
  //           this.getGroupComments(data.group_uuid);
  //           this.getFeaturedRecordings(data.id);
  //           this.getGroupDiscussions(data.group_uuid);
  //           this.getRecommendedPoems(data.id);
  //         }
  //       }
  //     })
  //     .finally(() => {
  //       this.setState({
  //         loading: false
  //       });
  //     })
  //     .catch(error => {
  //       console.log(error);
  //       toast.error("Error getting group info");
  //     });
  // };

  getGroupRecitations = id => {
    const getUrlFeaturedRecordings = `${this.props.apiHost}groups/${id}/recitations/`;
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(getUrlFeaturedRecordings, {
        headers: headerData
      })
      .then(response => {
        let data = response.data.filter(item => {
          const shouldRender =
            Boolean(item.user) !== false && Boolean(item.poem) !== false
              ? true
              : false;
          if (shouldRender) {
            return item;
          }
        });
        this.setState({ featuredRecordings: data });
      });
  };

  getFeaturedRecordings = id => {
    const getUrlFeaturedRecordings = `${this.props.apiHost}groups/${id}/recitations/`;
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(getUrlFeaturedRecordings, {
        headers: headerData
      })
      .then(response => {
        let data = response.data.filter(item => {
          const shouldRender =
            Boolean(item.user) !== false && Boolean(item.poem) !== false
              ? true
              : false;
          if (shouldRender) {
            return item;
          }
        });
        this.setState({ featuredRecordings: data });
      });
  };

  getRecommendedPoems = id => {
    const getUrlRecommendedPoems = this.props.apiHost + "poems/";
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(getUrlRecommendedPoems, { headers: headerData, recommended: true })
      .then(response => {
        let data = response.data.filter(item => {
          const shouldRender =
            Boolean(item.poet) !== false && Boolean(item.poem) !== false
              ? true
              : false;
          if (shouldRender) {
            return item;
          }
        });
        this.setState({ recommendedPoems: response.data });
      });
  };

  getGroupComments = id => {
    const url = `${this.props.apiHost}groups/${id}/comments/`;
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(url, { headers: headerData })
      .then(response => {
        this.setState({ groupComments: response.data });
      })
      .catch(error => {
        toast.error("We could not fetch the group comments!");
      });
  };

  getGroupDiscussions = id => {
    const url = `${this.props.apiHost}groups/${id}/discussions/`;
    let activeUser = this.props.activeUser;

    if (!activeUser) {
      activeUser = JSON.parse(localStorage.getItem("activeUser"));
    }

    let headerData = {
      Authorization: "Token " + activeUser.token
    };
    axios
      .get(url, { headers: headerData })
      .then(response => {
        this.setState({ groupDiscussions: response.data });
      })
      .catch(error => {
        toast.error("We could not fetch the group discussions!");
      });
  };

  render() {
    return !this.state.member ? (
      <div className="group-container wrap">
        <div className="group-content center top-space">
          <h2>Group is private</h2>
        </div>
      </div>
    ) : this.state.loading ? (
      <div className="group-container wrap">
        <div className="group-content center top-space">
          <h2>Loading group...</h2>
        </div>
      </div>
    ) : (
      <div>
        <GroupInfo
          {...this.props}
          groupData={this.state.groupData}
          toggleModal={this.toggleModal}
          editable={this.state.editable}
        />
        <div className="group-container wrap">
          <div className="group-content">
            <div className="tab-control">
              <Nav tabs>
                {this.tabs.map((item, i) => (
                  <NavItem key={i}>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === i.toString()
                      })}
                      onClick={() => {
                        this.toggleTab(i);
                      }}
                    >
                      {item}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="0">
                  <div className="tab-container">
                    <Uploads
                      {...this.props}
                      {...this.state}
                      recitations={this.state.featuredRecordings}
                    />
                  </div>
                </TabPane>
                <TabPane tabId="1">
                  <div className="tab-container">
                    <Featured
                      {...this.props}
                      {...this.state}
                      poems={this.state.recommendedPoems}
                    />
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="tab-container">
                    <Members
                      {...this.props}
                      {...this.state}
                      groupMembers={this.state.groupData.members}
                    />
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="tab-container">
                    <Discussions
                      {...this.props}
                      {...this.state}
                      discussions={this.state.groupDiscussions}
                    />
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </div>
          {/* <aside className="group-sidebar">
            <div className="comments">
              <Comments {...this.props} {...this.state} />
            </div>
          </aside> */}
        </div>
        <GroupModal
          {...this.props}
          groupData={this.state.groupData}
          modal={this.state.modal}
          toggleModal={this.toggleModal}
        />
      </div>
    );
  }
}

export default GroupMainContent;
