import React, { Component } from "react";
import { Button, FormGroup, Input, FormFeedback, NavItem } from "reactstrap";
import axios from "axios";
import "../Login.css";
import { toast } from "react-toastify";
import { PropTypes } from "prop-types";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      emailValid: false,
      passwordValid: false,
      formValid: true,
      formError: "",
      showForgetPassword: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  handleClick() {
    confirmAlert({
      title: "Reset Password",
      message: "Send reset password email to " + this.state.email,
      buttons: [
        {
          label: "Yes",
          onClick: this.handleReset
        },
        {
          label: "No",
          onClick: () => {}
        }
      ]
    });
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleAuthResponse(response) {
    if (response.error !== undefined) {
      this.setState({
        formError: response.error,
        formValid: false
      });
    } else {
      this.props.setAuthStatus(true, response.activeUser);
      this.props.history.push("/");
    }
  }

  handleChange(event) {
    const isValid = event.target.value.length > 0;
    this.setState({
      [event.target.id]: event.target.value,
      [event.target.id + "Valid"]: isValid
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const postUrl = this.props.apiHost + "login/";
    if (this.validateForm()) {
      var self = this;
      axios
        .post(postUrl, this.state)
        .then(response => {
          this.handleAuthResponse(response.data);
        })
        .catch(function(error) {
          if (error.response.status == 401) {
            toast.error("The email does not exist.");
          } else if (error.response.status == 402) {
            toast.error("The email address has not been comfirmed.");
          } else {
            self.setState({ showForgetPassword: true });
            toast.error(
              "The Password you entered is incorrect! Please try again."
            );
          }
        });
    } else {
      this.setState({
        formError: "Email/Password cannot be empty",
        formValid: false
      });
    }
  }

  async handleReset() {
    const postUrl = this.props.apiHost + "create_reset_password/";
    if (this.validateForm()) {
      axios
        .post(postUrl, { email: this.state.email })
        .then(() => {})
        .catch(function(error) {
          console.log(error);
        });
    } else {
      this.setState({
        formError: "Email/Password cannot be empty",
        formValid: false
      });
    }
  }

  render() {
    const formError = this.state.formValid;
    return (
      <div style={{ display: "inherit" }}>
        <NavItem>
          <FormFeedback className={formError ? "" : "show"} tooltip>
            {this.state.formError}
          </FormFeedback>
          <FormGroup>
            <Input
              autoFocus
              id="email"
              type="email"
              valid={this.state.emailValid}
              value={this.state.email}
              onChange={this.handleChange}
              placeholder="Email"
            />
            <FormFeedback>Email cannot be empty</FormFeedback>
          </FormGroup>
        </NavItem>
        <NavItem>
          <FormGroup>
            <Input
              id="password"
              type="password"
              valid={this.state.passwordValid}
              value={this.state.password}
              onChange={this.handleChange}
              placeholder="Password"
            />
            {this.state.email !== "" ? (
              <div className="forget-password">
                <a style={{ cursor: "pointer" }} onClick={this.handleClick}>
                  Forgot your password?{" "}
                </a>
              </div>
            ) : null}

            <FormFeedback>Password cannot be empty</FormFeedback>
          </FormGroup>
        </NavItem>
        <NavItem>
          <Button
            className="login-button"
            type="submit"
            onClick={this.handleSubmit}
          >
            LOGIN
          </Button>
        </NavItem>
      </div>
    );
  }
}

LoginForm.propTypes = {
  setAuthStatus: PropTypes.func,
  history: PropTypes.func,
  apiHost: PropTypes.string
};

export default LoginForm;
