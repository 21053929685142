import React, { Component } from "react";
import RecentUploadItem from "./RecentUploadItem.js";

class RecentUploadsList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const items = this.props.items.map((item, i) => (
      <li className="list-item" key={i}>
        <RecentUploadItem
          accessPlayer={this.props.accessPlayer}
          item={item}
          {...this.props}
        />
      </li>
    ));

    return (
      <div className="recent-upload">
        <div className="content-header">
          <h4 className="section-title text-uppercase">{this.props.title}</h4>
        </div>
        <ul className="upload-list">{items}</ul>
      </div>
    );
  }
}

export default RecentUploadsList;
